import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core';

import {Business, Person} from '@material-ui/icons';
// import Comments from "components/Comments/Comments";
import {authRefresh, authUser, useAuthDispatch} from 'contexts/Auth';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from 'contexts/Intl';
import EditForm from "../components/Form/EditForm";
import {rolePriorities} from "../config";
import {graphQLApi} from "../services/GraphQLApi";
import Link from "@material-ui/core/Link";
import themeColors from "../assets/theme/colors";

moment.locale(window.language);

export default function UsersProfile() {
  const intl = useIntl();
  const [_locale, setLocale] = useContext(LocaleContext);

  const userFields = [
    {
      field: "title",
      label: intl.formatMessage({
        id: "user-profile.label.title",
        defaultMessage: "Title",
      }),
    },
    {
      field: "name",
      required: true,
      label: intl.formatMessage({
        id: "user-profile.label.name",
        defaultMessage: "Name",
      }),
    },
    {
      field: "email",
      required: true,
      label: intl.formatMessage({
        id: "user-profile.label.email",
        defaultMessage: "E-mail",
      }),
    },
    {
      field: "phone",
      label: intl.formatMessage({
        id: "user-profile.label.phone",
        defaultMessage: "Phone",
      }),
    },
    {
      field: "locale",
      label: intl.formatMessage({
        id: "user-profile.label.language",
        defaultMessage: "Language",
      }),
      options: [
        {id: "da", name: intl.formatMessage({id: "common.language.danish", defaultMessage: "Danish"})},
        {id: "en", name: intl.formatMessage({id: "common.language.english", defaultMessage: "English"})},
      ],
    },
    {
      field: "password",
      label: intl.formatMessage({
        id: "user-profile.label.password",
        defaultMessage: "Password",
      }),
    },
    {
      field: "password_confirmation",
      label: intl.formatMessage({
        id: "user-profile.label.passwordConfirm",
        defaultMessage: "Confirm password",
      }),
    },
  ];
  const siteFields = [
    {
      field: "company",
      label: intl.formatMessage({
        id: "sites.edit.label.company",
        defaultMessage: "Company name",
      }),
    },
    {
      field: "address",
      label: intl.formatMessage({
        id: "sites.edit.label.address",
        defaultMessage: "Address",
      }),
    },
    {
      field: "zip",
      label: intl.formatMessage({
        id: "sites.edit.label.zip",
        defaultMessage: "Zip",
      }),
    },
    {
      field: "city",
      label: intl.formatMessage({
        id: "sites.edit.label.city",
        defaultMessage: "City",
      }),
    },
    {
      field: "country",
      label: intl.formatMessage({
        id: "sites.edit.label.country",
        defaultMessage: "Country",
      }),
    },
    {
      column: 2,
      field: "cvr",
      label: intl.formatMessage({
        id: "sites.edit.label.cvr",
        defaultMessage: "VAT reg.",
      }),
    },
    {
      column: 2,
      field: "invoice_email",
      label: intl.formatMessage({
        id: "sites.edit.label.invoice_email",
        defaultMessage: "Invoice e-mail",
      }),
    },
    {
      column: 2,
      field: "contact",
      label: intl.formatMessage({
        id: "sites.edit.label.contact",
        defaultMessage: "Contact",
      }),
    },
    {
      column: 2,
      field: "phone",
      label: intl.formatMessage({
        id: "sites.edit.label.phone",
        defaultMessage: "Telephone",
      }),
    },
    {
      column: 2,
      field: "email",
      label: intl.formatMessage({
        id: "sites.edit.label.email",
        defaultMessage: "E-mail",
      }),
    },
    {
      fullWidth: true,
      field: "logo",
      label: intl.formatMessage({
        id: "sites.edit.label.logo",
        defaultMessage: "Logo",
      }),
      input: "file",
    },
    {
      fullWidth: true,
      field: "support",
      label: intl.formatMessage({id: "sites.edit.label.support", defaultMessage: "Your current support agreement"}),
      render: (key, data, setData) => <Grid container>
        <Grid item xs={12}><strong>{intl.formatMessage({id: "sites.edit.label.support", defaultMessage: "Your current support agreement"})}</strong></Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            value={data.support || ''}
            onChange={e => setData({...data, support: e.target.value, agreed: false})}
          >{supportAgreements.map(a =>
            <MenuItem value={a.id} key={'support_agreement_'+a.id}>{a.Titel}</MenuItem>
          )}</TextField>
          <Link target="_blank" onClick={() => {
            setShowAgreement(true);
          }}>{intl.formatMessage({id: "sites.edit.read_support", defaultMessage: "Read the support agreement here."})}</Link>
          <Dialog maxWidth="lg" open={showAgreement} onClose={() => setShowAgreement(false)}>
            <DialogContent dangerouslySetInnerHTML={{__html: supportAgreements.find(a => a.id === data.support)?.Betingelser}}/>
            <DialogActions style={{justifyContent:"center"}}><Button variant="outlined" onClick={() => setShowAgreement(false)}>{intl.formatMessage({id:"common.button.close"})}</Button></DialogActions>
          </Dialog>
        </Grid>
          {initialSupport !== data.support && <Grid item xs={12} style={{color: validation.site.support.length > 0 ? themeColors.error.main : 'inherit'}}>
            <FormControl fullWidth error={validation.site.support.length > 0}>
              <FormControlLabel
                control={<Checkbox checked={!!data.agreed} onChange={e => {
                  setValidation(initialValidation);
                  setData({...data, agreed: e.target.checked});
                }} name="agreed"/>}
                label={intl.formatMessage({id: "sites.edit.i_agree_support", defaultMessage: "I have read the terms of the support agreement and accept it."})}
              />
              <FormHelperText>{validation.site.support.join('')}</FormHelperText>
            </FormControl>
          </Grid>}
      </Grid>
    },
  ];

  const [showAgreement, setShowAgreement] = useState(false);
  const [initialSupport, setInitialSupport] = useState(0);
  const [supportAgreements, setSupportAgreements] = useState([
      {id:0, title:"None"},
      {id:25, title:"25 hours"},
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({title: "",
    name: "",
    email: "",
    phone: "",
    locale: "",
    password: "",
    password_confirmation: "",});
  const [site, setSite] = useState({company: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    logo: "",
    cvr: "",
    invoice_email: "",
    contact: "",
    phone: "",
    email: "",
    support: 0,
    agreed: false,
  });
  const initialValidation = {
    title: [],
    name: [],
    email: [],
    phone: [],
    locale: [],
    password: [],
    password_confirmation: [],
    site: {
      company: [],
      address: [],
      zip: [],
      city: [],
      country: [],
      logo: [],
      cvr: [],
      invoice_email: [],
      contact: [],
      phone: [],
      email: [],
      support: [],
      agreed: [],
    }
  };

  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };

  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: setValidationFromErrors})
  useEffect(() => {
    setIsLoading(true);
    client.query('{' +
          'users(filter:{id:' + authUser().id + '}){data' +
            '{id name title phone email locale ' +
              'site{logo logo_uri company address zip city country cvr contact phone email invoice_email support}' +
            '}' +
          '}' +
          'supportAgreements{id Titel Timer Pris Betingelser}' +
        '}').then(r => {
      if (r && r.hasOwnProperty('users')) {
        setUser({...user, ...r.users.data[0]});
        setSite({...site, ...r.users.data[0].site});
        setSupportAgreements(r.supportAgreements);
        setInitialSupport(r.users.data[0].site.support);
      }
      setIsLoading(false);
    });
  }, []);

  const save = () => {
    const data = {...user, site: {...site}};
    delete data.site.logo_uri;
    const vars = {
      id: "ID!",
      title: "String",
      name: "String",
      phone: "String",
      email: "String",
      locale: "String",
      password: "String",
      password_confirmation: "String",
      site: "SiteInput",
    };
    if (authUser().hasRole(['admin','sysdev'])) {
      if (data.site.support !== initialSupport && !data.site.agreed) {
        setValidation({...initialValidation, site: {...initialValidation.site,
            support: [intl.formatMessage({id:"sites.edit.must_agree_support", defaultMessage:"You have to agree to the new support agreement terms to change your agreement!"})],
          }});
        return;
      }
    }
    else {
      delete data.site;
      delete vars.site;
    }
    setIsLoading(true);
    client.mutation('user', vars, data, 'id').then(r => {
      if (r && r.hasOwnProperty('response')) {
        authRefresh(true).then(() => {
          setLocale(data.locale);
          window.location.reload();
        });
      } else {
        setIsLoading(false);
      }
    });
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={11} md={10} lg={7} xl={6}>
        {isLoading
          ? <center style={{margin:"3rem"}}><CircularProgress/></center>
          : <Card>
          <CardHeader color="primary" avatar={<Person/>} title={<FormattedMessage
            id={"user-profile.heading"}
            defaultMessage={"Your profile"}
          />}/>
          <CardContent>
            <EditForm
              validation={validation}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              data={user}
              setData={setUser}
              fields={userFields}
              cols={2}
            />
          </CardContent>
          <CardHeader color="primary" avatar={<Business/>} title={<FormattedMessage
            id={"user-profile.company.heading"}
            defaultMessage={"Your company"}
          />}/>
          <CardContent>
            {authUser().isAllowed(rolePriorities.admin)
              ? <EditForm
                validation={validation.site}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                data={site}
                setData={setSite}
                fields={siteFields}
                cols={2}/>
              : siteFields.map(f => <Grid container spacing={1}>
                <Grid item xs={3}><strong>{f.label}</strong></Grid>
                <Grid item xs={9}>{(f.field === "logo" && !!site.logo_uri) ?
                  <img src={site.logo_uri} alt={f.field} height={50}/> :
                    f.field === "support" ? supportAgreements.find(a => a.id === site[f.field])?.Titel
                        : site[f.field]}</Grid>
              </Grid>)
            }
          </CardContent>
          <CardActions style={{justifyContent: "flex-end"}}>
            <Button
              variant="contained"
              color="primary"
              onClick={save}
            ><FormattedMessage id={"common.button.save"}/></Button>
          </CardActions>
        </Card>}
      </Grid>
    </Grid>
  );
}
