import {useCallback, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import {useIntl} from "react-intl";
import {graphQLApi} from "services/GraphQLApi";
import {authUser, getApiDomain, useAuthDispatch} from "contexts/Auth";
import sha256 from "crypto-js/sha256";
import moment from "moment/moment";

export default function SelectPrintoutDialog({open, onClose, entity}) {
  const intl = useIntl();
  const client = useCallback(new graphQLApi(useAuthDispatch()), []);

  const [layouts, setLayouts] = useState([]);

  useEffect(() => {
    if (entity && entity.entity_type_id) {
      client.query('{' +
        'printoutLayouts(filter:{entity_type_id:' + entity.entity_type_id + '}){data{id title}}' +
      '}')
      .then(r => {
        if (r && r.hasOwnProperty('printoutLayouts')) {
          setLayouts(r.printoutLayouts.data);
        }
      });
    }
  }, [entity]);


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography><Typography variant="h2" color="primary">{intl.formatMessage({id:"entities.list.select_layout"})}</Typography></DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {layouts.map((layout, index) => (
            <Grid key={index} item xs={6}>
              <Button color="primary" fullWidth variant="contained" onClick={e => {
                onClose(e);
                window.open(getApiDomain() + '/pdfs/' + layout.id + '?id=' + entity.id + '&token=' + sha256(authUser().id + authUser().email + moment().format('YYYYMMDD')), '_blank');
              }}>{layout.title}</Button>
            </Grid>
          ))}
        </Grid>
        {layouts.length === 0 && <Typography>{intl.formatMessage({id:"entities.list.no_layouts_found"})}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{intl.formatMessage({id:"common.button.close"})}</Button>
      </DialogActions>
    </Dialog>
  );
}
