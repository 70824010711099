import PropTypes from "prop-types";
import {Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import EditTableHead from "./EditTableHead";
import EditTableButtons from "./EditTableButtons";
import EditTableWarning from './EditTableWarning';
import {EditTableClickProps, EditTableDataList} from "./EditTableTypes";
import {useIntl} from "react-intl";

export default function EditTable({columns, data, onClickEdit, onClickDelete, warningMessage, computableTypes}) {
    const intl = useIntl();

    return (
        data ? (
            <TableContainer>
                <Table size="small">
                    <EditTableHead columns={columns} />
                    <TableBody>
                        {data.map(row => {
                            return (
                                <TableRow key={row.id}>
                                  <TableCell>{row.name}</TableCell>
                                    <TableCell>{intl.formatMessage({id: `fields.types.${row.type}`, defaultMessage: "Type"})}</TableCell>
                                    <TableCell align="right">
                                      {(!computableTypes.includes("*") && !computableTypes.includes(row.type))
                                       ? <EditTableWarning message={warningMessage}/>
                                       : ''}
                                      <EditTableButtons onClickEdit={onClickEdit} onClickDelete={onClickDelete} rowId={row.id} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        ) : ''
    )
}

EditTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    warningMessage: PropTypes.string,
    computableTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: EditTableDataList(),
    ...EditTableClickProps()
}
