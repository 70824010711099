import React from "react";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {useIntl} from "react-intl";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import {Dns} from "@material-ui/icons";
import {useParams} from "react-router-dom";

export default function DomainEdit(props) {
    const intl = useIntl();
  const params = useParams();

  let id = Number(params.id);
  let siteId = Number(params.site_id);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "domains.edit.label.name",
                defaultMessage: "Name",
            }),
            input: "text",
        },
        {
            column: 2,
            field: "is_active",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({
                id: "domains.edit.label.is_active",
                defaultMessage: "Is Active",
            }),
            input: "switch",
        },
        {
            column: 2,
            field: "is_verified",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({
                id: "domains.edit.label.is_verified",
                defaultMessage: "Is Verified",
            }),
            input: "switch",
        },
    ];
    let extraArgs = "";
    if (!siteId) {
        fields.unshift(
            {
                field: "site_id",
                initial: null,
                type: "ID",
                label: intl.formatMessage({
                    id: "domains.edit.label.site_id",
                    defaultMessage: "Site",
                }),
                query: "sites",
                filter: "",
                titleField: ["reference", "name"],
                renderOption: (option) => {
                    return (
                        <React.Fragment>
                            <span style={{minWidth: "150px"}}>{option.reference}</span>
                            {option.name}
                        </React.Fragment>
                    );
                },
            }
        );
    }
    else {
        extraArgs = ",site_id:" + String(siteId);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                      color="primary"
                      avatar={<Dns/>}
                      title={intl.formatMessage({id: "domains.edit.heading"})}/>
                    <CardContent>
                        <GraphQLEditForm
                            id={id}
                            query={"domains"}
                            mutations={"domain"}
                            fields={fields}
                            cols={2}
                            extraArgs={extraArgs}
                            {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
