import React from 'react';
import {useIntl} from 'react-intl';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import {VideoLabel} from '@material-ui/icons';
import GraphQLEditForm from 'components/GraphQL/GraphQLEditForm';
import LabelsTable from 'components/Labels/LabelsTable';
import {useParams} from "react-router-dom";

export default function LabelEdit(props) {
  const intl = useIntl();
  const params = useParams();
  const id = Number(params.id);

  const fields = [
    {
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "labels.edit.label.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      field: "entity_type_id",
      initial: null,
      type: "ID",
      query: "entityTypes",
      titleField: "title",
      label: intl.formatMessage({id: "labels.edit.label.entity_type", defaultMessage: "Entity type"}),
    },
    {
      field: "language_id",
      input: "language",
      label: intl.formatMessage({id: "labels.edit.label.language", defaultMessage: "Language"}),
    },
    {
      field: "is_multiple",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({id: "labels.edit.label.multiple", defaultMessage: "Has multiple values"}),
      input: "switch"
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={<VideoLabel color="primary"/>}
            title={intl.formatMessage({id: "labels.edit.heading", defaultMessage: "Label edit"})}
            titleTypographyProps={{color: "primary"}}
          />
          <CardContent>
            <GraphQLEditForm id={id}
                             query={"labels"}
                             mutations={"label"}
                             fields={fields}
                             cols={2}
                             redirectToEdit={id ? false : true}
                             extraComponent={id
                               ? <LabelsTable labelId={id}/>
                               : <Typography>{intl.formatMessage({
                                 id: "labels.edit.no_id",
                                 defaultMessage: "You must save before you can add any label elements."
                               })}</Typography>
                             }
                             {...props}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
