import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/da.js';
import 'moment/locale/en-gb.js';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {authUser} from 'contexts/Auth';
import {IntlProvider} from 'react-intl';
import locale_en from 'translations/en.json';
import locale_da from 'translations/da.json';

export const LocaleContext = React.createContext('da');

export const LocaleContextProvider = (props) => {
  const translations = {
    en: locale_en,
    da: locale_da,
  };

  let lang = navigator.language.split(/[-_]/)[0];
  if (lang !== "da") {
    lang = 'en';
  }
  if (authUser().locale) {
    lang = authUser().locale;
  }
  const [locale, setLocale] = useState(lang);

  useEffect(() => {
    if (locale) {
      document.documentElement.lang = locale;
      window.language = locale;
      moment.locale(locale);
    }
  }, [locale]);

  return (<IntlProvider
    locale={locale}
    defaultLocale="da"
    messages={translations[locale]}
  >
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <LocaleContext.Provider value={[locale, setLocale]}>
        {props.children}
      </LocaleContext.Provider>
    </MuiPickersUtilsProvider>
    </IntlProvider>
  );
};
