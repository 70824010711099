import {FormControl, FormLabel} from "@material-ui/core";
import ColorPicker from "material-ui-color-picker";
import React from "react";
import {useIntl} from "react-intl";

export default function Color({setProp, value, prop = "background"}) {
    const intl = useIntl();
    return <FormControl fullWidth margin="dense" component="fieldset">
        <FormLabel component="legend" style={{fontSize:"0.825rem"}}>{prop === "background"
            ? intl.formatMessage({id:"layouts.prop.background-color",defaultMessage:"Background"})
            : intl.formatMessage({id:"layouts.prop.font-color",defaultMessage:"Font color"})}</FormLabel>
        <ColorPicker
            name={prop+"-color"}
            value={value}
            onChange={(color) => {
                setProp((props) => (props[prop] = color), 500);
            }}
            inputProps={{
                style: {backgroundColor: value}
            }}
        />
    </FormControl>;
}
