import {IconButton} from '@material-ui/core';
import {Create, Delete} from '@material-ui/icons';
import {EditTableClickProps} from './EditTableTypes';
import PropTypes from 'prop-types';

export default function EditTableButtons({onClickEdit, onClickDelete, rowId}) {
    return (
        <>
            <IconButton size="small" onClick={() => onClickEdit(rowId)}>
                <Create fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={() => onClickDelete(rowId)}>
                <Delete fontSize="small" />
            </IconButton>
        </>
    );
}

EditTableButtons.propTypes = {
    ...EditTableClickProps(),
    rowId: PropTypes.string.isRequired
};
