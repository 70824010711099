import {FormControl, InputLabel, Select} from "@material-ui/core";

export default function Selection({value, onChange, label, children}) {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="create-label-dialog-select-label">{label}</InputLabel>
      <Select
        labelId="create-label-dialog-select-label"
        value={value}
        onChange={onChange}
        label={label}
      >
        {children}
      </Select>
    </FormControl>
  )
}
