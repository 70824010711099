import React from 'react';
import {Box, Button, Dialog, DialogActions} from '@material-ui/core';
import {useIntl} from 'react-intl';
import EnhancedTable from '../DataTable/EnhancedTable';

const RevisionsListDialog = ({open, onClose, entityId}) => {

  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({id: 'revisions.list.column.name', defaultMessage: 'Name'}),
      field: 'Name',
      render: row => {
        return row.user.name;
      }
    },
    {title: intl.formatMessage({id: 'revisions.list.column.description', defaultMessage: 'description'}), field: 'description'},
    {title: intl.formatMessage({id: 'revisions.list.column.created_at', defaultMessage: 'created_at'}), field: 'created_at'},
  ];
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
        <EnhancedTable
          title={intl.formatMessage({
            id: 'revisions.list.heading',
            defaultMessage: "Revisions"
          })}
          query={'entityRevisions'}
          fields={'user {name} description created_at'}
          columns={columns}
          filter={'entity_id:' + entityId}
          sorting={'created_at'}
          direction={'desc'}
          urlState={false}
        />
      <DialogActions style={{display: "flex", justifyContent: "flex-end"}}>
        <Box>
          <Button onClick={onClose}>{intl.formatMessage({id: 'common.button.ok'})}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
    ;
};

export default RevisionsListDialog;
