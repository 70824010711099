import React from "react";
import {useIntl} from "react-intl";
import StringField from "./StringField";
import moment from "moment";

export default function DateField(props) {
  const intl = useIntl();
  return <StringField {...props}
                      type={'date'}
                      valueFormatter={value => value && moment(value).format(intl.formatMessage({id: "common.date.format"}))}
  />;
}
