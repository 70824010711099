import React from "react";
import {Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Grid} from "@material-ui/core";

export default function CreateElementDialog(props) {
    const {elements, open, onClose, onAdd} = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle disableTypography><Typography variant="h2" color="primary">Create Element</Typography></DialogTitle>
            <DialogContent>
                <Grid container>
                {elements.map((element, index) => (
                    <Grid key={index} item xs={4}>
                        <Button color="primary" variant="outlined" fullWidth onClick={() => onAdd(element.type)}>{element.name}</Button>
                    </Grid>
                ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};