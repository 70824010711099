import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useIntl} from 'react-intl';
import {Button, makeStyles, Step, StepLabel, Stepper, Typography} from '@material-ui/core';
import EntitySelect from './EntitySelect';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

//NOTE: Selected values are handled without regard to updating selections.
const EntityWizard = forwardRef(({collections, handleFinishWizard}, ref) => {
  const intl = useIntl();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const steps = collections.map(collection => collection.title);
  const entitySelects = collections.map(collection => (
    <EntitySelect
      key={`${collection.id}-${collection.title}`}
      collection={collection}
      handleOnSelected={handleNext}
    />
  ));

  useEffect(() => {
    handleFinishWizard(steps.length === 0);
  }, []);

  function handleNext(values) {
    const valueIds = values.map(value => value.id);
    setSelectedValues(state => [...state, ...valueIds]);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (steps.length === activeStep +1) handleFinishWizard(true, [...selectedValues, ...valueIds]);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedValues([]);
      setActiveStep(0);
    }
  }), [])

  return (
    <div className={classes.root}>
      <Stepper className={classes.root} activeStep={activeStep} alternativeLabel>
        {steps.map(step => {
          return (
            <Step key={step}>
              <StepLabel>
                <Typography variant='h2'>{step}</Typography>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <div className={classes.root}>
        {entitySelects[activeStep]}
      </div>
      <div>
        {(activeStep > 0 && activeStep < steps.length) && <Button onClick={handleBack}>{intl.formatMessage({
          id: "common.button.back",
          defaultMessage: "Back"
        })}</Button>}
      </div>
    </div>
  );
});

export default EntityWizard;
