import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useIntl} from 'react-intl'
import hexToRgb from 'assets/theme/hex-to-rgb.js'
import {graphQLApi} from 'services/GraphQLApi'
import {authHeader, getApiDomain, useAuthDispatch} from 'contexts/Auth'
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Edit,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  Image,
  ViewColumn
} from '@material-ui/icons'
import CreateElementDialog from './CreateElementDialog'
import RowLayoutDialog from './RowLayoutDialog'
import LanguageSelector from 'components/Languages/LanguageSelector'
import {FieldTypes} from 'variables/fields'
import {Jodit} from "jodit-pro-react";
import {joditConfig} from "../../variables/general";
import {getThumbnailComponentFromUrl} from "../../components/FileTypes";
import {useNavigate, useParams} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  topBar: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.grey[100]) + ", 0.5)",
    height: 75,
    padding: 10,
    display: "flex",
    alignItems: "center",
    maxWidth: "unset",
    margin: "unset",
    borderBottom: "1px solid #ccc"
  },
  header: {
    padding: "0 20px"
  },
  settings: {
    padding: "0 20px"
  },
  settingsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsItemSelect: {
    // width: "70%",
    // padding: "0 20px"
  },
  actions: {
    padding: 0,
    textAlign: "right"
  },
  container: {
    maxWidth: "unset",
    margin: "unset"
  },
  content: {
    padding: 20,
    position: "relative",
    maxHeight: "85vh",
    overflowY: "auto",
    textAlign: "center",
  },
  sideBar: {
    backgroundColor: theme.palette.grey[100],
    padding: 20,
  },
  rowContainer: {
    marginTop: 10
  },
  row: {
    padding: 5,
    marginBottom: 20,
    // "&:hover": {
      border: "2px solid " + theme.palette.info.badgeBg,
      borderRadius: 4,
    // }
  },
  elementItem: {
    padding: 10,
    margin: 0,
    overflow: "hidden",
    border: "2px solid " + theme.palette.success.badgeBg,
    borderRadius: 4,
  },
  rowOptionsContainer: {
    position: "absolute",
    height: 30,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10
  },
  rowOptions: {
    padding: 4,
    borderRadius: 5,
    backgroundColor: "rgba(" + hexToRgb(theme.palette.info.badgeBg) + ",0.5)"
  },
  rowAdd: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10
  },
  rowAddButton: {
    background: theme.palette.info.badgeBg,
    color: theme.palette.text.primary,
    transition: "all .2s ease-in-out",
    "&:hover": {
      background: theme.palette.info.badgeBg,
      transform: "scale(1.2)"
    }
  },
  columnAddButton: {
    background: theme.palette.success.badgeBg,
    color: theme.palette.text.primary,
    transition: "all .2s ease-in-out",
    "&:hover": {
      background: theme.palette.success.badgeBg,
      transform: "scale(1.2)"
    }
  },
  columnOptions: {
    padding: 4,
    borderRadius: 5,
    backgroundColor: "rgba(" + hexToRgb(theme.palette.success.badgeBg) + ",0.5)"
  },
  optionsButton: {
    margin: "0 5px"
  },
  columnOptionsContainer: {
    position: "absolute",
    height: 30,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      border: "2px solid " + theme.palette.success.badgeBg,
      borderRadius: 4,
    },
    cursor: "pointer"
  },
  fieldSelect: {
    width: "100%",
    margin: "20px 0"
  },
  asset: {
    width: 200,
    fontSize: 200
  }
}));

export default function PrintoutEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const items = [
    {type: "field", name: "Field"},
    {type: "asset", name: "Asset"},
    {type: "string", name: "String"}
  ];

  const [printout, setPrintout] = useState({
    id: null,
    title: '',
    printout_elements: [],
    entity_type_id: null,
    language: null,
    settings: {},
  });
  const [fields, setFields] = useState([]);
  // const [assets, setAssets] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);

  let id = Number(params.id);

  const client = new graphQLApi(useAuthDispatch());
  const stableClient = useCallback(client, [client]);
  const printoutFields = 'id title language entity_type_id printout_elements{ id printout{id} type settings sorting' +
    ' children{ id printout{id} parent{id} type field{id type} string settings sorting} }';
  useEffect(() => {
    const id = Number(params.id);
    if (id) {
      // Query printout and set elements
      stableClient.query('{printouts(filter:{id:' + id + '}) {data{' + printoutFields + '}} }')
        .then(result => {
          if (result && result.hasOwnProperty('printouts')) {
            const printouts = result.printouts.data;
            if (printouts.length === 1) {
              const newPrintout = {...printouts[0]};
              const printoutElements = newPrintout.printout_elements;
              let transformedElements = [];
              printoutElements.forEach(e => {
                if (e.type !== "container") return;
                e.printout_id = e.printout.id;
                delete e.printout;
                e.settings = JSON.parse(e.settings);
                e.children = e.children.map(c => {
                  c.printout_id = c.printout.id;
                  delete c.printout;
                  c.parent_id = c.parent ? c.parent.id : null;
                  delete c.parent;
                  c.settings = JSON.parse(c.settings);
                  c.field_id = c.field ? c.field.id : null;
                  return c;
                });
                transformedElements.push(e);
              });
              newPrintout.printout_elements = transformedElements;
              if (newPrintout.settings) newPrintout.setting = JSON.parse(newPrintout.settings);
              setPrintout(newPrintout);
            }
          }
        });
    }
    stableClient.query('{fields' +
      `{data{
              id
              name
              type
              entity_type{title}
            }}` +
      `entityTypes{data{
                id
                title
            }}` +
      '}').then(result => {
      if (result && result.hasOwnProperty('fields')) {
        setFields(result.fields.data);
      }
      if (result && result.hasOwnProperty('entityTypes')) {
        setEntityTypes(result.entityTypes.data);
      }
    });
  }, []);

  const [selectedElement, setSelectedElement] = useState({});
  const [selectedRow, setSelectedRow] = useState({});

  const [activeElement, setActiveElement] = useState();

  const [createElementDialogOpen, setCreateElementDialogOpen] = useState(false);
  const [rowLayoutDialogOpen, setRowLayoutDialogOpen] = useState(false);

  const columnOptionsRef = useRef();
  const rowOptionsRef = useRef();

  const intl = useIntl();

  const classes = useStyles();

  const rowEnterHandler = (e, element) => {
    if (!e.target.id.includes("row-")) return;
    hideColumnOptions();
    setSelectedRow(element);
    const top = e.target.offsetTop - 15;
    const left = e.target.offsetLeft;
    rowOptionsRef.current.style.top = top + "px";
    rowOptionsRef.current.style.left = left + "px";
    rowOptionsRef.current.style.display = "flex";
  };

  const rowLeaveHandler = e => {
    e.stopPropagation();
    // setSelectedElement({});
    // setSelectedRow({});
  };

  const columnEnterHandler = (e, element) => {
    e.stopPropagation();
    setSelectedElement(element);
    const row = printout.printout_elements.find(e => e.id === element.parent_id);
    setSelectedRow(row);
    const top = e.target.offsetTop;
    const height = e.target.offsetHeight;
    const left = e.target.offsetLeft;
    const width = e.target.offsetWidth;
    columnOptionsRef.current.style.top = top + "px";
    columnOptionsRef.current.style.height = height + "px";
    columnOptionsRef.current.style.left = left + "px";
    columnOptionsRef.current.style.width = width + "px";
    columnOptionsRef.current.style.display = "flex";
  };

  const columnLeaveHandler = e => {
    e.stopPropagation();
    // setSelectedElement({});
  };

  const hideRowOptions = () => rowOptionsRef.current.style.display = "";
  const hideColumnOptions = () => columnOptionsRef.current.style.display = "";

  const randomId = () => new Date().toISOString() + "-" + Math.random();

  const newElementHandler = type => {
    const newElements = [...printout.printout_elements];
    const rowIndex = printout.printout_elements.findIndex(e => e.id === selectedRow.id);
    let index = printout.printout_elements[rowIndex].children.length - 1;
    if (index === -1) index = printout.printout_elements[rowIndex].children.length;
    const newElement = {
      id: randomId(),
      new: true,
      printout_id: printout.id,
      parent_id: selectedRow.id,
      type: type,
      field_id: null,
      string: null,
      settings: {alignment: "left"},
      sorting: index + 2,
    }
    if (type === "string" || type === "field") newElement.settings.fontSize = 14;
    newElements[rowIndex].children.splice(index + 1, 0, newElement);
    setPrintout({...printout, printout_elements: newElements});
    hideColumnOptions();
    setCreateElementDialogOpen(false);
    setActiveElement(newElement);
  };

  const deleteElementHandler = (e) => {
    e.stopPropagation();
    const newElements = [...printout.printout_elements];
    const rowIndex = printout.printout_elements.findIndex(e => e.id === selectedRow.id);
    const index = printout.printout_elements[rowIndex].children.findIndex(e => e.id === selectedElement.id);
    newElements[rowIndex].children.splice(index, 1);
    setPrintout({...printout, printout_elements: newElements});
    hideColumnOptions();
    if (selectedElement === activeElement) {
      setActiveElement({});
    }
    setSelectedElement({});
  }

  const newRowHandler = () => {
    const newElements = [...printout.printout_elements];
    let index = printout.printout_elements.findIndex(e => e.id === selectedRow.id);
    if (index === -1) index = printout.printout_elements.length;
    const newRow = {
      id: randomId(),
      new: true,
      printout_id: printout.id,
      parent_id: null,
      type: "container",
      children: [],
      field_id: null,
      string: null,
      settings: {columns: 1, col1Size: 12, col2Size: null, col3Size: null, col4Size: null},
      sorting: index + 2,
    };
    newElements.splice(index + 1, 0, newRow);
    setPrintout({...printout, printout_elements: newElements});
    setSelectedRow(newRow);
  };

  const deleteRowHandler = () => {
    const newElements = printout.printout_elements.filter(e => {
      if (!e.parent_id && e.id === selectedRow.id) return false;
      else if (e.parent_id === selectedRow.id) return false;
      else return true;
    });
    setPrintout({...printout, printout_elements: newElements});
    hideRowOptions();
    hideColumnOptions();
    setSelectedElement({});
    setSelectedRow({});
  };

  const moveRowHandler = (direction) => {
    const newElements = [...printout.printout_elements.sort(sortElements)];
    let rowIndex = newElements.findIndex(row => row.id === selectedRow.id);
    let offset = 0;
    if (direction === 'up' && rowIndex > 0) {
      offset = -1;
    }
    else if(direction === 'down' && newElements.length !== rowIndex+1) {
      offset = 1;
    }
    let tempSorting = newElements[rowIndex+offset].sorting;
    newElements[rowIndex+offset].sorting = newElements[rowIndex].sorting;
    newElements[rowIndex].sorting = tempSorting;
    setPrintout({...printout, printout_elements: newElements});
    hideRowOptions();
    hideColumnOptions();
    setSelectedElement({});
    setSelectedRow({});
  };

  const rowLayoutHandler = ({columns, col1Size, col2Size, col3Size, col4Size}) => {
    setRowLayoutDialogOpen(false);
    const newElements = [...printout.printout_elements];
    const index = printout.printout_elements.findIndex(e => e.id === selectedRow.id);
    newElements[index] = {...newElements[index], settings: {columns, col1Size, col2Size, col3Size, col4Size}};
    setPrintout({...printout, printout_elements: newElements});
  };

  const selectElementHandler = () => {
    setActiveElement(selectedElement);
  };

  const sortingHandler = (e, element) => {
    const newElements = [...printout.printout_elements];
    const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
    const children = printout.printout_elements[rowIndex].children;
    const elementIndex = children.findIndex(e => e.id === element.id);
    const newElement = {...element, sorting: e.target.value};
    newElements[rowIndex].children[elementIndex] = newElement;
    setPrintout({...printout, printout_elements: newElements});
    setSelectedElement(newElement);
    setActiveElement(newElement);
  };

  const toggleBoldHandler = (element) => {
    const newElements = [...printout.printout_elements];
    const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
    const children = printout.printout_elements[rowIndex].children;
    const elementIndex = children.findIndex(e => e.id === element.id);
    const newElement = {...element, settings: {...element.settings, bold: element.settings.bold ? false : true}};
    newElements[rowIndex].children[elementIndex] = newElement;
    setPrintout({...printout, printout_elements: newElements});
    setSelectedElement(newElement);
    setActiveElement(newElement);
  };

  const fontSizeHandler = (e, element) => {
    const value = e.target.value;
    const newElements = [...printout.printout_elements];
    const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
    const children = printout.printout_elements[rowIndex].children;
    const elementIndex = children.findIndex(e => e.id === element.id);
    const newElement = {...element, settings: {...element.settings, fontSize: value}};
    newElements[rowIndex].children[elementIndex] = newElement;
    setPrintout({...printout, printout_elements: newElements});
    setSelectedElement(newElement);
    setActiveElement(newElement);
  };

  const alignHandler = (type, element) => {
    const newElements = [...printout.printout_elements];
    if (!selectedRow.id) return;
    const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
    const children = printout.printout_elements[rowIndex].children;
    const elementIndex = children.findIndex(e => e.id === element.id);
    const newElement = {...element, settings: {...element.settings, alignment: type}};
    newElements[rowIndex].children[elementIndex] = newElement;
    setPrintout({...printout, printout_elements: newElements});
    setSelectedElement(newElement);
    setActiveElement(newElement);
  };

  const selectFieldHandler = (e, element) => {
    const fieldId = e.target.value;
    const newElements = [...printout.printout_elements];
    const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
    const children = printout.printout_elements[rowIndex].children;
    const elementIndex = children.findIndex(e => e.id === element.id);
    const newSettings = {...element.settings};
    const field = fields.find(f => f.id === fieldId);
    if (field && (field.type === FieldTypes.ASSET || field.type === FieldTypes.ASSETS)) {
      delete newSettings.fontSize;
      delete newSettings.bold;
      newSettings.width = '';
      newSettings.height = '';
      newSettings.padding = 5;
    }
    else {
      newSettings.fontSize = 14;
      newSettings.bold = false;
      delete newSettings.width;
      delete newSettings.height;
      delete newSettings.padding;
    }
    const newElement = {
      ...element,
      field_id: fieldId,
      field: field,
      settings: newSettings,
    };
    newElements[rowIndex].children[elementIndex] = newElement;
    setPrintout({...printout, printout_elements: newElements});
    setSelectedElement(newElement);
    setActiveElement(newElement);
  };

  // const selectAssetHandler = (e, element) => {
    // const assetId = e.target.value;
    // const asset = assets.find(a => a.id === assetId);
    // const newElements = [...printout.printout_elements];
    // const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
    // const children = printout.printout_elements[rowIndex].children;
    // const elementIndex = children.findIndex(e => e.id === element.id);
    // const newElement = {
    //   ...element,
    //   asset_id: assetId,
    //   settings: {...element.settings, width: Math.round(asset.width / 2), height: Math.round(asset.height / 2)}
    // };
    // newElements[rowIndex].children[elementIndex] = newElement;
    // setPrintout({...printout, printout_elements: newElements});
    // setSelectedElement(newElement);
    // setActiveElement(newElement);
  // };

  const stringHandler = (e, element) => {
    const value = e.target.value;
    const newElements = [...printout.printout_elements];
    const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
    const children = printout.printout_elements[rowIndex].children;
    const elementIndex = children.findIndex(e => e.id === element.id);
    const newElement = {...element, string: value};
    newElements[rowIndex].children[elementIndex] = newElement;
    setPrintout({...printout, printout_elements: newElements});
    setSelectedElement(newElement);
    setActiveElement(newElement);
  };

  const widthHandler = (e, element) => {
    const value = {width: +e.target.value};
    // const asset = element.string;
    // if (asset) {
    //   value.height = Math.round(value.width / (asset.width / asset.height));
    // }
    setSetting(value, element);
  };

  const heightHandler = (e, element) => {
    const value = {height: +e.target.value};
    // const asset = element.string;
    // if (asset) {
    //   value.width = Math.round(value.height * (asset.width / asset.height));
    // }
    setSetting(value, element);
  };

  const settingHandler = (e, setting, element) => {
    const value = {[setting]: +e.target.value};
    setSetting(value, element);
  };

  const setSetting = (value, element) => {
    const newElements = [...printout.printout_elements];
    const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
    const children = printout.printout_elements[rowIndex].children;
    const elementIndex = children.findIndex(e => e.id === element.id);
    const newElement = {...element, settings: {...element.settings, ...value}};
    newElements[rowIndex].children[elementIndex] = newElement;
    setPrintout({...printout, printout_elements: newElements});
    setSelectedElement(newElement);
    setActiveElement(newElement);
  };

  const languageHandler = (lang) => {
    setPrintout({...printout, language: lang});
  };

  const entityTypeHandler = (type) => {
    setPrintout({...printout, entity_type_id: type});
  };

  const saveHandler = () => {
    setSelectedRow({});
    setSelectedElement({});
    setActiveElement({});
    let transformedElements = [...printout.printout_elements];
    transformedElements = transformedElements.map(e => {
      e.settings = JSON.stringify(e.settings);
      e.sorting = parseInt(e.sorting);
      e.children = e.children.map(c => {
        if (c.new) {
          delete c.id;
          delete c.new;
        }
        c.settings = JSON.stringify(c.settings);
        c.sorting = parseInt(c.sorting);
        delete c.field;
        if (e.new) {
          c.parent_id = null;
        }
        return c;
      });
      if (e.new) {
        delete e.id;
        delete e.new;
      }
      return e;
    });
    const variables = {
      entity_type_id: "ID",
      language: "String",
      title: "String",
      printout_elements: "[PrintoutElementInput]",
      settings: "String",
    };
    const data = {
      entity_type_id: printout.entity_type_id,
      language: printout.language,
      title: printout.title,
      printout_elements: transformedElements,
      settings: JSON.stringify(printout.settings),
    };
    if (id) {
      variables.id = "ID!";
      data.id = id;
    } else {
      variables.data_class = 'String';
      data.data_class = 'entity';
    }
    client.mutation('printout', variables, data, printoutFields).then(result => {
      if (result && result.response) {
        if (!id && result.response.id) {
          navigate('/admin/printouts/' + result.response.id, {replace: true});
        }
        else if (result.response && result.response.hasOwnProperty('printout_elements')) {
          const printoutElements = result.response.printout_elements;
          let transformedElements = [];
          printoutElements.forEach(e => {
            if (e.type !== "container") return;
            e.printout_id = e.printout.id;
            delete e.printout;
            e.settings = JSON.parse(e.settings);
            e.children = e.children.map(c => {
              c.printout_id = c.printout.id;
              delete c.printout;
              c.parent_id = c.parent ? c.parent.id : null;
              delete c.parent;
              c.settings = JSON.parse(c.settings);
              c.field_id = c.field ? c.field.id : null;
              return c;
            });
            transformedElements.push(e);
          });
          setPrintout({...printout, printout_elements: transformedElements});
        }
      }
    });
  };

  const sortElements = (a,b) => {
    if (a.sorting > b.sorting) return 1;
    if (a.sorting < b.sorting) return -1;
    return 0;
  }


  let fileBrowserPro = null;
  const handleShowAssetSelector = (element) => {
    if (!fileBrowserPro) {
      fileBrowserPro = new Jodit.modules.FileBrowserPro({
        license: joditConfig.license,
        width: window.innerWidth * 0.8,
        height: window.innerHeight * 0.8,
        moveFolder: true,
        moveFile: true,
        ajax: {
          url: getApiDomain() + '/files/assets',
          headers: authHeader(),
        },
        uploader: {
          url: getApiDomain() + '/upload/assets',
          headers: authHeader(),
        },
      });
    }
    if (!fileBrowserPro.isOpened) {
      fileBrowserPro.open(images => {
        if (images.files?.length > 1) {
        //  setSnackbarMessage(intl.formatMessage({id:"entity.edit.alert.only_one_asset", defaultMessage:"This field only accepts 1 asset, so only the first {asset} has been used."}, {asset: images.files[0]}))
        }
        const asset = images.files[0];
        const newElements = [...printout.printout_elements];
        const rowIndex = printout.printout_elements.findIndex(e => e.id === element.parent_id);
        const children = printout.printout_elements[rowIndex].children;
        const elementIndex = children.findIndex(e => e.id === element.id);
        const newElement = {
          ...element,
          string: asset,
          settings: {...element.settings, width: 400, height: 300}
        };
        newElements[rowIndex].children[elementIndex] = newElement;
        setPrintout({...printout, printout_elements: newElements});
        setSelectedElement(newElement);
        setActiveElement(newElement);
      });
    }
  }

  return (
    <Paper>
      <Grid classes={{container: classes.topBar}} container>
        <Grid className={classes.header} item xs={2}>
          <Typography
            variant="h3">{
            !id
              ? intl.formatMessage({id: "printouts.edit.create_heading"})
              : intl.formatMessage({id: "printouts.edit.heading"})
          }</Typography>
        </Grid>
        <Grid className={classes.settings} item xs={9}>
          <Grid container>
            <Grid className={classes.settingsItem} item xs={5}>
              <TextField
                value={printout.title}
                onChange={e => setPrintout({...printout, title: e.target.value})}
                label={intl.formatMessage({id: 'printouts.edit.title', defaultMessage: 'Title'})}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid className={classes.settingsItem} item xs={4}>
              <TextField
                select
                fullWidth
                label={intl.formatMessage({id: 'printouts.edit.entity_type', defaultMessage: 'Entity type'})}
                value={printout.entity_type_id ? printout.entity_type_id : ''}
                onChange={(e) => entityTypeHandler(e.target.value)}
              >{entityTypes.map((entityType, index) => (
                <MenuItem
                  key={index}
                  value={entityType.id}
                >
                  {entityType.title}
                </MenuItem>
              ))}</TextField>
            </Grid>
            <Grid className={classes.settingsItem} item xs={3}>
              <LanguageSelector className={classes.settingsItemSelect}
                                value={printout.language ? printout.language : ''} onChange={languageHandler}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.actions} item xs={1}>
          <Button fullWidth onClick={saveHandler} size="small" variant="contained" color="primary">
            {intl.formatMessage({id: "common.button.save"})}
          </Button>
        </Grid>
      </Grid>
      <Grid classes={{container: classes.container}} container>
        <Grid className={classes.content} item xs={9}>
          <Grid container classes={{container: classes.rowContainer}}>
            {printout.printout_elements.sort(sortElements).map((row, index) => {
              let column = 1;
              return (
                <Grid
                  key={'row-'+index}
                  item
                  classes={{item: classes.row}}
                  xs={12}
                  id={"row-" + index + 1}
                  onMouseEnter={e => rowEnterHandler(e, row)}
                  onMouseLeave={rowLeaveHandler}
                >
                  <Grid container spacing={1}>
                    {row.children && row.children.sort(sortElements).map((element, index2) => {
                      let content = {};
                      const styles = {textAlign: 'left'};

                      if (element.settings.alignment === "center") styles.textAlign = "center";
                      else if (element.settings.alignment === "right") styles.textAlign = "right";
                      let stringStyles = {};
                      if (element.settings.fontSize) stringStyles.fontSize = element.settings.fontSize;
                      if (element.settings.bold) stringStyles.fontWeight = "bold";

                      if (element.type === "field") {
                        const field = fields.find(f => f.id === element.field_id);
                        content = <Typography style={stringStyles}>{field ? field.name : 'Select Field'}</Typography>;
                      } else if (element.type === "asset") {
                        const asset = element.string;
                        // let imageStyles = {};
                        // if (asset && element.settings.width && element.settings.height) imageStyles = {
                        //   width: element.settings.width,
                        //   height: element.settings.height
                        // };
                        content = asset ?
                          <Box display="inline-block" height={200} width="30%">{getThumbnailComponentFromUrl(asset)}</Box> :
                          <Image className={classes.asset}/>;
                      } else if (element.type === "string") {
                        content = <Typography style={stringStyles} dangerouslySetInnerHTML={{__html: (element.string && element.string.length)
                            ? element.string
                            : '<i>'+intl.formatMessage({id:"printouts.edit.string_placeholder", defaultMessage:"Click here to select the string element and edit your text."})+'</i>'}}/>;
                      }

                      if (column >= row.settings.columns) column = 1;
                      else column++;

                      return (
                        <Grid
                          key={'element-'+index+'-'+index2}
                          item
                          classes={{item: classes.elementItem}}
                          style={styles}
                          xs={row.settings['col'+column+'Size']}
                          onMouseEnter={e => columnEnterHandler(e, element)}
                          onMouseLeave={columnLeaveHandler}
                        >{content}</Grid>
                      );
                    })}
                  </Grid>
                  {selectedRow === row && <div className={classes.rowAdd}>
                    <IconButton title={intl.formatMessage({id: "printouts.edit.new_element"})}
                                onClick={() => setCreateElementDialogOpen(true)} className={classes.columnAddButton}
                                color="primary" size="small">
                      <Add/>
                    </IconButton>
                  </div>}
                </Grid>
              );
            })}
          </Grid>
          <div ref={rowOptionsRef} className={classes.rowOptionsContainer}>
            <div className={classes.rowOptions}>
              <IconButton title={intl.formatMessage({id: "printouts.edit.move_up", defaultMessage:"Move up"})}
                          onClick={() => moveRowHandler('up')} className={classes.optionsButton} color="primary"
                          size="small">
                <ArrowUpward/>
              </IconButton>
              <IconButton title={intl.formatMessage({id: "printouts.edit.move_down", defaultMessage:"Move down"})}
                          onClick={() => moveRowHandler('down')} className={classes.optionsButton} color="primary"
                          size="small">
                <ArrowDownward/>
              </IconButton>
              <IconButton title={intl.formatMessage({id: "printouts.edit.select_layout"})}
                          onClick={() => setRowLayoutDialogOpen(true)} className={classes.optionsButton} color="primary"
                          size="small">
                <ViewColumn/>
              </IconButton>
              <IconButton title={intl.formatMessage({id: "common.button.delete"})} onClick={deleteRowHandler}
                          className={classes.optionsButton} color="primary" size="small">
                <Delete/>
              </IconButton>
            </div>
          </div>
          <div onClick={selectElementHandler} ref={columnOptionsRef} className={classes.columnOptionsContainer}>
            <div className={classes.columnOptions}>
              <IconButton onClick={deleteElementHandler} className={classes.optionsButton} color="primary" size="small">
                <Delete/>
              </IconButton>
            </div>
          </div>
          <IconButton title={intl.formatMessage({id: "printouts.edit.new_row"})} onClick={newRowHandler}
                      className={classes.rowAddButton} color="primary" size="small">
            <Add/>
          </IconButton>
        </Grid>
        <Grid className={classes.sideBar} item xs={3}>
          <Typography variant="h3">{intl.formatMessage({id: "printouts.edit.options"})}</Typography>
          {activeElement ? (
            <Box>
              {activeElement.type === "field" && (<>
                  <TextField
                    fullWidth
                    select
                    label={intl.formatMessage({
                      id: 'printouts.edit.field',
                      defaultMessage: 'Field'
                    })}
                    id="field"
                    value={activeElement.field_id ? activeElement.field_id : ''}
                    onChange={(e) => selectFieldHandler(e, activeElement)}
                  >{fields.filter(f => f.type.search(/_\d+$/) === -1).map((field, index) =>
                    <MenuItem key={index} value={field.id}>{field.entity_type.title + ': ' + field.name}</MenuItem>
                  )}</TextField>
                  {activeElement.field && (activeElement.field.type === FieldTypes.ASSET || activeElement.field.type === FieldTypes.ASSETS) && (
                    <Box display="flex">
                      <FormControl className={classes.fieldSelect} style={{marginRight: 10}}>
                        <TextField
                          id="width"
                          label={intl.formatMessage({id: 'printouts.edit.width', defaultMessage: 'Width'})}
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                          }}
                          value={activeElement.settings.width}
                          onChange={(e) => widthHandler(e, activeElement)}
                        />
                      </FormControl>
                      <FormControl className={classes.fieldSelect} style={{marginLeft: 10}}>
                        <TextField
                          id="height"
                          label={intl.formatMessage({id: 'printouts.edit.height', defaultMessage: 'Height'})}
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                          }}
                          value={activeElement.settings.height}
                          onChange={(e) => heightHandler(e, activeElement)}
                        />
                      </FormControl>
                      <FormControl className={classes.fieldSelect} style={{marginLeft: 10}}>
                        <TextField
                          id="padding"
                          label={intl.formatMessage({id: 'printouts.edit.padding', defaultMessage: 'Padding'})}
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                          }}
                          value={activeElement.settings.padding}
                          onChange={(e) => settingHandler(e, 'padding', activeElement)}
                        />
                      </FormControl>
                    </Box>)}
                </>
              )}
              {activeElement?.type === "asset" && (
                <>
                  <Grid container>
                    <Grid item style={{flexGrow:1,height:200}}>{getThumbnailComponentFromUrl(activeElement.string)}</Grid>
                    <Grid item><IconButton size="small" onClick={_e => handleShowAssetSelector(activeElement)}>{!activeElement.string ? <Add/> : <Edit/>}</IconButton></Grid>
                  </Grid>
                  <Box display="flex">
                    <FormControl className={classes.fieldSelect} style={{marginLeft: 10}}>
                      <TextField
                        id="width"
                        label={intl.formatMessage({id: 'printouts.edit.width', defaultMessage: 'Width'})}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 0,
                        }}
                        value={activeElement.settings.width}
                        onChange={(e) => widthHandler(e, activeElement)}
                      />
                    </FormControl>
                    <FormControl className={classes.fieldSelect} style={{marginLeft: 10}}>
                      <TextField
                        id="height"
                        label={intl.formatMessage({id: 'printouts.edit.height', defaultMessage: 'Height'})}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 0,
                        }}
                        value={activeElement.settings.height}
                        onChange={(e) => heightHandler(e, activeElement)}
                      />
                    </FormControl>
                    <FormControl className={classes.fieldSelect} style={{marginLeft: 10}}>
                      <TextField
                        id="padding"
                        label={intl.formatMessage({id: 'printouts.edit.padding', defaultMessage: 'Padding'})}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 0,
                        }}
                        value={activeElement.settings.padding}
                        onChange={(e) => settingHandler(e, 'padding', activeElement)}
                      />
                    </FormControl>
                  </Box>
                </>
              )}
              {activeElement.type === "string" && (
                <TextField
                  className={classes.fieldSelect}
                  value={activeElement.string}
                  onChange={(e) => stringHandler(e, activeElement)}
                  label={intl.formatMessage({id: 'printouts.edit.string', defaultMessage: 'String'})}
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {activeElement.settings && activeElement.settings.fontSize && (
                <Grid container justifyContent="space-between" spacing={1} style={{marginTop:8,marginBottom:8}}>
                  <Grid item style={{flexGrow:1}}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({id: 'printouts.edit.font_size', defaultMessage: 'Font size'})}
                      select
                      id="fontSize"
                      value={activeElement.settings ? activeElement.settings.fontSize : ''}
                      onChange={(e) => fontSizeHandler(e, activeElement)}
                    >
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={14}>14</MenuItem>
                      <MenuItem value={16}>16</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item>
                    <ButtonGroup size="small">
                      <Button title={intl.formatMessage({id:"common.style.bold", defaultMessage:"Bold"})} onClick={() => toggleBoldHandler(activeElement)}
                              variant={activeElement.settings.bold && "contained"}
                              color={activeElement.settings.bold && "primary"}>
                        <FormatBold/>
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid item>
                    <ButtonGroup size="small">
                      <Button onClick={() => alignHandler("left", activeElement)}
                              variant={activeElement.settings.alignment === "left" && "contained"}
                              color={activeElement.settings.alignment === "left" && "primary"}>
                        <FormatAlignLeft/>
                      </Button>
                      <Button onClick={() => alignHandler("center", activeElement)}
                              variant={activeElement.settings.alignment === "center" && "contained"}
                              color={activeElement.settings.alignment === "center" && "primary"}>
                        <FormatAlignCenter/>
                      </Button>
                      <Button onClick={() => alignHandler("right", activeElement)}
                              variant={activeElement.settings.alignment === "right" && "contained"}
                              color={activeElement.settings.alignment === "right" && "primary"}>
                        <FormatAlignRight/>
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              )}
              {(!activeElement.settings?.fontSize && activeElement.settings?.alignment) && (
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                  <Typography>Alignment:</Typography>
                  <ButtonGroup size="small">
                    <Button onClick={() => alignHandler("left", activeElement)}
                            variant={activeElement.settings.alignment === "left" && "contained"}
                            color={activeElement.settings.alignment === "left" && "primary"}>
                      <FormatAlignLeft/>
                    </Button>
                    <Button onClick={() => alignHandler("center", activeElement)}
                            variant={activeElement.settings.alignment === "center" && "contained"}
                            color={activeElement.settings.alignment === "center" && "primary"}>
                      <FormatAlignCenter/>
                    </Button>
                    <Button onClick={() => alignHandler("right", activeElement)}
                            variant={activeElement.settings.alignment === "right" && "contained"}
                            color={activeElement.settings.alignment === "right" && "primary"}>
                      <FormatAlignRight/>
                    </Button>
                  </ButtonGroup>
                </Box>
              )}
              {activeElement.type && <TextField
                fullWidth
                label={intl.formatMessage({id: 'printouts.edit.sorting', defaultMessage: 'Sort order'})}
                value={activeElement.sorting}
                onChange={e => sortingHandler(e, activeElement)}
              />}
            </Box>
          ) : (
            <Typography>{intl.formatMessage({
              id: "printouts.edit.select_element",
              defaultMessage: "Select an element"
            })}</Typography>
          )}
        </Grid>
      </Grid>
      <CreateElementDialog
        elements={items}
        open={createElementDialogOpen}
        onClose={() => setCreateElementDialogOpen(false)}
        onAdd={newElementHandler}
      />
      <RowLayoutDialog
        open={rowLayoutDialogOpen}
        onClose={() => setRowLayoutDialogOpen(false)}
        onSelect={rowLayoutHandler}
      />
    </Paper>
  );
}
