import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import EditTable from '../EditTable/EditTable';
import AddFieldDialog from '../Dialogs/AddFieldDialog';
import EditFieldDialog from '../Dialogs/EditFieldDialog';
import {useAuthDispatch} from '../../contexts/Auth';
import {CircularProgress} from '@material-ui/core';
import {FieldTypes} from 'variables/fields';

const ComputationFields = forwardRef((props, ref) => {
    const intl = useIntl();
    const {field} = props;

    const columns = [
        intl.formatMessage({id: "field_computation.table_head.field", defaultMessage: "Field"}),
        intl.formatMessage({id: "field_computation.table_head.type", defaultMessage: "Type"})
    ];

    const [fields, setFields] = useState([]);

    // TODO: Figure out how validation is used.
    const [_validation, setValidation] = useState([]);

    const setValidationFromErrors = (errors) => {
        if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
            setValidation(...errors[0].extensions.validation);
        }
    };

    const [computableFields, setComputableFields] = useState(null);
    let [computableTypes, setComputableTypes] = useState(null);
    const client = new graphQLApi(
        useAuthDispatch(),
        null,
        {handleErrors: setValidationFromErrors}
    );
    useEffect(() => {
        if (field.computation_fields) {
            setFields(field.computation_fields);
        }

        client
            .query(`{fieldDefinitions{id computes column}}`)
            .then(r => {
                if (r && r.fieldDefinitions) {

                    let options = [];
                    let computables = [];
                    r.fieldDefinitions.find(entry => entry.id === field.type).computes.forEach(option => {
                            computables.push(option);
                            options.push(option);
                        });
                    r.fieldDefinitions.filter(def => options.find(o => o === def.column))?.forEach(def => {
                      computables.push(def.id);
                      options.push(def.id);
                    });
                    setComputableTypes(computables);
                    getFields(client, options);
                }
            })

    }, [field]);


    function getFields(client, options) {
        let filters = ['entity_type_id:'+field.entity_type_id.id];
        if (options.join('') !== "*") {
            filters.push(`type_in:"${options}"`);
        }
        if (field.uses_languages === false) {
          filters.push('uses_languages:false');
        }
        const query = `{fields(sorting:"name" direction:"asc" filter:{${filters.join(' ')}}){data{ id type name }}}`;
        client
            .query(query)
            .then(r => {
              if (r) setComputableFields(r.fields.data.filter(f => !RegExp('^' + FieldTypes.RELATIONS + '_[0-9]+').test(f.type)));
            })
            .catch(error => console.error(error));
    }

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editDialogInitialValue, setEditDialogInitialValue] = useState({});

    function editComputationField(id) {
        setEditDialogInitialValue(fields.find(field => field.id === id));
        setEditDialogOpen(true);
    }

    function deleteComputationField (id) {
        const newFields = fields.filter(field => field.id !== id).map(field => field.id);
        const query = `{fieldUpdate(id:${field.id}, computation_fields:[${newFields}]) { computation_fields{id name type} }}`;
        client
            .mutate(query)
            .then(r => {
                if (r && r.hasOwnProperty('fieldUpdate')) {
                    setFields(r.fieldUpdate.computation_fields ? r.fieldUpdate.computation_fields : []);
                    handleSetcomputationFields(r.fieldUpdate.computation_fields);
                }
            });
    }

    function handleSetcomputationFields(newFields) {
        if (newFields) field.computation_fields = newFields;
        else field.computation_fields = fields;
    }

    function handleDialogClose(data) {
        setAddDialogOpen(false);

        if (!data || fieldAlreadyExists(data)) return;

        setFields([...fields, data]);
    }

    function handleEditDialogClose(data) {
        setEditDialogOpen(false);

        if (!data || fieldAlreadyExists(data)) return;

        const newFields = fields.map(field => {
            if (field.id === editDialogInitialValue.id) {
                return data;
            } else {
                return field;
            }
        });

        setFields(newFields);
    }

    function fieldAlreadyExists(data) {
        if (fields.find(field => field.id === data.id) === undefined) return false;
        return true;

    }

    useImperativeHandle(ref, () => ({
        addComputation() {
            //BUG: Returns to fields view after saving.
            setAddDialogOpen(true);
        },
        setComputationFields() {
            handleSetcomputationFields();
        },
        fieldsAreCompatible() {
            const bool = fields.length > 0 ? fields.some(item => computableTypes.includes("*") || computableTypes.includes(item.type)) : true;

            return bool;
        }
    }));

    return (
      <div style={{textAlign: 'center'}}>
        {(!field || !computableTypes) ? (
            <CircularProgress/>
        ) : (
          <EditTable
            columns={columns}
            data={fields}
            onClickEdit={editComputationField}
            onClickDelete={deleteComputationField}
            computableTypes={computableTypes}
            warningMessage={intl.formatMessage({
              id: "computation_fields.incompatible_field_type",
              defaultMessage: "Incompatible Field type"
            })}
          />
        )}
        {computableFields
            && <AddFieldDialog
            open={addDialogOpen}
            fields={computableFields}
            onClickClose={handleDialogClose}
            title={intl.formatMessage({id: "common.dialog.add.title", defaultMessage: "Add"})}
            label={intl.formatMessage({id: "common.dialog.add.label", defaultMessage: "Field"})} />}
        {computableFields
            && <EditFieldDialog
            open={editDialogOpen}
            fields={computableFields}
            onClickClose={handleEditDialogClose}
            title={intl.formatMessage({id: "common.dialog.edit.title", defaultMessage: "Edit Field"})} label={intl.formatMessage({id: "common.dialog.edit.title", defaultMessage: "Edit"})}
            initialValue={editDialogInitialValue} />
        }
      </div>
    );
});

export default ComputationFields;
