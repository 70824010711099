import React, {useRef, useState} from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField
} from "@material-ui/core";
import {FileCopy, ViewColumn} from "@material-ui/icons";
import {useIntl} from "react-intl";
import {graphQLApi} from "../../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../../contexts/Auth";
import EnhancedTable from "../../../../components/DataTable/EnhancedTable";

export default function LayoutList(props) {
  const intl = useIntl();
  const [showCopy, setShowCopy] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const client = new graphQLApi(useAuthDispatch());
  const tableRef = useRef();
  const columns = [
    {title: intl.formatMessage({id: 'entity_type_layouts.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {
      title: intl.formatMessage({id: 'entity_type_layouts.list.column.sorting', defaultMessage: 'Sorting'}),
      field: 'sorting',
      type: 'numeric'
    },
    {
      title: intl.formatMessage({
        id: "entity_type_layouts.list.column.roles",
        defaultMessage: "Roles",
      }),
      field: "roles",
      render: row => {
        return row.roles.map((r, k) =>
          <Chip size="small" key={"user_" + row.id + "_roles_" + k} label={r.title} style={{marginRight: 3}}/>
        )
      }
    },
  ];
  if (!props.filter) {
    columns.splice(1, 0,
      {
        title: intl.formatMessage({id: 'entity_type_layouts.list.column.entity_type', defaultMessage: 'Entity type'}),
        field: 'listing.entity_type.title'
      });
  }
  const {elevation = 2, margin = 3} = props;

  return (<Paper elevation={elevation} style={{margin: margin}}>
      <EnhancedTable
        ref={tableRef}
        title={intl.formatMessage({id: 'entity_type_layouts.list.table.heading', defaultMessage: "Form tabs"})}
        columns={columns}
        sorting={"sorting"}
        query="entityTypeLayouts"
        mutations="entityTypeLayout"
        fields='id title entity_type{title} sorting roles{title}'
        icon={<ViewColumn/>}
        actions={[
          {
            icon: FileCopy,
            tooltip: intl.formatMessage({id: "common.button.copy", defaultMessage: "Copy"}),
            onClick: row => setShowCopy(row),
          }
        ]}
        {...props}
      />
      <Dialog open={showCopy !== null} onClose={() => setShowCopy(null)}>
        <DialogTitle disableTypography>
          {intl.formatMessage({
            id: "entity_type_layouts.list.dialog.copy.heading",
            defaultMessage: "Enter the new title of the copied view {title}"
          }, showCopy)}
        </DialogTitle>
        <DialogContent>
          <TextField
            label={intl.formatMessage({id: "entity_type_layouts.list.dialog.copy.title", defaultMessage: "Title"})}
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
            size="small"
            fullWidth
          />
        </DialogContent>
        {showCopy === false && <DialogActions style={{display: "flex", justifyContent: "space-around"}}>
          <CircularProgress/>
        </DialogActions>}
        {showCopy !== false && <DialogActions style={{justifyContent: "space-between", display: "flex"}}>
          <Button variant="contained"
                  onClick={_e => setShowCopy(null)}>{intl.formatMessage({id: "common.button.close"})}</Button>
          <Button
            startIcon={<FileCopy/>}
            size="small"
            variant="contained"
            color="primary"
            disabled={newTitle.length < 3}
            onClick={_e => {
              setShowCopy(false);
              client.mutate('{entityTypeLayoutCopy(id:' + showCopy.id + ', title:"' + newTitle + '"){id}}').then(r => {
                if (r && tableRef.current) {
                  tableRef.current.update();
                  setNewTitle("");
                }
                setShowCopy(null);
              });
            }}
          >{
            intl.formatMessage({id: "common.button.copy", defaultMessage: "Copy"})
          }</Button>
        </DialogActions>}
      </Dialog>
    </Paper>
  );
}
