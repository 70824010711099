export function FtpFileServerTest(intl) {
  return {
    'host': [intl.formatMessage({id: "connector.driver.ftp_file_upload.test.host", defaultMessage: "Connection test failed, check host setting."})],
    'port': [intl.formatMessage({id: "connector.driver.ftp_file_upload.test.port", defaultMessage: "Connection test failed, check port setting."})],
    'username': [intl.formatMessage({id: "connector.driver.ftp_file_upload.test.username", defaultMessage: "Connection test failed, check username setting."})],
    'password': [intl.formatMessage({id: "connector.driver.ftp_file_upload.test.password", defaultMessage: "Connection test failed, check password setting."})],
  }
}

export default function FtpFileServer(intl) {
  return [
    {
      field: "filetype",
      initial: "xml",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.filetype", defaultMessage: "Filetype"}),
      options: [
        {id:"xml", name:"XML file"},
        {id:"csv", name:"Comma separated file"},
        {id:"ssv", name:"Semicolon separated file"},
        {id:"tsv", name:"Tab separated file"},
      ]
    },
    {
      field: "header",
      initial: "yes",
      label: intl.formatMessage({
        id: "connector.driver.csv_file.header",
        defaultMessage: "Does the file have a header row?"
      }),
      options: [
        {id: "yes", name: "There is a header, use it as reference"},
        {id: "no", name: "No header, first line is data"},
      ]
    },
    {
      field: "path",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.filepath", defaultMessage: "Filepath"}),
      help: intl.formatMessage({
        id: "connector.driver.ftp_file_upload.filepath_help",
        defaultMessage: "The full file path including the files name. Example: products/export_from_pim"
      }),
    },
    {
      field: "import",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.import", defaultMessage: "Import filename"}),
      help: intl.formatMessage({
        id: "connector.driver.ftp_file_upload.import_help",
        defaultMessage: "The name of the file to be imported from, can be a regular expression. Example: products.xml or /^Inventory.*\\.(?:XML|xml)$/"
      }),
    },
    {
      field: "export",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.export", defaultMessage: "Export Filepath"}),
      help: intl.formatMessage({
        id: "connector.driver.ftp_file_upload.export_help",
        defaultMessage: "The name of the file to be exported to, can contain {datetime} replacement. Example: products.xml or inventory-{datetime}.xml"
      }),
    },
    {
      field: "host",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.host", defaultMessage: "Host"}),
    },
    {
      field: "port",
      initial: 21,
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.port", defaultMessage: "Port"}),
      input: "number",
    },
    {
      field: "encryption",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.encryption", defaultMessage: "Secure transport"}),
      options: [
        {id:"", name:"None"},
        {id:"ssl", name:"SSL"},
      ]
    },
    {
      field: "username",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.username", defaultMessage: "Username"}),
      inputProps: {autoComplete: "off"},
    },
    {
      field: "password",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.password", defaultMessage: "Password"}),
      input: "password",
      inputProps: {autoComplete: "off"}
    }
  ];
}
