import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";

export default function RowLayoutDialog(props) {
    const {open, onClose, onSelect} = props;

    const layouts = [
        { name: "1 Column", columns: 1, col1Size: 12, col2Size: null, col3Size: null, col4Size: null },
        { name: "2 Columns", columns: 2, col1Size: 6, col2Size: 6, col3Size: null, col4Size: null },
        { name: "3 Columns", columns: 3, col1Size: 4, col2Size: 4, col3Size: 4, col4Size: null },
        { name: "4 Columns", columns: 4, col1Size: 3, col2Size: 3, col3Size: 3, col4Size: 3 }
    ];

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle disableTypography><Typography variant="h2" color="primary">Select Layout</Typography></DialogTitle>
            <DialogContent>
                <Grid container>
                {layouts.map((layout, index) => (
                    <Grid key={index} item xs={6}>
                        <Button color="primary" onClick={() => onSelect(layout)}>{layout.name}</Button>
                    </Grid>
                ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};