export function GeckoBookingItemsTest(intl) {
  return {
    apiKey: [intl.formatMessage({id: "connector.driver.gecko_booking_items.test.api_key", defaultMessage: "Connection test failed, check api token."})],
  }
}

export default function GeckoBookingItems(intl) {
  return [
    {
      field: "apiKey",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.gecko_booking_items.api_key", defaultMessage: "API Token"}),
      inputProps: {autoComplete: "off"},
    },
    {
      field: "apiUrl",
      initial: "https://app2.geckobooking.dk/api/v1/",
      label: intl.formatMessage({id: "connector.driver.gecko_booking_items.api_url", defaultMessage: "API URL"}),
      inputProps: {autoComplete: "off"},
      help: intl.formatMessage({id: "connector.driver.gecko_booking_items.api_url_help", defaultMessage: "Example: https://app2.geckobooking.dk/api/v1/"}),
    },
  ];
}
