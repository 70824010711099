import {useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {useIntl} from 'react-intl';
import FieldSelection from './FieldSelection';

export default function LabelElementDialog({onClose, open, row, entityTypeId, ...rest}) {
  const intl = useIntl();

  const [alignment, setAlignment] = useState(row.alignment);
  const [type, setType] = useState(rest.types.find(pt => pt.id === row.type));
  const [string, setString] = useState(row.string);
  const [field, setField] = useState(rest.fields.find(field => row.field && field.id === row.field.id));

  const separatorList = [
    {
      key: '',
      title: intl.formatMessage({
        id: "labels.edit.separator.null",
        defaultMessage: "None"
      })
    },
    {
      key: 'new_line',
      title: intl.formatMessage({
        id: "labels.edit.separator.new_line",
        defaultMessage: "New Line"
      })
    },
    {
      key: 'comma',
      title: intl.formatMessage({
        id: "labels.edit.separator.comma",
        defaultMessage: "Comma Separation"
      })
    },
    {
      key: 'slash',
      title: intl.formatMessage({
        id: "labels.edit.separator.slash",
        defaultMessage: "Slash Separation"
      })
    },
    {
      key: 'file',
      title: intl.formatMessage({
        id: "labels.edit.separator.file",
        defaultMessage: "File"
      })
    },
  ]
  const [separator, setSeparator] = useState(row.separator);
  const [lineHeight, setLineHeight] = useState(row.alignment);
  const [allLanguages, setAllLanguages] = useState(!!row.is_all_languages_exported);

  function handleCancel() {
    onClose();
  }

  function handleOk() {
    if (['field', 'field_with_title'].includes(type.id) && !field && type.id !== 'text') return;
    onClose({
      ...row,
      type: type,
      field: field,
      string: string,
      alignment: separator !== 'new_line' ? alignment : lineHeight,
      separator: separator,
      is_all_languages_exported: allLanguages,
    }, (!row.id));
  }

  function getContent() {
    switch (type && type.id) {
      case 'text':
        return (
          <TextField
            fullWidth
            value={string}
            onChange={e => setString(e.target.value)}
            label={intl.formatMessage({id: "labels.edit.label.string", defaultMessage: "String"})}
          />
        );
      case 'field':
        return (
          <FieldSelection
            value={field}
            onChange={e => setField(e.target.value)}
            fields={rest.fields}
          />
        );
      case 'field_with_title':
        return (
          <>
            <FieldSelection
              value={field}
              onChange={e => setField(e.target.value)}
              fields={rest.fields}
            />
            <FormControl fullWidth>
              <TextField
                value={string}
                onChange={e => setString(e.target.value)}
                label={intl.formatMessage({id: "labels.edit.label.title", defaultMessage: "Title"})}
              />
            </FormControl>
            {separator !== 'new_line' && <FormControl fullWidth>
              <TextField
                type="number"
                InputProps={{min: 0}}
                value={alignment}
                onChange={e => setAlignment(e.target.value)}
                label={intl.formatMessage({id: 'labels.edit.label.alignment', defaultMessage: 'Alignment'})}
              />
            </FormControl>}
          </>
        );
      default:
        break;
    }
  }

  function buildContent() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={intl.formatMessage({id: "labels.edit.label.element_type", defaultMessage: "Element Type"})}
            select
            value={type}
            onChange={e => setType(e.target.value)}
          >
            {rest.types.map(type => <MenuItem value={type} key={type.id}>{type.title}</MenuItem>)}
          </TextField>
        </Grid>
        {((field && field.entity_type.id !== entityTypeId) || allLanguages) ? (
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label={intl.formatMessage({id: "labels.edit.label.element_separator", defaultMessage: "Separator"})}
              value={separator}
              onChange={e => setSeparator(e.target.value)}
            >
              {separatorList.map((option, k) => <MenuItem value={option.key} key={'separators-'+k}>{option.title}</MenuItem>)}
            </TextField>
          </Grid>
        ) : ''}
        {separator === 'new_line'
          ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                value={lineHeight}
                onChange={e => setLineHeight(parseInt(e.target.value))}
                label={intl.formatMessage({
                  id: "labels.edit.label.element_line_height",
                  defaultMessage: "Lineheight"
                })}
              />
            </Grid>
          ) : ''}
        <Grid item xs={12}>
          {getContent()}
        </Grid>
        {field && field.uses_languages ? <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allLanguages}
                onChange={e => setAllLanguages(e.target.checked)}
                name="allLanguages"
                color="primary"
              />
            }
            label={intl.formatMessage({
              id: 'labels.edit.label.element_all_languages',
              defaultMessage: 'Export all languages',
            })}
          />
        </Grid> : ''}
      </Grid>
    );
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      aria-labelledby='labelelement-dialog-title'
      open={open}
      {...rest}
    >
      <DialogTitle disableTypography id='labelelement-dialog-title'>
        <Typography variant='h2' style={{textTransform: 'capitalize'}}>
          {intl.formatMessage({
            id: `labels.dialog.title.${row.id ? 'edit' : 'new'}`,
            defaultMessage: row ? 'edit' : 'new'
          })}
        </Typography>
      </DialogTitle>
      <DialogContent divider>
        {buildContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='default'>
          {intl.formatMessage({id: "common.button.cancel", defaultMessage: "Cancel"})}
        </Button>
        <Button onClick={() => handleOk()} color='primary'>
          {intl.formatMessage({id: "common.button.ok"})}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
