import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core';

function EnhancedTableHead(props) {
  const {
    columns,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    selected,
    rows,
    onRequestSort,
    actions,
    dragHandle,
    children
  } = props;

  const hasOnSelectedActions = () => actions && actions.filter(af => af.onSelected === true).length > 0;
  const createSortHandler = (property) => (event) => {
    event.stopPropagation();
    onRequestSort(event, property);
  };
  const allRowsSelected = (arr1, arr2) => {
    return arr1.every(elem => arr2.includes(elem));
  };

  return (<TableHead>
    <TableRow>
      {dragHandle
        ? <TableCell align="right"></TableCell> : null}
      {(hasOnSelectedActions() || children) ?
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={selected.length > 0 && selected.length < rows.length}
            checked={rows.length > 0 && allRowsSelected(rows, selected)}
            onClick={event => {
              event.stopPropagation();
              return onSelectAllClick(event);
            }}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell> : null}
      {columns.map((headCell, index) => (
        <TableCell
          key={index}
          width={headCell.width ? headCell.width : 'auto'}
          align={headCell.align ? headCell.align : 'left'}
          padding={headCell.disablePadding ? 'none' : 'normal'}
          sortDirection={orderBy === headCell.field ? order : false}
        >{headCell.sortable === false ? headCell.title :
          <TableSortLabel
            active={orderBy === headCell.field}
            direction={orderBy === headCell.field ? order : 'asc'}
            onClick={createSortHandler(headCell.field)}
          >{headCell.title}{orderBy === headCell.field ? (
            <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
          ) : null}</TableSortLabel>
        }</TableCell>
      ))}
      {(actions && actions.filter(action => !action.toolbar).length)
        ? <TableCell align="right"></TableCell> : null
      }</TableRow>
  </TableHead>);
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto', 'inherit', 'default'])]),
      render: PropTypes.func,
      align: PropTypes.oneOf(['left', 'right']),
    })
  ).isRequired,
  selected: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
      tooltip: PropTypes.string.isRequired,
      label: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      toolbar: PropTypes.bool,
      onSelected: PropTypes.bool,
      isFreeAction: PropTypes.bool,
      rowClick: PropTypes.bool,
    })
  ),
};

export default EnhancedTableHead;
