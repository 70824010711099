
export function SmartWebOrdersTest(intl) {
  return {
    'username': [intl.formatMessage({id: "connector.driver.smartweb.test.username", defaultMessage: "Connection test failed, check username setting."})],
    'password': [intl.formatMessage({id: "connector.driver.smartweb.test.password", defaultMessage: "Connection test failed, check password setting."})],
  }
}

export default function SmartWebOrders(intl) {
  let fields = [
    {
      field: "username",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.smartweb.username", defaultMessage: "Username"}),
      inputProps: {autoComplete: "off"},
    },
    {
      field: "password",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.smartweb.password", defaultMessage: "Password"}),
      input: "password",
      inputProps: {autoComplete: "off"}
    },
    {
      field: "status_import",
      initial: "1",
      label: intl.formatMessage({id: "connector.driver.smartweb_orders.status_import.label", defaultMessage: "What status(es) should orders have to be imported?"}),
      help: intl.formatMessage({id: "connector.driver.smartweb_orders.status_import.help", defaultMessage: "A comma separated list of status ids (0: Not Received, 1: Order Received, 2: Processing, 3: Order Sent, 4: Reopen, 5: Cancelled, 6: Ready for pickup, 7: Partially sent, 8: Picked up, 99: Draft, 100: Credit note)"}),
    },
    {
      field: "status_imported",
      initial: "2",
      label: intl.formatMessage({id: "connector.driver.smartweb_orders.status_imported.label", defaultMessage: "What status should order be set to after import?"}),
      help: intl.formatMessage({id: "connector.driver.smartweb_orders.status_imported.help", defaultMessage: "0: Not Received, 1: Order Received, 2: Processing, 3: Order Sent, 4: Reopen, 5: Cancelled, 6: Ready for pickup, 7: Partially sent, 8: Picked up, 99: Draft, 100: Credit note"}),
    },
    {
      field: "status_sent",
      initial: "3",
      label: intl.formatMessage({id: "connector.driver.smartweb_orders.status_sent.label", defaultMessage: "What status should order be set to after tracking code update?"}),
      help: intl.formatMessage({id: "connector.driver.smartweb_orders.status_imported.help", defaultMessage: "0: Not Received, 1: Order Received, 2: Processing, 3: Order Sent, 4: Reopen, 5: Cancelled, 6: Ready for pickup, 7: Partially sent, 8: Picked up, 99: Draft, 100: Credit note"}),
    },
    {
      field: "discounts_as_line_sku",
      initial: "RABATKODE",
      label: intl.formatMessage({id: "connector.driver.smartweb_orders.discounts_as_line_sku", defaultMessage: "If discounts should be imported as order lines, then set this item number on each line."}),
    },
    {
      field: "delivery_as_line_sku",
      initial: "FRAGT",
      label: intl.formatMessage({id: "connector.driver.smartweb_orders.delivery_as_line_sku", defaultMessage: "If delivery should be imported as an order line, then set this item number on each line."}),
    },
  ];
  return fields;
}
