import React, {useContext} from "react";
import {TextField} from "@material-ui/core";
import {LanguageContext} from "../../../../contexts/Layouts";
import {getFieldLabel, RenderEditableField} from "./Field";
import {EntityContext} from "../../EntityLayoutEditor";
import {getEntityValueColumnFromField} from "../../../../variables/fields";

export default function StringField(
  {
    field,
    valueFormatter = v => v,
    ...rest
  }) {
  const {language} = useContext(LanguageContext);
  const {getValuesOfField, setValuesOfField, setEditing} = useContext(EntityContext);
  const column = getEntityValueColumnFromField(field);
  // console.log(field.name, column);
  let values = getValuesOfField(field.id);
  // console.log(field.name, values, language.id);
  let value = {};
  if (field.uses_languages) {
    value = values?.find(v => v.language_id === language.id) || {};
  } else {
    value = values?.pop() || {};
  }
  // console.log(field.name, value, value[column]);
  return <RenderEditableField
    field={field}
    value={value ? valueFormatter(value[column]) : ''}
  >
    <TextField
      {...rest}
      style={{flexGrow: 1}}
      fullWidth
      autoFocus
      label={getFieldLabel(field, language)}
      value={value[column] || ""}
      onChange={e => setValuesOfField(field.id, [{
        ...value,
        [column]: e.target.value,
      }])}
      onKeyUp={e => ['Enter', 'Escape'].includes(e.code) && setEditing(null)}
    />
  </RenderEditableField>;
}
