
export function NettButikkTest(intl) {
    return {
        'username': [intl.formatMessage({id: "connector.driver.nett_butikk.test.url", defaultMessage: "Connection test failed, check username setting."})],
        'password': [intl.formatMessage({id: "connector.driver.nett_butikk.test.access_token", defaultMessage: "Connection test failed, check password setting."})],
    }
}

export default function NettButikk(intl) {
    let fields = [
        {
            field: "url",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.nett_butikk.url", defaultMessage: "Url"}),
            inputProps: {autoComplete: "off"},
        },
        {
            field: "access_token",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.nett_butikk.access_token", defaultMessage: "Access token"}),
            input: "access_token",
            inputProps: {autoComplete: "off"}
        }
    ];
    return fields;
}
