import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import {authLogin, getApiDomain, useAuthDispatch} from "contexts/Auth";
import {CardActions, CardHeader, CircularProgress, TextField} from "@material-ui/core";
import {useIntl} from "react-intl";
import {useLocation, useNavigate} from "react-router-dom";
import versions from "../versions";

const useStyles = makeStyles(componentStyles);

function Login() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useAuthDispatch();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const validationFields = {email: [], password: [], message: [], name: [], phone: []};
  const [validation, setValidation] = React.useState(validationFields);
  const [domainCheck, setDomainCheck] = React.useState(null);

  React.useEffect(() => {
    if (domainCheck === null) {
      fetch(getApiDomain() + "/info" + location.search, {
        headers: {Accept: 'text/json'},
      }).then(response => response.json())
        .then(json => {
          setDomainCheck(json);
        }).catch(reason => {
        console.error('Failed to validate the API domain', getApiDomain() + "/", reason);
        setDomainCheck(false);
      });
    }
  });

  const getValidation = (apiValidation) => {
    let errors = {};
    if (apiValidation.error === "Unauthorized") {
      errors.email = "Please recheck";
      errors.password = "Please recheck";
      errors.message = "Email and/or password seems to be wrong, please check and try again.";
    }
    errors = {...validationFields, ...apiValidation, ...errors};
    setValidation(errors);
    //console.log('getValidation', apiValidation, errors, validation);
  };

  const performLogin = (e) => {
    e.preventDefault();
    authLogin(dispatch, navigate, setIsLoading, email, password, getValidation);
  };

  const classes = useStyles();
  return (
    <Grid item xs={12} lg={5} md={7} style={{display: "flex"}} justifyContent="center">
      {domainCheck === null ? (
        <CircularProgress color={"secondary"}/>
      ) : domainCheck.error ? (
        <Card color="danger">
          <CardHeader
            avatar={<i className={"ni ni-bell-55"}/>}
            title={intl.formatMessage({
              id: "login.domain-is-incorrect.header",
              defaultMessage: "The OpenDIMS you have tried to access is not available"
            })}
          />
          <CardContent>
            <strong>{intl.formatMessage({
              id: "login.domain.information",
              defaultMessage: "Further information"
            })}</strong><br/>
            {domainCheck.message
              ? domainCheck.message
              : intl.formatMessage({
                id: "login.domain-is-incorrect.body",
                defaultMessage: "It appears that this domain is not configured, have you entered the correct URL?"
              })}
          </CardContent>
        </Card>
      ) : domainCheck.code === 'register' ? (
        <Card color="danger" component={"form"} onSubmit={e => {
          e.preventDefault();
          let formData = new FormData(e.target);
          fetch(getApiDomain() + '/auth/register', {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            body: formData,
            headers: {
              "Accept": "application/json",
            },
          }).then(r => {
            if (r.status === 201) {
              performLogin(e);
            } else return r.json();
          }).then(r => {
            setValidation({...validationFields, ...r?.errors});
          });
        }}>
          <CardHeader
            title={intl.formatMessage({
              id: "login.register.header",
              defaultMessage: "OpenDIMS is almost ready for you!"
            })}
            subheader={intl.formatMessage({
              id: "login.register.subheader",
              defaultMessage: "We just need to create your admin user"
            })}
          />
          <CardContent>
            <Grid container spacing={2}>
              <input type="hidden" name="version" defaultValue={versions.pop()?.version}/>
              <input type="hidden" name="token" defaultValue={searchParams.get('token')}/>
              <Grid item xs={12}>
                <TextField
                  error={validation.name.length > 0}
                  fullWidth
                  label={intl.formatMessage({id: "login.register.name", defaultMessage: "Name"})}
                  name={"name"}
                  helperText={validation.name.length > 0 ? validation.name.join(" ") : null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={validation.email.length > 0}
                  fullWidth
                  label={intl.formatMessage({id: "login.register.email", defaultMessage: "Email"})}
                  name={"email"}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  helperText={validation.email.length > 0 ? validation.email.join(" ") : null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={validation.phone.length > 0}
                  fullWidth
                  label={intl.formatMessage({id: "login.register.phone", defaultMessage: "Telephone"})}
                  name={"phone"}
                  helperText={validation.phone.length > 0 ? validation.phone.join(" ") : null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={validation.password.length > 0}
                  fullWidth
                  label={intl.formatMessage({id: "login.register.password", defaultMessage: "Password"})}
                  name={"password"}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  helperText={validation.password.length > 0 ? validation.password.join(" ") : null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={validation.password.length > 0}
                  fullWidth
                  label={intl.formatMessage({
                    id: "login.register.password-confirm",
                    defaultMessage: "Confirm the password"
                  })}
                  name={"password_confirmation"}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{display: "flex", justifyContent: "space-around"}}>
            <Button
              type={"submit"}
              color={"primary"}
              variant={"contained"}
            >{intl.formatMessage({id: "login.register.save", defaultMessage: "Start your OpenDIMS"})}</Button>
          </CardActions>
        </Card>
      ) : (
        <Card classes={{root: classes.cardRoot}}>
          <CardContent classes={{root: classes.cardContent}} component="form" onSubmit={performLogin}>
            <div style={{marginBottom: 20}}>{validation.message}</div>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FilledInput
                autoComplete="off"
                type="email"
                placeholder={intl.formatMessage({id: "auth.email", defaultMessage: "Email"})}
                value={email}
                onChange={e => setEmail(e.target.value)}
                error={validation.email.length > 0}
                startAdornment={
                  <InputAdornment position="start">
                    <Email/>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FilledInput
                autoComplete="off"
                type="password"
                placeholder={intl.formatMessage({id: "auth.password", defaultMessage: "Password"})}
                value={password}
                onChange={e => setPassword(e.target.value)}
                error={validation.password.length > 0}
                startAdornment={
                  <InputAdornment position="start">
                    <Lock/>
                  </InputAdornment>
                }
              />
            </FormControl>
            {/*<FormControlLabel
                                    value="end"
                                    control={<Checkbox color="primary"/>}
                                    label="Remeber me"
                                    labelPlacement="end"
                                    classes={{
                                        root: classes.formControlLabelRoot,
                                        label: classes.formControlLabelLabel,
                                    }}
                                />*/}
            <Box textAlign="center">
              {isLoading ?
                <CircularProgress/>
                :
                <Button type="submit" color="primary" variant="contained" onClick={performLogin}>
                  {intl.formatMessage({id: "auth.login", defaultMessage: "Login"})}
                </Button>
              }
            </Box>
          </CardContent>
          <CardActions>
            <Link
              href="/forgot"
              className={classes.footerLinks}
            >{
              intl.formatMessage({id: "auth.forgot_password", defaultMessage: "Forgot password"})
            }</Link>
          </CardActions>
        </Card>
      )}
    </Grid>
  );
}

export default Login;
