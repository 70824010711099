import React, {useCallback, useEffect, useState} from "react";
import {MenuItem, TextField} from "@material-ui/core";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import {useIntl} from "react-intl";

export default function LanguageSelector({value, onChange, className}) {
  const intl = useIntl();
  const [apiLanguages, setApiLanguages] = useState([]);

  const client = new graphQLApi(useAuthDispatch());
  const stableClient = useCallback(client, [client]);
  const settings = {};
  const languages = [];
  const DEFAULT_LANGUAGE_ID = "0";

  useEffect(() => {
    stableClient.query('{settings{data{ id key data }} languages(sorting:"name"){data{id name locale country_code}}}').then(result => {
      if (result) {
        if (result.hasOwnProperty('settings')) {
          result.settings.data.forEach(st => {
            settings[st.key] = st.data;
          });
          languages.unshift({
            id: DEFAULT_LANGUAGE_ID,
            name: settings.language,
            locale: settings.locale,
            country_code: settings.country_code
          });
        }
        if (result.hasOwnProperty('languages')) {
          result.languages.data.forEach(l => languages.push(l));
          setApiLanguages(languages);
        }
      }
    });
  }, []);

  return (
    <TextField
      select
      className={className}
      fullWidth
      label={intl.formatMessage({
        id: 'printouts.edit.language',
        defaultMessage: 'Language'
      })}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {apiLanguages.map((language, index) => (
        <MenuItem
          key={index}
          value={language.id}
        >
          {language.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
