import AutocompleteApi from "components/AutocompleteApi/AutocompleteApi";
import React, {useCallback, useEffect, useState} from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField
} from "@material-ui/core";
import {useIntl} from "react-intl";
import {ChevronRight, Delete, DragHandle, ListAlt} from "@material-ui/icons";
import {sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc";
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";
import {arrayMoveMutable as arrayMove} from "array-move";
import RoleSelector from './RoleSelector';
import {useNavigate, useParams} from "react-router-dom";

const DraggableHandle = sortableHandle(() => <DragHandle cursor="row-resize"/>);

const SortableItem = sortableElement(({field, handleRemove}) => {
  return <ListItem>
    <ListItemIcon><DraggableHandle/></ListItemIcon>
    <ListItemText>{field.name}</ListItemText>
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="remove" onClick={_e => handleRemove(field)}>
        <Delete/>
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>;
});
const SortableContainer = sortableContainer(({children}) => {
  return <List dense>{children}</List>;
});


export default function FieldGroupEdit() {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();

  const fg = {
    'name': '',
    'entity_type': '',
    'sorting': 0,
    'roles': [],
    'fields': []
  }

  let id = Number(params.id);
  const [roles, setRoles] = useState([]);
  const [fieldsList, setFieldsList] = useState([]);
  const [fieldGroup, setFieldGroup] = useState(fg);
  // const [fieldsChecked, setFieldsChecked] = useState([]);

  const initialValidation = {
    name: [],
    sorting: [],
    entity_type_id: [],
  };
  var [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };

  // const [isLoading, setIsLoading] = useState(false);

  const client = new graphQLApi(
    useAuthDispatch(),
    null,
    {handleErrors: setValidationFromErrors}
  );
  const stableClient = useCallback(client, []);

  useEffect(() => {
    let q = 'roles{data{id title}}';
    if (id) {
      q += ' fieldGroups(filter:{id:' + id + '}){data{ id entity_type{id title} name sorting fields{id name} roles{id} }}';
    }
    stableClient.query('{'+q+'}')
      .then(r => {
        let o = {};
        if (r && r.hasOwnProperty('roles')) {
          setRoles(r.roles.data);
          if (!id) {
            setFieldGroup({...fieldGroup, roles: r.roles?.data})
          }
        }
        if (r && r.hasOwnProperty('fieldGroups')) {
          let fg = r.fieldGroups.data[0];
          fg.entity_type_id = fg.entity_type;
          setFieldGroup(fg);
          // setFieldsChecked(fg.fields.map(f => f.id));
          fg.roles.forEach(r => {
            o[r.id] = true;
          })
        }
      });
  }, [stableClient, id]);
  useEffect(() => {
    if (fieldGroup.entity_type && fieldGroup.entity_type.id > 0) {
      stableClient.query('{fields(filter:{entity_type_id:"' + fieldGroup.entity_type.id + '"}){ data{id type name key uses_languages}}}')
        .then(r => {
          if (r && r.hasOwnProperty('fields')) {
            setFieldsList(r.fields.data);
          }
        });
    }
  }, [stableClient, fieldGroup.entity_type]);

  const save = () => {
    const vars = {
      name: "String",
      entity_type_id: "ID",
      sorting: "Int",
      fields: "[ID]",
      roles: "[ID]",
    };

    fieldGroup.entity_type_id = fieldGroup.entity_type.id
    if (id) {
      fieldGroup.id = id;
      vars.id = "ID!";
    }
    client.mutation('fieldGroup', vars, fieldGroup, 'id').then(r => {
      if (r) {
        navigate(-1);
      }
    });
  }

  const addField = (field) => {
    console.log('addField', field);
    setFieldGroup({...fieldGroup, fields: [...fieldGroup.fields, {id: field.id, name: field.name}]});
  };
  const removeField = (field) => {
    console.log('removeField', field);
    const newFields = fieldGroup.fields.filter(f => f.id !== field.id);
    setFieldGroup({...fieldGroup, fields: newFields})
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    let opts = [...fieldGroup.fields];
    arrayMove(opts, oldIndex, newIndex);
    setFieldGroup({...fieldGroup, fields: opts})
  };
  let filtered = fieldsList.filter(f => !fieldGroup.fields?.find(i => i.id === f.id));


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<ListAlt/>}
            title={intl.formatMessage({id: "field_groups.edit.heading"})}/>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id={"name"}
                      label={intl.formatMessage({id: "field_groups.edit.name", defaultMessage: "Name"})}
                      value={fieldGroup.name}
                      onChange={event => setFieldGroup({...fieldGroup, name: event.target.value})}
                      error={validation.name.length > 0}
                      helperText={validation.name.join(" ")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AutocompleteApi
                      id={"entity_type"}
                      query={"entityTypes"}
                      titleField={"title"}
                      label={intl.formatMessage({id: "field_groups.edit.entity_type", defaultMessage: "Data type"})}
                      value={fieldGroup.entity_type}
                      onChange={(event, value) => setFieldGroup({...fieldGroup, entity_type: value})}
                      error={validation.entity_type_id.length > 0}
                      helperText={validation.entity_type_id.join(" ")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id={"sorting"}
                      label={intl.formatMessage({id: "field_groups.edit.sorting", defaultMessage: "Sorting"})}
                      value={fieldGroup.sorting}
                      onChange={event => setFieldGroup({...fieldGroup, sorting: event.target.value})}
                      error={validation.sorting.length > 0}
                      helperText={validation.sorting.join(" ")}
                      fullWidth
                      type="number"
                      inputProps={{
                        style: {textAlign: "right"}
                      }}
                    />
                  </Grid>
                  <RoleSelector roles={roles} data={fieldGroup} setData={setFieldGroup}/>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormLabel>{intl.formatMessage({
                      id: "field_groups.edit.available_fields",
                      defaultMessage: "Available fields"
                    })}</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      <List>
                        {filtered.length === 0 ?
                          (<ListItem key={"no-fields-left"}>
                            <ListItemText style={{fontStyle: "italic"}}
                                          secondary={intl.formatMessage({
                                            id: "field_groups.edit.all_fields_assigned",
                                            defaultMessage: "All fields have been assigned"
                                          })}/>
                          </ListItem>)
                          : filtered.map((field) => {
                            const labelId = `checkbox-list-label-${field.id}`;

                            return (
                              <ListItem key={"field_" + field.id}
                                        role={undefined}
                                        dense
                                        button
                                        onClick={_e => addField(field)}>
                                <ListItemText id={labelId} primary={field.name}/>
                                <ListItemSecondaryAction>
                                  <IconButton edge="end" aria-label="remove" onClick={_e => addField(field)}>
                                    <ChevronRight/>
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })
                        }
                      </List>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormLabel>{intl.formatMessage({
                      id: "field_groups.edit.assigned_fields",
                      defaultMessage: "Assigned fields"
                    })}</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                        {fieldGroup?.fields?.map((value, index) => (
                          <SortableItem key={'field-selected-' + index}
                                        index={index}
                                        field={value}
                                        handleRemove={removeField}/>
                        ))}
                      </SortableContainer>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{justifyContent: "flex-end"}}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => navigate(-1)}>
              {intl.formatMessage({id: "common.button.back"})}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={save}>
              {intl.formatMessage({id: "common.button.save"})}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
