import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  lighten,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Clear} from '@material-ui/icons';
import {useIntl} from 'react-intl';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: "0.25rem 1rem",
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.text.secondary,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
    fontSize: 22
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const intl = useIntl();
  const {
    fullTextSearch,
    handleFullTextSearch,
    onSearchChange,
    actions,
    title,
    urlState,
    search: searchParam,
    fullTextSearchable,
  } = props;

  const [search, setSearch] = useState('');
  const [preLoaded, setPreloaded] = useState(false);

  let timeout = useRef();
  const debounce = (func, wait, immediate) => {
    return function () {
      let context = this, args = arguments;
      let later = function () {
        timeout.current = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout.current;
      // console.log('Debouncing', callNow, timeout.current, immediate, args);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  const debouncedSetSearch = debounce((s) => {
    // console.log('Setting search', s);
    onSearchChange(s);
  }, 1250);

  const handleSearchChange = (event) => {
    //event.stopPropagation();
    setSearch(event.target.value);
    debouncedSetSearch(event.target.value);
  };

  useEffect(() => {
    if (urlState && !preLoaded && searchParam !== "") {
      setSearch(searchParam);
      setPreloaded(true);
    }
  }, [urlState, preLoaded, searchParam]);

  return (
    <Toolbar
      className={classes.root}
    ><Typography className={classes.title} color="primary" variant="body2" component="div">
      <Grid container>
        <Grid item>{title}</Grid>
      </Grid>
    </Typography>
      {
        actions.filter(af => af.isFilterAction).map((action, index) =>
          <Grid item key={"toolbar-free-action-" + index} style={{paddingTop: 8}}><Tooltip title={action.tooltip}>
            <IconButton
              size="small"
              onClick={action.onClick}
              color={action.color}>
              <action.icon/>
            </IconButton>
          </Tooltip></Grid>)}
      <Grid container spacing={1} justifyContent={'flex-end'} alignItems="center"
            style={{margin: -11}}>
        {!!onSearchChange && fullTextSearchable && <Grid item key={'wildcard-checkbox'}>
          <FormControl>
            <FormControlLabel
              control={<Checkbox
                name={'wildcard'}
                color={'primary'}
                checked={fullTextSearch}
                onChange={() => handleFullTextSearch(!fullTextSearch)}
              />}
              label={intl.formatMessage({
                id: 'entities.list.export.dialog.select.full_text',
                defaultMessage: 'Fuld tekst søgning',
              })}/>
            {fullTextSearch && <FormHelperText>{intl.formatMessage({
              id: "enhanced_table.toolbar.fullTextSearch",
              defaultMessage: "This search may take a very long time!"
            })}</FormHelperText>}
          </FormControl>
        </Grid>}
        {!!onSearchChange &&
          (<Grid item style={{flexGrow: 1}}><TextField
            fullWidth
            size="small"
            label={intl.formatMessage({id: "enhanced_table.toolbar.search", defaultMessage: "Search"})}
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: search !== '' ? (
                <IconButton onClick={() => {
                  timeout.current = null;
                  onSearchChange('');
                  setSearch('');
                }}>
                  <Clear color="disabled" fontSize="small"/>
                </IconButton>
              ) : ''
            }}
            inputProps={{
              maxLength: 32,
            }}
          /></Grid>)}
        {actions.filter(af => af.isFreeAction).map((action, index) =>
          <Grid item key={"toolbar-free-action-" + index}>{action.label
            ? <Button
              size="small"
              variant={action.variant || "outlined"}
              disabled={typeof action.disabled === "function" ? action.disabled() : action.disabled}
              onClick={action.onClick}
              color={action.color || "primary"}>{action.label}</Button>
            : <Tooltip title={action.tooltip}>
              <IconButton
                size="small"
                disabled={typeof action.disabled === "function" ? action.disabled() : action.disabled}
                onClick={action.onClick}
                color="primary">
                <action.icon/>
              </IconButton>
            </Tooltip>
          }</Grid>)}
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSearchChange: PropTypes.func,
  onSelectedActions: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
      tooltip: PropTypes.string.isRequired,
      label: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      toolbar: PropTypes.bool,
      onSelected: PropTypes.bool,
      isFreeAction: PropTypes.bool,
      isFilterAction: PropTypes.bool,
      rowClick: PropTypes.bool,
      color: PropTypes.string,
    })
  ),
  showTagFilterDialog: PropTypes.func,
  showFilterTags: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

export default EnhancedTableToolbar;
