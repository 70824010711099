import EnhancedTable from "../../DataTable/EnhancedTable";
import React from "react";
import {SkipNext} from "@material-ui/icons";
import {useIntl} from 'react-intl';

export default function EntitySelect({collection, handleOnSelected}) {
  const intl = useIntl();

  function handleSelectedClick(rows) {
    handleOnSelected(rows);
  }

  return (
    <EnhancedTable
      columns={collection.columns}
      query='entities'
      filter={"ids: [" + collection.filterIds.join(",") + "]"}
      fields={'id entity_type{id title} parent{variant_fields{id}} variant_fields{id name} values{id field{id} language{id} field_option{title} asset{file_thumb} text string integer decimal bool date}'}
      children
      actions={[
        {
          onSelected: true,
          tooltip: intl.formatMessage({
            id: "common.button.select",
            defaultMessage: "Select"
          }),
          icon: SkipNext,
          onClick: handleSelectedClick
        }
      ]}
    />
  );
};
