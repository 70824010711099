import {useIntl} from "react-intl";
import {Paper} from "@material-ui/core";
import EnhancedTable from "../../../components/DataTable/EnhancedTable";
import {Extension} from "@material-ui/icons";
import React from "react";

export default function WorkflowList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id: 'workflow.list.column.description', defaultMessage: 'Description'}), field: 'title'},
        {title: intl.formatMessage({id: 'workflow.list.column.events_count', defaultMessage: 'Events'}), field: 'events_count'},
        {title: intl.formatMessage({id: 'workflow.list.column.actions_count', defaultMessage: 'Actions'}), field: 'actions_count'},
        {title: intl.formatMessage({id: 'workflow.list.column.created_at', defaultMessage: 'Last started at'}), field: 'last_started_at'},
        {title: intl.formatMessage({id: 'workflow.list.column.created_at', defaultMessage: 'Created at'}), field: 'created_at'}
    ];

    return (<Paper>
            <EnhancedTable
                {...props}
                title={intl.formatMessage({id: 'workflow.list.table.heading', defaultMessage: "Workflows"})}
                columns={columns}
                query="workflows"
                mutations="workflow"
                fields='id title created_at last_started_at'
                counts={['actions', 'events']}
                icon={<Extension/>}
                urlState
            />
        </Paper>
    );
}
