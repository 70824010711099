import {WebAsset} from "@material-ui/icons";

import AssetList from "views/Assets/AssetList";
import {rolePriorities} from "config";

export default function routes(intl) {
  return [
    {
      path: "",
      name: intl.formatMessage({
        id: "menu.assets.list",
        defaultMessage: "Assets",
      }),
      icon: WebAsset,
      iconColor: "Info",
      component: AssetList,
      layout: "/admin",
      allow_priority: rolePriorities.can_assets,
    },
  ];
}
