import {
 Checkbox,
 FormControlLabel, FormGroup, FormLabel, Grid,
} from '@material-ui/core';
import {useIntl} from "react-intl";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  tableCell: {
    borderTop: 0,
    borderBottom: 0,
  }
});

export default function RoleSelector(props) {
  const {roles, data, setData} = props;
  const intl = useIntl()
  const classes = useStyles();

  const isRoleSelected = (id) => data.roles?.find((v) => v.id === id) !== undefined;
  const handleClick = (event, id) => {
    if (isRoleSelected(id)) {
      const newRoles = data.roles.filter(r => r.id !== id);
      setData({...data, roles: newRoles})
    } else {
      setData({...data, roles: [...data.roles, {id:id}]})
    }
  };
  return <Grid item xs={12}>
    <FormLabel>{intl.formatMessage({id:"views.edit.label.roles", defaultMessage:"Roles that have access"})}</FormLabel>
    <Table size="small" >
      <TableBody>
        {roles?.map((row, index) => {
          const isItemSelected = isRoleSelected(row.id);

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={index+"-"+row.id}
            >
              <TableCell className={classes.tableCell} padding="checkbox">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox
                      name={"role-" + row.id}
                      color={"primary"}
                      checked={isItemSelected}
                      onChange={(event) => handleClick(event, row.id)}
                    />}
                    label={row.title}
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </Grid>;

}
