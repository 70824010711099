import React, {useState} from "react";
import {authUser} from "../../contexts/Auth";
import {Button, Dialog, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import {Close, NewReleases} from "@material-ui/icons";
import moment from "moment";
import {useIntl} from "react-intl";
import themeColors from "../../assets/theme/colors";
import AppOrder from "../../views/AppOrder";

export default function NavbarDemo() {
  const intl = useIntl();
  const isDanDomain = /^dandomain_.*$/.test(authUser().site?.schema);
  const demoExpiresAt = authUser().site?.demo_expire_at ? moment(authUser().site?.demo_expire_at).format(intl.formatMessage({id: "common.date.format"})) : null;
  const demoOrderedAt = authUser().site?.order_sent_at ? moment(authUser().site?.order_sent_at).format(intl.formatMessage({id: "common.date.format"})) : null;
  const [showDialog, setShowDialog] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  if ((!demoExpiresAt || demoOrderedAt) && !(isDanDomain && showThankYou)) {
    return null;
  }
  return <>
    {!(isDanDomain && showThankYou) && <Button
      size="small"
      variant="contained"
      color={demoOrderedAt ? "default" : "secondary"}
      startIcon={!demoOrderedAt && <NewReleases/>}
      onClick={_e => setShowDialog(true)}
    >{demoOrderedAt
      ? intl.formatMessage({
        id: "demo.navbar.button-ordered",
        defaultMessage: "Demo ordered {ordered_at}"
      }, {ordered_at: demoOrderedAt})
      : intl.formatMessage({
        id: "demo.navbar.button",
        defaultMessage: "Demo expires {expires_at}"
      }, {expires_at: demoExpiresAt})
    }</Button>}
    <Dialog open={showDialog} maxWidth={"lg"}>
      <DialogTitle
        disableTypography
        style={{textAlign: "center", fontSize: "1.5rem", fontWeight: "bold", color: themeColors.primary.main}}
      ><IconButton onClick={_e => setShowDialog(false)} style={{float: "right"}}><Close/></IconButton>{
        (!isDanDomain && authUser().site?.order_sent_at)
          ? intl.formatMessage({
            id: "demo.dialog.processing.title",
            defaultMessage: "We are processing your order of OpenDIMS!"
          })
          : showThankYou
            ? intl.formatMessage({
              id: "demo.dialog.thankyou.title",
              defaultMessage: "Thank you for your order of OpenDIMS!"
            })
            : intl.formatMessage({
              id: "demo.dialog.title",
              defaultMessage: "Order your OpenDIMS today and get full control"
            })
      }</DialogTitle>
      <DialogContent>
        <AppOrder
          isDanDomain={isDanDomain}
          showThankYou={showThankYou}
          setShowThankYou={setShowThankYou}
          setShowDialog={setShowDialog}
        />
      </DialogContent>
    </Dialog>
  </>
}
