import {Button, Card, CardActions, CardContent, CardHeader, Grid} from '@material-ui/core';

import {NewReleases} from '@material-ui/icons';
// import Comments from "components/Comments/Comments";
import moment from 'moment';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import versions from "../versions";

moment.locale(window.language);

export default function Versions() {
  const intl = useIntl();
  const [versionIndex, setVersionIndex] = useState(versions.length - 1);
  return (<Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={10} md={9} lg={7}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<NewReleases/>}
            title={versions[versionIndex][intl.locale].title}
            subheader={<Grid container justifyContent="space-between"><Grid item>{intl.formatMessage({
              id: "versions.version",
              defaultMessage: "Version: {version}"
            }, versions[versionIndex])}</Grid>
              <Grid item>{moment(versions[versionIndex].date).format(intl.formatMessage({id:"common.datetime.format"}))}</Grid></Grid>}
          />
          <CardContent><Grid container spacing={1}>
            <Grid item xs={12}>{versions[versionIndex][intl.locale].description}</Grid>
            {versions[versionIndex].images.map((img,idx) =>
              <Grid item xs={12} key={"version-" + versionIndex + "-image-" + idx} style={{marginTop: 24}}>
                <div style={{
                  textTransform: "capitalize",
                  fontWeight: "bold"
                }}>{String(img).split('/').pop().split('.').shift().split('-').join(' ')}</div>
                <img alt={"image-" + idx} src={img} style={{margin: 24, maxWidth: "100%"}}/>
              </Grid>
            )}
          </Grid></CardContent>
          <CardActions>
            <Button variant="outlined"
                    onClick={() => setVersionIndex(versionIndex > 0 ? versionIndex - 1 : 0)}>{
              intl.formatMessage({
                id: "common.button.previous",
                defaultMessage: "Previous"
              })
            }</Button>
            <div style={{paddingTop: 8}}>{versionIndex + 1} / {versions.length}</div>
            <Button variant="outlined"
                    onClick={() => setVersionIndex(versionIndex < versions.length - 1 ? versionIndex + 1 : versions.length - 1)}>{
              intl.formatMessage({
                id: "common.button.next",
                defaultMessage: "Next"
              })
            }</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
