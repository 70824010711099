import PropTypes from 'prop-types';
import {Button, Dialog, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from '@material-ui/core';
import {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    }
}));

export default function AddFieldDialog({open, title, label, fields, onClickClose}) {
    const [select, setSelect] = useState(null);
    const classes = useStyles();

    function handleClose() {
        onClickClose();
      setSelect(null);
    }

    function handleSelect() {
        onClickClose(select);
        setSelect(null);
    }

    return (
        <Dialog PaperProps={{style: {padding: "20px"}}} maxWidth="sm" open={open} onClose={handleClose}
                aria-labelledby="dialog-title">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DialogTitle id="dialog-title">
                        <Typography variant="h2" component="span">{title}</Typography>
                    </DialogTitle>
                </Grid>
                <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="dialog-form-label">{label}</InputLabel>
                            <Select
                                labelId="dialog-form-label"
                                onChange={e => setSelect(e.target.value)}
                              value={select}
                            >
                                {fields.map((field, index) => {
                                    return <MenuItem key={index} value={field}>{field.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                </Grid>
                <Grid item container xs={12} style={{display:"flex"}} justifyContent="space-between">
                    <Button variant="outlined" onClick={handleClose}><FormattedMessage id="common.button.close"/></Button>
                    <Button color="primary" onClick={handleSelect}><FormattedMessage id="common.button.add"/></Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}

AddFieldDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
}
