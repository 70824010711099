import React from 'react'
import {TableCell, TableHead, TableRow,} from '@material-ui/core'
import {EditTableColumnType} from "./EditTableTypes";

EditTableHead.propTypes = {
    columns: EditTableColumnType()
}

export default function EditTableHead({columns}) {
    return (
        <TableHead>
            <TableRow>
                {columns.map((column, index) => {
                    return (
                        <TableCell
                            key={index}
                            width="auto"
                            align="left"
                            style={{ textTransform: "capitalize" }}
                        >
                          <strong>{column}</strong>
                        </TableCell>
                    )
                })}
              <TableCell>&nbsp;</TableCell>
            </TableRow>
        </TableHead>
    )
}
