import React, {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {authUser, useAuthDispatch} from "../../../../contexts/Auth";
import {getEntityTitle, RenderEditableField, RenderFieldOfType} from "./Field";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {Button, Chip, Grid, LinearProgress, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import themeColors from "../../../../assets/theme/colors";
import theme from "../../../../assets/theme/theme";
import {graphQLApi} from "../../../../services/GraphQLApi";
import {FieldTypes} from "../../../../variables/fields";

export default function VariantField({
                                       node,
                                       fields,
                                       entityTypes,
                                       // ...rest
                                     }) {
  const {language} = useContext(LanguageContext);
  const dispatcher = useAuthDispatch();
  const intl = useIntl();
  const {entity, setEntity, setEditing} = useContext(EntityContext);
  const {background, color} = node.props;

  const entityType = entityTypes.find(et => et.id === entity.entity_type_id);
  const entityTypeFieldIds = [];
  if (entityType?.number_field) entityTypeFieldIds.push(entityType?.number_field?.id);
  if (entityType?.title_field) entityTypeFieldIds.push(entityType?.title_field?.id);

  const [loadingParents, setloadingParents] = useState(false);
  const [parentEntities, setParentEntities] = useState([]);

  const loadParents = () => {
    if (parentEntities.length > 0) return;
    setloadingParents(true);
    const client = new graphQLApi(dispatcher);
    client.query('{' +
      'entities(filter:{entity_type_id:' + entityType.id + ',is_variant:false' + (entity.id ? ',id_ne:' + entity.id : '') + '}){' +
      '  data{id variant_fields{id} values(locale:"' + (language.locale) + '", field_id_in:[' + entityTypeFieldIds.join(',') + ']){id field_id language_id field_option{title} asset{file_thumb} text string integer decimal bool date}}' +
      '}}').then(r => {
      if (r?.entities.hasOwnProperty('data')) {
        setParentEntities(r.entities.data);
      }
      setloadingParents(false);
    })
  };

  const MODE_SIMPLE = 1;
  const MODE_PARENT = 2;
  const MODE_VARIANT = 3;
  if (entity.parent) {
    entity.mode = MODE_VARIANT;
  } else if (entity.variant_fields?.length > 0) {
    entity.mode = MODE_PARENT;
  } else {
    entity.mode = MODE_SIMPLE;
  }
  const [mode, setMode] = useState(entity.mode);

  const variantFieldOptions = [];
  for (let k in fields) {
    if (FieldTypes.OPTION === fields[k].type && fields[k].entity_type_id === entity.entity_type_id)
      variantFieldOptions.push(fields[k]);
  }

  const rowResetToSimple = () => <Grid container alignItems="center">
    <Grid item style={{flexGrow: 1}}>
      <Typography variant="inherit">{
        intl.formatMessage({
          id: "entity.edit.variant.reset",
          defaultMessage: "Click here if you want to remove variant information and make this entity a simple entity agian."
        })
      }</Typography>
    </Grid>
    <Grid item>
      <Button
        size="small"
        variant="contained"
        onClick={_ => {
          setEntity({...entity, variant_fields: [], parent: null});
          setMode(MODE_SIMPLE);
        }}
      >{intl.formatMessage({
        id: "entity.edit.variant.button.reset",
        defaultMessage: "Reset"
      })}</Button>
    </Grid>
  </Grid>;

  const labelVariantFields = intl.formatMessage({
    id: "entity.edit.variant.parent.fields",
    defaultMessage: "Variant fields"
  });
  const labelParent = intl.formatMessage({
    id: "entity.edit.variant.parent.field",
    defaultMessage: "Parent of this variant"
  });

  const legendStyle = {padding: "2px 8px"};
  if (background !== theme.palette.background.paper) {
    legendStyle.borderWidth = 1;
    legendStyle.borderStyle = "solid";
    legendStyle.borderColor = color;
    legendStyle.backgroundColor = background;
    legendStyle.borderRadius = 4;
  }

  const getEntityDescription = (entity) => {
    if (typeof entity !== "object" || !entity?.hasOwnProperty('values')) {
      entity = parentEntities?.find(o => (o.id === entity || o.id === entity?.id));
    }
    if (!entity) return <em>{intl.formatMessage({
      id: "entity.edit.variant.no-parent",
      defaultMessage: "No parent entity selected"
    })}</em>;
    return getEntityTitle(entity, entityTypeFieldIds, fields);
  }

  return mode === MODE_SIMPLE
    ? <Grid container alignItems="center" spacing={2}>
      <Grid item style={{flexGrow: 1}}>
        <Typography variant="inherit">{
          intl.formatMessage({
            id: "entity.edit.variant.simple.buttons",
            defaultMessage: "This entity is neither a parent or variant, click a button here to configure it as one."
          })
        }</Typography>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="contained"
          onClick={_ => setMode(MODE_PARENT)}
        >{intl.formatMessage({
          id: "entity.edit.variant.button.parent",
          defaultMessage: "Parent"
        })}</Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="contained"
          onClick={_ => setMode(MODE_VARIANT)}
        >{intl.formatMessage({
          id: "entity.edit.variant.button.variant",
          defaultMessage: "Variant"
        })}</Button>
      </Grid>
    </Grid>
    : (mode === MODE_PARENT
      ? <Grid container alignItems="center" spacing={2} style={{color: themeColors.text.primary}}>
        <Grid item xs={12}>{intl.formatMessage({
          id: "entity.edit.variant.parent",
          defaultMessage: "This is a parent entity that has variants, that differ on the fields selected below."
        })}</Grid>
        <Grid item xs={12}>
          <RenderEditableField
            field={{
              id: 'variant',
              name: labelVariantFields,
            }}
            value={entity.variant_fields?.map(vf => <Chip size="small"
                                                          label={vf.name || variantFieldOptions?.find(f => f.id === vf.id)?.name}/>)}
          ><Autocomplete
            fullWidth
            multiple
            size="small"
            renderInput={params =>
              <TextField {...params}
                         InputLabelProps={{style: {backgroundColor: background}}}
                         label={labelVariantFields}/>
            }
            value={entity.variant_fields.map(f => fields[f.id])}
            options={variantFieldOptions}
            getOptionLabel={o => o.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_, value) => {
              console.log(value);
              setEntity({...entity, variant_fields: (value?.length ? value : [])});
            }}
            onKeyUp={e => ['Enter', 'Escape'].includes(e.code) && setEditing(null)}
          /></RenderEditableField>
        </Grid>
        {null && authUser().hasRole('sysdev') && <Grid item xs={12}>{rowResetToSimple()}</Grid>}
      </Grid>
      : <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <RenderEditableField
            field={{id: 'parent', name: labelParent}}
            onEditing={loadParents}
            value={getEntityDescription(entity.parent)}
          >{(loadingParents)
            ? <LinearProgress variant="indeterminate" style={{width: "100%", margin: 0}}/>
            : <Autocomplete
              key={'parent-selector'}
              fullWidth
              size="small"
              options={parentEntities}
              renderInput={params =>
                <TextField {...params}
                           InputLabelProps={{style: {backgroundColor: background}}}
                           label={labelParent}/>}
              value={entity.parent}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={getEntityDescription}
              onChange={(_, value) => {
                setEntity({...entity, parent_id: value?.id || null, parent: value});
              }}
              onKeyUp={e => ['Enter', 'Escape'].includes(e.code) && setEditing(null)}
            />
          }</RenderEditableField>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {/*<Grid item xs={12} style={{borderBottom:"1px solid " + theme.palette.text.hint}}><Typography variant="inherit">{intl.formatMessage({id:"entity.edit.variant.values", defaultMessage:"Variant values"})}</Typography></Grid>*/}
          {entity.parent?.variant_fields.map(f => <Grid key={'variant-field-' + f.id} item xs={12}><RenderFieldOfType
            field={fields[f.id]}/></Grid>)}
        </Grid>
        {null && authUser().hasRole('sysdev') && <Grid item xs={12}>{rowResetToSimple()}</Grid>}
      </Grid>);
}
