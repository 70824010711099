import {createContext, useEffect, useState} from "react";
import {graphQLApi} from "../services/GraphQLApi";
import {authUser, useAuthDispatch} from "./Auth";

export const SettingsContext = createContext({
    settings: {},
  setSetting: () => {
  },
  setUserSetting: () => {
  },
  setSettings: () => {
  },
});

export const SettingsProvider = ({children}) => {
    const [settings, setSettings] = useState({});
  const client = new graphQLApi(useAuthDispatch());

    useEffect(() => {
        if (!authUser().id) return;
        client.query('{' +
            ' settings{data{key locale type data}}' +
            ' userSettings(filter:{user_id:' + authUser().id + '}){data{id key value}}' +
            '}').then(r => {
            let newSettings = {user: {}};
            if (r?.hasOwnProperty('settings')) {
                r.settings.data.forEach(s => newSettings[s.key] = s.type === 'json' ? JSON.parse(s.data) : s.data);
            }
            if (r?.hasOwnProperty('userSettings')) {
                r.userSettings.data.forEach(s => newSettings.user[s.key] = JSON.parse(s.value));
            }
            setSettings(newSettings);
        });
    }, []);

    const setSetting = (key, value) => {
        setSettings({...settings, [key]: value});
    }

    const setUserSetting = (key, value) => {
        setSettings({...settings, user: {...settings.user, [key]: value}});
    }

    return <SettingsContext.Provider value={{settings, setSetting, setUserSetting, setSettings}}>{children}</SettingsContext.Provider>;
}
