import PropTypes from 'prop-types';
import {useState} from 'react';
import {Popover, Typography, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Warning} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none'
  },
  iconx: {
    marginTop: 'auto',
    marginBottom: 'auto'
  }
}))

export default function EditTableWarning({message}) {
  const classes = useStyles();

  const [hovering, setHovering] = useState(null);

  function handleOpen(event) {
    setHovering(event.currentTarget);
  }

  function handleClose() {
    setHovering(null);
  }

  const open = Boolean(hovering);
  return (
    <>
    <IconButton>
      <Warning color="error" className={classes.iconx} aria-owns={open ? 'warning-popover' : undefined} aria-haspopup='true' onMouseEnter={handleOpen} onMouseLeave={handleClose} />
    </IconButton>
    <Popover
        id='warning-popover'
        open={open}
        className={classes.popover}
        anchorEl={hovering}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        disableRestoreFocus
    >
        <Typography variant='h4'>
        {message}
        </Typography>
    </Popover>
    </>
  );
}

EditTableWarning.propTypes = {
  message: PropTypes.string.isRequired
}
