
export function DandomainClassicTest(intl) {
  return {
    'api_key': [intl.formatMessage({id: "connector.driver.dandomain_classic.test.api_key", defaultMessage: "Connection test failed, check api key setting."})],
    'Site': [intl.formatMessage({id: "connector.driver.dandomain_classic.test.site", defaultMessage: "Connection test failed, check site setting."})],
  }
}

export default function DandomainClassic(intl) {
  let fields = [
    {
      field: "api_key",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.dandomain_classic.api_key", defaultMessage: "Api key"}),
      inputProps: {autoComplete: "off"},
    },
    {
      field: "Site",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.dandomain_classic.site", defaultMessage: "Site"}),
      inputProps: {autoComplete: "off"}
    },
  ];
  return fields;
}
