import React from "react";
import {Chip, Paper} from "@material-ui/core";
import {ListAlt} from "@material-ui/icons";
import {useIntl} from "react-intl";
import EnhancedTable from "components/DataTable/EnhancedTable";

export default function FieldGroupList(props) {
  const intl = useIntl();
  const columns = [
    {title: intl.formatMessage({id: 'field_groups.list.column.name', defaultMessage: 'Name'}), field: 'name'},
    {
      title: intl.formatMessage({id: 'field_groups.list.column.entity_type', defaultMessage: 'Data type'}),
      field: 'entity_type.title',
    },
    {
      title: intl.formatMessage({id: 'field_groups.list.column.sorting', defaultMessage: 'Sorting'}),
      field: 'sorting',
      type: 'numeric',
      defaultSort: 'asc'
    },
    {
      title: intl.formatMessage({id: 'field_groups.list.column.fields', defaultMessage: 'Fields'}),
      field: 'fields_count',
      type: 'numeric'
    },
    {title: intl.formatMessage({id:'field_groups.list.column.roles', defaultMessage:'Roles'}), render: row => row.roles.map(r => <Chip label={r.title} size="small" style={{marginRight:3}}/>)},
  ];

  return (
    <Paper>
      <EnhancedTable
        title={intl.formatMessage({id: 'field_groups.list.table.heading', defaultMessage: "Field Groups list"})}
        columns={columns}
        query="fieldGroups"
        mutations="fieldGroup"
        fields='id name sorting fields_count entity_type{title} roles{title}'
        icon={<ListAlt/>}
        urlState
        {...props}
      />
    </Paper>
  );
}