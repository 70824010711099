import React from 'react';
import {Paper} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import EnhancedTable from 'components/DataTable/EnhancedTable';

export default function SettingsList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id: 'settings.list.column.key', defaultMessage: 'Key'}), field: 'key'},
        {title: intl.formatMessage({id: 'settings.list.column.type', defaultMessage: 'Type'}), field: 'type'},
        {title: intl.formatMessage({id: 'settings.list.column.locale', defaultMessage: 'Locale'}), field: 'locale'},
        {title: intl.formatMessage({id: 'settings.list.column.data', defaultMessage: 'Data'}), field: 'data'},
        {
            title: intl.formatMessage({id: 'settings.list.column.description', defaultMessage: 'Description'}),
            field: 'description',
            render: row => {
                if (row.description) {
                    let doc = new DOMParser().parseFromString(row.description, 'text/html');
                    return doc.body.textContent.slice(0, 50) + '...' || "";
                }
                return "";
            }
        }
    ];

    return (
        <Paper>
            <EnhancedTable
                title={intl.formatMessage({id: 'settings.list.table.heading', defaultMessage: "Settings list"})}
                columns={columns}
                query="settings"
                mutations="setting"
                fields='id key type locale data description'
                icon={<Settings/>}
                {...props}
            />
        </Paper>
    );
}
