import React, {useCallback, useEffect, useState} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import {Image} from '@material-ui/icons';
import {FieldTypes, getEntityValueFromFieldType} from 'variables/fields';
import {useParams} from "react-router-dom";

const useStyles = makeStyles(_theme => ({
  container: {
    maxWidth: "unset",
    margin: "unset"
  },
  content: {
    padding: 20,
    position: "relative",
    maxHeight: "85vh",
    overflowY: "auto"
  },
  rowContainer: {
    marginTop: 0
  },
  title: {
    marginBottom: 50
  },
  row: {
    padding: 5,
    marginBottom: 40
  },
  elementItem: {
    // padding: 20,
    overflow: "hidden"
  },
  asset: {
    width: 200,
    fontSize: 200
  }
}));

export default function ViewPrintout() {
  const params = useParams();
  const classes = useStyles();
  const [printout, setPrintout] = useState({
    id: null,
    title: '',
    printout_elements: [],
    entity_type_id: null,
    language: null,
    settings: {}
  });

  const client = new graphQLApi(useAuthDispatch());
  const stableClient = useCallback(client, [client]);

  const [entity, setEntity] = useState({});

  useEffect(() => {
    const id = Number(params.id);
    const urlParams = new URLSearchParams(window.location.search);
    const entityId = urlParams.get('id');
    if (id && entityId) {
      // Query printout and set elements
      stableClient.query('{' +
        'printouts(filter:{id:' + id + '}) {data{ ' +
          'id title language entity_type_id printout_elements{id printout{id} type settings sorting ' +
          'children{ id printout{id} parent{id} type field{id entity_type{id} type uses_languages} string settings sorting} }' +
        '}}\n' +
        'entities(filter:{id:' + entityId + '}) {data{ ' +
          'id values{language{id} field{id type name} field_option{id title titles{language{id} translation}} text string integer decimal bool date ' +
            'related_entity{' +
              'id values{language{id} field{id type name} field_option{id title} text string integer decimal bool date}' +
            '}' +
          '}' +
        '}}\n' +
        '}'
      ).then(result => {
        if (result && result.hasOwnProperty('entities')) {
          setEntity(result.entities.data[0]);
          setTimeout(() => window.print(), 500);
        }
        if (result && result.hasOwnProperty('printouts')) {
          const printouts = result.printouts.data;
          if (printouts.length === 1) {
            const newPrintout = {...printouts[0]};
            const printoutElements = newPrintout.printout_elements;
            let transformedElements = [];
            printoutElements.forEach(e => {
              if (e.type !== "container") return;
              e.printout_id = e.printout.id;
              delete e.printout;
              e.settings = JSON.parse(e.settings);
              e.sorting = parseInt(e.sorting);
              e.children = e.children.map(c => {
                c.printout_id = c.printout.id;
                delete c.printout;
                c.parent_id = c.parent ? c.parent.id : null;
                delete c.parent;
                c.settings = JSON.parse(c.settings);
                c.sorting = parseInt(c.sorting);
                c.field_id = c.field ? c.field.id : null;
                return c;
              });
              transformedElements.push(e);
            });
            newPrintout.printout_elements = transformedElements;
            if (newPrintout.settings) newPrintout.setting = JSON.parse(newPrintout.settings);
            setPrintout(newPrintout);
          }
        }
      });
    }
  }, []);

  const sortElements = (a,b) => {
    if (a.sorting > b.sorting) return 1;
    if (a.sorting < b.sorting) return -1;
    return 0;
  }

  return (
    <Grid container classes={{container: classes.rowContainer}}>
      {printout.printout_elements.sort(sortElements).map((row, index) => {
        let column = 1;
        return (
          <Grid
            key={index}
            item
            classes={{item: classes.row}}
            xs={12}
            id={"row-" + index + 1}
          >
            <Grid container spacing={1}>
              {row.children && row.children.sort(sortElements).map((element, index2) => {
                let content;
                const styles = {};
                styles.textAlign = element.settings.alignment;
                if (element.type === "field") {
                  if (element.field.entity_type.id !== printout.entity_type_id) {
                    let values = entity.values
                      .filter(v => v.field.type.search(RegExp('_' + element.field.entity_type.id + '$')) !== -1)
                      .map(v => getEntityValueFromFieldType(v.related_entity.values.find(rv => rv.field.id === element.field_id)));
                    if (element.field.type === FieldTypes.ASSET || element.field.type === FieldTypes.ASSETS) {
                      let imageStyles = {};
                      if (element.settings.width) {
                        imageStyles.width = element.settings.width;
                      }
                      if (element.settings.height) {
                        imageStyles.height = element.settings.height;
                        imageStyles.width = element.settings.width ? element.settings.width : 'auto';
                      }
                      if (element.settings.padding) {
                        styles.gap = element.settings.padding;
                        styles.display = 'inline-flex';
                        styles.flexWrap = 'wrap';
                      }
                      content = values.filter(v => !!v).map((v,k) => <img key={'element_'+element.id+'_asset_'+k} style={imageStyles} className={classes.asset} src={v} alt=''/>);
                    }
                    else {
                      content = <Typography>{values.join(', ')}</Typography>;
                    }
                  }
                  else {
                    let values = entity.values.filter(v => v.field?.id === element.field_id && (!element.field.uses_languages || !['text', 'string', 'html'].includes(element.field.type) || v.language?.id === printout.language));
                    if (values) {
                      content = values.map(value => <Typography>{getEntityValueFromFieldType(value, printout.language)}</Typography>);
                    }
                  }
                }
                else if (element.type === "asset") {
                  let asset = element.string;
                  let imageStyles = {};
                  if (asset && element.settings.width && element.settings.height) {
                    imageStyles = {width: element.settings.width, height: element.settings.height};
                  }
                  content = asset ? <img className={classes.asset} style={imageStyles} src={asset} alt=''/> :
                    <Image className={classes.asset}/>;
                }
                else if (element.type === "string") {
                  let stringStyles = {};
                  if (element.settings.fontSize) stringStyles.fontSize = element.settings.fontSize;
                  if (element.settings.bold) stringStyles.fontWeight = "bold";
                  content = <Typography style={stringStyles} dangerouslySetInnerHTML={{__html: element.string}}/>;
                }

                if (column > row.settings.columns) column = 1;
                let colSize;
                if (column === 1) colSize = row.settings.col1Size;
                else if (column === 2) colSize = row.settings.col2Size;
                else if (column === 3) colSize = row.settings.col3Size;
                column++;
                return (
                  <Grid
                    key={index2}
                    item
                    classes={{item: classes.elementItem}}
                    style={styles}
                    xs={colSize}
                  >{content}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
