import React, {useRef} from "react";
import {Paper} from "@material-ui/core";
import {FileCopy, Share} from "@material-ui/icons";
import {useIntl} from "react-intl";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";

export default function ConnectorList(props) {
  const intl = useIntl();
  const ref = useRef({});

  const columns = [
    {title: intl.formatMessage({id: 'connectors.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {title: intl.formatMessage({id: 'connectors.list.column.driver', defaultMessage: 'Driver'}), field: 'driver'},
    {
      title: intl.formatMessage({id: 'connectors.list.column.is_active', defaultMessage: 'Is Active'}),
      field: 'is_active',
      type: 'boolean',
      initial: false
    }
  ];
  const client = new graphQLApi(useAuthDispatch());

  return (
    <Paper>
      <EnhancedTable
        {...props}
        ref={ref}
        title={intl.formatMessage({id: 'connectors.list.table.heading', defaultMessage: "Connectors list"})}
        columns={columns}
        actions={[
          {
            icon: FileCopy,
            tooltip: intl.formatMessage({id: "connectors.list.action.copy", defaultMessage: "Copy"}),
            onClick: (row) => {
              ref.current.isLoading(true)
              client.mutate('($id:ID!) {connectorCopyCreate(id:$id) {id}}',
                {id:  parseInt(row.id)}).then(r => {
                if (r) {
                  ref.current.update();
                }
              });

            }
          }
        ]}
        query="connectors"
        mutations="connector"
        fields='id driver title is_active'
        icon={<Share/>}
        urlState
      />
    </Paper>
  );
}
