import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {FilterList, List} from '@material-ui/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {graphQLApi} from '../../../services/GraphQLApi';
import {useAuthDispatch} from '../../../contexts/Auth';


export default function EventLogList(props) {
  const intl = useIntl();
  const tableRef = useRef();
  const [showFilterDialog, setShowFilterDialogue] = useState(false);
  const [types, setTypes] = useState([]);
  const [filter, setFilter] = useState('');
  const initialFilter = {
    type: '',
    from: '',
    to: '',
    filter_id: '',
    filter_value: '',
    process_id: '',
  };
  const [filterValues, setFilterValues] = useState(initialFilter);
  const [iconColor, setIconColor] = useState("primary");
  const client = new graphQLApi(useAuthDispatch());

  useEffect(() => {
    client.query('{eventLogTypes {id title}}').then(r => {
        setTypes(r.eventLogTypes);
      });
  }, []);

  const columns = [
    {
      title: intl.formatMessage({id: 'event_logs.list.column.type', defaultMessage: 'Type'}),
      field: 'type_title',
      type: 'string',
      width: 'auto',
      render: row => types.find(t => t.id === row.type)?.title
    },
    {
      title: intl.formatMessage({id: 'event_logs.list.column.title', defaultMessage: 'Title'}),
      field: 'title',
      type: 'string'
    },
   {
      title: intl.formatMessage({id: 'event_logs.list.column.description', defaultMessage: 'Description'}),
      field: 'description',
      type: 'string'
    },
    {
      title: intl.formatMessage({id: 'event_logs.list.column.process_id', defaultMessage: 'Process ID'}),
      field: 'process_id',
      type: 'string'
    },
    {
      title: intl.formatMessage({id: 'event_logs.list.column.username', defaultMessage: 'Username'}),
      field: 'user.name',
      type: 'string'
    },
    {
      title: intl.formatMessage({id: 'event_logs.list.column.created_at', defaultMessage: 'Created At'}),
      field: 'created_at',
      type: 'datetime',
      defaultSort: 'desc'
    },
  ];

  const handleFilter = () => {
    setShowFilterDialogue(false);
    const values = {...filterValues};
    let string = '';
    for (const [key, value] of Object.entries(values)) {
      if (value === '') {
        delete values[key];
      } else {
          if (key === 'type') {
            const event = types?.find(t => t.title === values.type)?.id.replaceAll('\\', '\\\\');
            string = string + 'type_eq:"' + event + '" ';
            delete values[key];
          } else {
            string = string + key + ':"' + value.trim() + '" ';
          }
      }
    }
    setFilter(string);
    setIconColor(string === '' ? 'primary' : 'secondary');
    tableRef.current.update();
  };


   return (<>
   <Dialog open={showFilterDialog} style={{maxWidth: "none" }}>
     <DialogTitle disableTypography id="filter-dialog-title"><Typography variant="h2">{intl.formatMessage({id: 'event_logs.filter.dialog.title', defaultMessage: 'Filter event logs'})}</Typography></DialogTitle>
     <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={3}>{intl.formatMessage({id: 'event_logs.filter.type', defaultMessage: 'Type'})}</Grid>
          <Grid item xs={8}>
            <TextField fullWidth value={filterValues.type}
                       select
                       onChange={e => setFilterValues(
                         {...filterValues, type: e.target.value})}
                       variant={'standard'}>
              {types?.map(t => t.title).map((t,i) => <MenuItem key={'type-' + i} value={t}>{t}</MenuItem>,)}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
            <Grid item xs={3}>{intl.formatMessage({id: 'event_logs.filter.from', defaultMessage: 'From'})}</Grid>
            <Grid item xs={8}>
              <TextField fullWidth
                id="datetime-local"
                type="datetime-local"
                value={filterValues.from}
                onChange={e => {
                  setFilterValues(
                    {...filterValues, from: e.target.value});
                }}
                variant={'standard'}
              />
            </Grid>
          </Grid>
        <Grid container spacing={4}>
            <Grid item xs={3}>{intl.formatMessage({id: 'event_logs.filter.to', defaultMessage: 'To'})}</Grid>
            <Grid item xs={8}>
              <TextField fullWidth
                         id="datetime-local"
                         type="datetime-local"
                         value={filterValues.to}
                         onChange={e => setFilterValues(
                           {...filterValues, to: e.target.value})}
                         variant={'standard'}
              />
            </Grid>
          </Grid>
        <Grid container spacing={4}>
            <Grid item xs={3}>{intl.formatMessage({id: 'event_logs.filter.filter_id', defaultMessage: 'Filter Id'})}</Grid>
            <Grid item xs={8}>
              <TextField fullWidth value={filterValues.filter_id}
                         onChange={e => setFilterValues(
                           {...filterValues, filter_id: e.target.value})}
                         variant={'standard'}/>
            </Grid>
          </Grid>
        <Grid container spacing={4}>
            <Grid item xs={3}>{intl.formatMessage({id: 'event_logs.filter.filter_value', defaultMessage: 'Filter value'})}</Grid>
            <Grid item xs={8}>
              <TextField fullWidth value={filterValues.filter_value}
                         onChange={e => setFilterValues(
                           {...filterValues, filter_value: e.target.value})}
                         variant={'standard'}/>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3}>{intl.formatMessage({id: 'event_logs.filter.process_id', defaultMessage: 'Process Id'})}</Grid>
            <Grid item xs={8}>
              <TextField fullWidth value={filterValues.process_id}
                         onChange={e => setFilterValues(
                           {...filterValues, process_id: e.target.value})}
                         variant={'standard'}/>
            </Grid>
          </Grid>
     </DialogContent>
     <DialogActions>
       <Button autoFocus onClick={() => setFilterValues(initialFilter)} color="default">
         <FormattedMessage id="common.button.reset" />
       </Button>
       <Button autoFocus onClick={() => {setShowFilterDialogue(false);}} color="default">
         <FormattedMessage id="common.button.cancel" />
       </Button>
       <Button onClick={() => handleFilter()} color="primary">
         <FormattedMessage id="common.button.ok" />
       </Button>
     </DialogActions>
    </Dialog>
    <Paper>
      <EnhancedTable
        {...props}
        ref={tableRef}
        title={intl.formatMessage({id: 'event_logs.list.table.heading', defaultMessage: "Event Logs list"})}
        columns={columns}
        direction="desc"
        query="eventLogs"
        fields='id type title description process_id user{name} created_at'
        icon={<List/>}
        sorting="created_at"
        filter={filter}
        actions={
        [
          {
            icon: FilterList,
            tooltip: intl.formatMessage({id: 'event_logs.list.actions.filter', defaultMessage: 'Filter',}),
            isFilterAction: true,
            onClick: () => {setShowFilterDialogue(true);},
            color: iconColor
          }
        ]
      }
        urlState
      />
    </Paper>
    </>
  );
}
