import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import DraggableTable from 'components/DataTable/DraggableTable';
import {Paper} from '@material-ui/core';
import {Add, Delete, Edit, LineWeight} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import LabelElementDialog from 'components/Dialogs/LabelElementDialog';

const LabelsTable = forwardRef((props, ref) => {
  const {labelId} = props;
  const intl = useIntl();
  const client = new graphQLApi(useAuthDispatch());
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [fields, setFields] = useState(null);
  const [label, setLabel] = useState(null);

  const types = [
        {
            id: 'text',
            title: intl.formatMessage({id: "labels.type.label.text", defaultMessage: "Text"})
        },
        {
            id: 'field',
            title: intl.formatMessage({id: "labels.type.label.field", defaultMessage: "Field"})
        },
        {
            id: 'field_with_title',
            title: intl.formatMessage({id: "labels.type.label.field_with_title", defaultMessage: "Field with Title"})
        },
    ];

    const columns = [
        {
          field:'type',
          title: intl.formatMessage({id: "labels.edit.label.type", defaultMessage: "Line type"}),
          render: row => types.find(t => t.id === row.type).title,
          sortable: false
        },
        {
          title: intl.formatMessage({id: "labels.edit.label.value", defaultMessage: "Value / Field"}),
          field: 'string',
          render: row => row.field?.name || row.string,
          sortable: false
        },
        {
          title: intl.formatMessage({id: "labels.edit.label.alignment", defaultMessage: "Left alignment"}),
          field: 'alignment',
          sortable: false
        }
    ];

    useEffect(() => {
      let filter = '';
      if (props.entityTypeId) {
        filter = `(filter:{entity_type_id:${props.entityTypeId}})`;
      }

      client.query(`{fields${filter}{data{id name type key uses_languages entity_type{id title}}} labels(filter:{id:${labelId}}){data{entity_type{id}}}}`).then(r => {
          if (r && r.hasOwnProperty('fields') && r.hasOwnProperty('labels')) {
            if (r.labels && r.labels.data) setLabel(r.labels.data[0]);
            const futureFields = r.fields.data
              .filter(fd => fd.type.search(/_\d+$/) === -1);
            setFields(futureFields);
          }
      })
    }, [props.entityTypeId]);

    useImperativeHandle(ref, () => ({}));

    const tableRef = useRef();

    function handleRowDelete(row) {
        if (tableRef.current && tableRef.current.hasOwnProperty('update') && row.id) {
            client.mutate(`{labelElementDelete(id:${row.id})}`).then(() => {
                tableRef.current.update();
            });
        }
    }
  function handleRowEdit(row) {
        setOpenEditDialog(true);
        setEditingRow(row);
    }

    function handleDialogClose(data, isNew) {
        disableDialog()
        if (!data) return;
        if (isNew) {
            if (tableRef.current && tableRef.current.hasOwnProperty('getRows')) {
                const rows = tableRef.current.getRows();
                const lastSort = rows.length > 0 ? rows.at(-1).sorting : 0;
                data.sorting = lastSort + 1;
                createRow(data)
            }
        }
        else editRow(data);

    }

    function getMutationQuery(data) {
      return '{' +
        'labelElement'+(data.id ? 'Update' : 'Create')+'(' + (data.id ? 'id:'+data.id+',' : '') +
        ' sorting:' + data.sorting + ',' +
        ' type:"' + (data.type ? data.type.id : '') + '",' +
        ' string:"' + (data.string && data.type.id !== 'field' ? data.string : '') + '",' +
        ' alignment:' + (data.alignment ? data.alignment : 0) + ',' +
        ' label_id:' + labelId + ',' +
        ' is_all_languages_exported:' + (data.is_all_languages_exported ? 'true' : 'false') + ',' +
        ' field_id:' + (data.field ? data.field.id : null) + ',' +
        ' separator:"' + data.separator + '"' +
        ') {id}' +
        '}';
    }

    function createRow(data) {
        client.mutate(getMutationQuery(data))
        .then(() => {
            if (tableRef.current && tableRef.current.hasOwnProperty('update')) {
                tableRef.current.update();
            }
        })
        disableDialog();
    }

    function editRow(data) {
        client.mutate(getMutationQuery(data))
        .then(() => {
            if (tableRef.current && tableRef.current.hasOwnProperty('setRows') && tableRef.current.hasOwnProperty('getRows')) {
                tableRef.current.setRows(tableRef.current.getRows().map(row => {
                    if (row.id === data.id) {
                        data.type = data.type.id;
                        return data;
                    }
                    return row;
                }));
            }
        })
        .catch(error => console.error(error));
        disableDialog();
    }

    function handleRowAdd() {
        setOpenEditDialog(true);
        setEditingRow({});
    }

    function disableDialog() {
        setOpenEditDialog(false);
        setEditingRow(null);
    }

    function handleInternalSort(rows) {
      if (!Array.isArray(rows)) return;
      if (tableRef.current && tableRef.current.hasOwnProperty('update')) {
        rows.forEach(row => {
          let query = `{labelElementUpdate(id:"${row.id}", sorting:${row.sorting}){id}}`;
          client.mutate(query);
        })
      }
    }

    return (
        <Paper>
                <DraggableTable
                    title={intl.formatMessage({id: "labels.edit.label.rows", defaultMessage: 'Rows'})}
                    columns={columns}
                    query='labelElements'
                    handleInternalSort={handleInternalSort}
                    fields='sorting type string alignment separator is_all_languages_exported field{id key name type uses_languages}'
                    filter={`label_id:${labelId ? labelId : 0}`}
                    icon={<LineWeight />}
                    sorting='sorting'
                    actions={[
                        {
                          icon: Edit,
                          rowClick: true,
                          tooltip: intl.formatMessage({id: "enhanced_table.actions.edit", defaultMessage: "Edit"}),
                          onClick: (row) => {
                            return handleRowEdit(row)
                          },
                        },
                        {
                          icon: Add,
                          isFreeAction: true,
                          tooltip: intl.formatMessage({id: "enhanced_table.actions.add", defaultMessage: "Add"}),
                          onClick: () => handleRowAdd(),
                        },
                        {
                          icon: Delete,
                          tooltip: intl.formatMessage({id: "enhanced_table.actions.delete", defaultMessage: "Delete"}),
                          onClick: (row) => handleRowDelete(row),
                        }
                    ]}
                    ref={tableRef}
                    {...props}
                />
                {(openEditDialog && fields) && <LabelElementDialog
                    open={openEditDialog}
                    onClose={handleDialogClose}
                    entityTypeId={label ? label.entity_type.id : null}
                    row={editingRow}
                    types={types}
                    fields={fields}
                />}
        </Paper>
    );
});

export default LabelsTable;
