import React, {useRef} from 'react';
import {useIntl} from "react-intl";
import {Grid, Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {FileCopy} from '@material-ui/icons';
import {graphQLApi} from '../../services/GraphQLApi';
import {useAuthDispatch} from '../../contexts/Auth';

export default function PrintoutList(props) {
  const intl = useIntl();
  const ref = useRef({});
  const columns = [
    {
      title: intl.formatMessage({id: 'printouts.list.column.title', defaultMessage: 'Title'}),
      field: 'title'
    },
    {
      title: intl.formatMessage({id: 'printouts.list.column.created_at', defaultMessage: 'Created'}),
      field: 'created_at',
      type: 'datetime'
    }
  ];

  const client = new graphQLApi(useAuthDispatch());

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
            <EnhancedTable
                ref={ref}
                columns={columns}
                query={"printouts"}
                mutations={"printout"}
                fields="title created_at"
                title={intl.formatMessage({id: "menu.printouts.list"})}
                actions={[
                  {
                    icon: FileCopy,
                    tooltip: intl.formatMessage({id: "printouts.list.action.copy", defaultMessage: "Copy"}),
                    onClick: (row) => {
                      console.log(row)
                      ref.current.isLoading(true)
                      client.mutate('($id:ID!) {printoutCopyCreate(id:$id) {id}}',
                        {id:  parseInt(row.id)}).then(r => {
                        if (r) {
                          ref.current.update();
                        }
                      });

                    }
                  }
                ]}
                {...props}
            />
        </Paper>
      </Grid>
    </Grid>
  );
}
