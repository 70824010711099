import {authUser} from 'contexts/Auth';
import {Link} from '@material-ui/core';

export default function XmlFile(intl) {
    return [
        {
            field: "url",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.xml_file.host", defaultMessage: "Full URL of the file to be imported"}),
            help: intl.formatMessage({
                id: "connector.driver.xml_file.url",
                defaultMessage: "To import a file from a webserver specify the full url of the file, example: https://example.com/test.xml",
            }, {
                schema: authUser().site.schema,
            }),
        },
        {
            field: "filename",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.xml_file.filename", defaultMessage: "Filename of the exported file"}),
        },
        {
            field: "filename",
            initial: "",
            render: (_key, data) => {
                console.log(data);
                return <Link key={"connector_filename_key_" + _key}
                             href={"https://cdn.opendims.com/" + authUser().site.schema + "/exports/" + data.settings_filename}
                             target="_blank">{intl.formatMessage({id:"connector.driver.xml_file.download_link", defaultMessage:"Download link to exported file"})}</Link>
            }
        },
    ];
}
