import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {Share} from "@material-ui/icons";
import {driverFields, driverTestFields, getConnectorDrivers} from "views/Admin/Settings/ConnectorDrivers";
import {graphQLApi, graphQLReduceFields} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import EditForm from "components/Form/EditForm";
import {useNavigate, useParams} from "react-router-dom";

export default function ConnectorEdit() {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();

  let id = Number(params.id);

  const f = [
    {
      column: 1,
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "connectors.edit.label.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      column: 1,
      field: "driver",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "connectors.edit.label.driver", defaultMessage: "Driver"}),
      options: getConnectorDrivers(intl),
      onChange: (value, data, setData) => {
        setData({...data, driver: value, ...graphQLReduceFields(getDriverFields(value), "initial")});
        appendServiceFields(value);
      }
    },
    // {
    //   column: 1,
    //   field: "webhook_token",
    //   initial: "",
    //   type: "String",
    //   label: intl.formatMessage({id: "connectors.edit.label.webhook_token", defaultMessage: "Webhook Token"}),
    //   input: "text"
    // },
    {
      column: 1,
      field: "is_active",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({id: "connectors.edit.label.is_active", defaultMessage: "Active"}),
      input: "switch"
    },
    {
      field: "settings",
      column: 2,
      input: "heading",
      label: intl.formatMessage({id: "connectors.edit.label.settings", defaultMessage: "Settings"}),
    },
  ];
  const [fields, setFields] = useState(f);

  const getDriverFields = (driver) => {
    if (driverFields[driver] && typeof driverFields[driver] === "function") {
      return driverFields[driver](intl).map(f => {
        return {...f, column: 2, field: "settings_" + f.field}
      });
    }
    return [];
  };

  const appendServiceFields = (driver) => {
    let driverFields = getDriverFields(driver);
    setValidation({...validation, ...graphQLReduceFields(driverFields, "validation")});
    setFields([...fields.filter(f => f.column === 1 || f.field === "settings"), ...driverFields]);
    return driverFields;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState(graphQLReduceFields(fields, 'validation'));
  const [connector, setConnector] = useState(graphQLReduceFields(fields, 'initial'));

  const client = useCallback(new graphQLApi(useAuthDispatch()), []);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      client
        .query(
          "{ connectors(filter:{id:" + id + "}) { data {" +
          "  id is_active title driver settings " +
          " }}" +
          "}"
        )
        .then((result) => {
          if (result && result.hasOwnProperty("connectors")) {
            let data = result.connectors.data[0];
            let settings = JSON.parse(data.settings);
            if (!settings) settings = {};
            getDriverFields(data.driver).forEach(f => {
              let settingsKey = f.field.substr(9);
              data[f.field] = settings[settingsKey] !== undefined ? settings[settingsKey] : f.initial;
            });
            setConnector(data);
            appendServiceFields(data.driver);
          }
          setIsLoading(false);
        });
    }
  }, [client, id]);

  const dispatch = useAuthDispatch();
  const save = () => {
    let query =
      "(" + (id ? "$id:ID!," : "") + "$title:String, $driver:String, $settings:String, $is_active: Boolean) " +
      "{ response: connector" + (id ? "Update" : "Create") +
      "(" + (id ? "id:$id, " : "") + "title:$title, driver:$driver, settings:$settings, is_active:$is_active) " +
      "{ id } }";
    let settings = {};
    getDriverFields(connector.driver).forEach(f =>
      settings[f.field.substr(9)] = connector[f.field]
    );
    let variables = {
      title: connector.title,
      driver: connector.driver,
      is_active: connector.is_active,
      settings: JSON.stringify(settings),
    };
    if (id) {
      variables.id = id;
    }
    new graphQLApi(dispatch, (errors) => {
      let vs = graphQLReduceFields(fields, "validation");
      if (errors) {
        errors.forEach(e => {
          if (e && e.extensions && e.extensions.validation) {
            if (e.message === "Driver test failed") {
              let fails = driverTestFields[connector.driver](intl);
              for (let field in fails) {
                vs["settings_" + field] = fails[field];
              }
            }
            let prefix = "";
            if (e.message === "Settings data is incorrect") {
              prefix = "settings_";
            }
            for (let field in e.extensions.validation) {
              vs[prefix + field] = e.extensions.validation[field];
            }
          }
        });
      }
      setValidation(vs);
      setIsLoading(false);
    }).mutate(query, variables).then(r => {
      setIsLoading(false);
      if (r && r.hasOwnProperty('response')) {
        navigate(-1);
      }
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<Share/>}
            title={intl.formatMessage({id: "connectors.edit.heading", defaultMessage: "Connector edit"})}/>
          <CardContent>
            <form onSubmit={save}>
              <EditForm
                fields={fields}
                cols={2}
                isLoading={isLoading}
                data={connector}
                setData={setConnector}
                save={save}
                validation={validation}
                back={true}
              />
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
