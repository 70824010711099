import {Box, Breadcrumbs, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from 'assets/theme/components/admin-navbar';
import {useAuthDispatch} from 'contexts/Auth';
import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {graphQLApi} from 'services/GraphQLApi';

const useStyles = makeStyles(componentStyles);

export default function Breadcrumb({routes, history}) {
  const classes = useStyles();
  const intl = useIntl();
  const location = useLocation();
  const brandText = intl.formatMessage({id:"navbar.brand_text"});

  const getBreadcrumb = (routes, prefix = "", path = []) => {
    routes.forEach(route => {
      let r = {...route};
      // Transform a path like /admin/entities/:id/values/:id to something able to regexp:
      // like /admin/entities/[0-9]+/values/[0-9]+
      let regexUri = String(prefix + r.path).replaceAll(new RegExp('/:[a-z_-]+', 'gi'), '/([0-9]+)')
      regexUri = regexUri.replace(/\/\(\[0-9]\+\)\?$/i, '(/[0-9]+)?');
      regexUri = regexUri.replace(/\/\(\[0-9]\+\)\?\//i, '/([0-9]+)/');
      if (location.pathname.search(regexUri) === 0) {
        r.href = prefix + r.path;
        let values = location.pathname.match(regexUri);
        let params = r.path.match(/:[a-z_]+/gi);
        if (params) {
          r.match = {};
          params.forEach((p, i) => {
            r.match[p.substr(1)] = values[i + 1] ? values[i + 1].substring(1) : null;
          });
        }
        path.push(r);
      }
      if (r.views) {
        path = getBreadcrumb(r.views, prefix + r.path, path);
      }
    });
    return path;
  };
  const [breadcrumbs, setBreadcrumbs] = React.useState([]);

  const client = new graphQLApi(useAuthDispatch());
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.getElementById('main-content').scrollTop = 0;
    let crumbs = getBreadcrumb(routes, "");
    let setCrumbs = true;
    let titleCrumbs = [];
    if (crumbs.length) {
      crumbs.forEach((crumb, index) => {
        titleCrumbs.unshift(crumb.name);
        if (crumb.query && typeof crumb.queryResult === "function") {
          setCrumbs = false;
          let q = crumb.query;
          let doQuery = false;
          for (let k in crumb.match) {
            if (crumb.match[k]) {
              q = q.replace('#' + k + '#', crumb.match[k]);
              doQuery = true;
            }
            crumb.href = crumb.href.replace('/:'+k+'\\?', crumb.match[k] ? '/' + crumb.match[k] : '');
            crumb.href = crumb.href.replace(':'+k, crumb.match[k] ? crumb.match[k] : '');
          }
          if (doQuery) {
            client.query('{' + q + '}').then(result => {
              crumb.name = crumb.queryResult(result);
              crumbs.splice(index, 1, crumb);
              // console.log(crumbs);
              setBreadcrumbs(crumbs);
              let name = crumb.name;
              if (typeof crumb.name === "object" && crumb.name.hasOwnProperty('props')) {
                name = crumb.name.props.name;
              }
              titleCrumbs[titleCrumbs.length - index - 1] = name;
              document.title = brandText + " - " + titleCrumbs.join(" - ");
            });
          } else {
            crumbs.splice(index, 1, crumb);
            // console.log(crumbs);
            setBreadcrumbs(crumbs);
            let name = crumb.name;
            if (typeof crumb.name === "object" && crumb.name.hasOwnProperty('props')) {
              name = crumb.name.props.name;
            }
            titleCrumbs[titleCrumbs.length - index - 1] = name;
            document.title = brandText + " - " + titleCrumbs.join(" - ");
          }
        }
      })
    }
    if (setCrumbs) {
      // console.log(crumbs);
      if (titleCrumbs.length) document.title = brandText + " - " + titleCrumbs.join(" - ");
      setBreadcrumbs(crumbs);
    }
  }, [location]);

  return <Breadcrumbs aria-label="breadcrumb">
    {breadcrumbs.slice(0, -1).map((b, k) => {
      if (b.component && typeof b.name !== "object") {
        return (<Link
          key={"breadcrumb-" + k}
          color="inherit"
          onClick={() => history.push(b.href)}
          className={classes.breadcrumb}
        >{b.name}</Link>);
      }
      return (<Box key={"breadcrumb-" + k}>{b.name}</Box>);
    })}
    <Box color="textPrimary" className={classes.title}>
      {breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].name : 'Unknown'}
    </Box>
  </Breadcrumbs>
}
