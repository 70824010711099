import {
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function getThumbnailFromUrl(url) {
  if (!url || url === "") return null;
  const urlSplit = url.split('/');
  const file = urlSplit.pop();
  const schema = urlSplit.shift();
  return schema + '/' + urlSplit.join('/') + '/thumbnails/' + file;
}

export function getThumbnailComponentFromUrl(url) {
  if (!url || url === "") return null;
  const urlSplit = url.split('/');
  const file = urlSplit.pop();
  const schema = urlSplit.shift();
  const extension = file.split('.').pop();
  const imageExtensions = ['jpg', 'png', 'gif', 'jpeg', 'bmp', 'svg', 'ico', 'webp'];
  if (imageExtensions.includes(extension))
    return <div alt={file} title={file} style={{
      background: 'url("' + schema + '/' + urlSplit.join('/') + '/thumbnails/' + file + '") no-repeat center center',
      width: '100%',
      height: '100%',
    }}/>;
  else {
    let icon = faFile;
    switch (extension) {
      case 'pdf':
        icon = faFilePdf;
        break;
      case 'doc':
      case 'docx':
      case 'odf':
        icon = faFileWord;
        break;
      case 'xls':
      case 'xlsx':
      case 'ods':
        icon = faFileExcel;
        break;
      case 'ppt':
      case 'pptx':
      case 'odp':
        icon = faFilePowerpoint;
        break;
      case 'html':
      case 'php':
      case 'js':
      case 'json':
        icon = faFileCode;
        break;
      case 'zip':
      case 'gz':
      case '7z':
        icon = faFileArchive;
        break;
      case 'mov':
      case 'mp4':
      case 'mpg':
      case 'mkv':
      case 'webm':
        icon = faFileVideo;
        break;
      case 'mp3':
      case 'wav':
        icon = faFileVideo;
        break;
      case 'rtf':
      case 'txt':
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon icon={icon} size="2x" title={file}/>;
  }
}

export function renderThumbNails(value, idx = 0)  {
  const imageExtensions = ['jpg', 'png', 'gif', 'jpeg', 'bmp', 'svg', 'ico', 'webp'];
  const extension = value.string?.split('.').pop();
  return imageExtensions.includes(extension) ?
    <img height={24} key={'column-' + value.id + '-image-' + idx} style={{marginLeft: 3}} src={getThumbnailFromUrl(value.string)} alt={value?.string?.split('/').pop()}
         title={value.string?.split('/').pop()}/>
    : getThumbnailComponentFromUrl(value.string);
}

export function FileTypeIcon(mimeType) {
  const mimeTypes = {
    'image': faFileImage,
    'audio': faFileAudio,
    'video': faFileVideo,
    // Documents
    'application/pdf': faFilePdf,
    'application/msword': faFileWord,
    'application/vnd.ms-word': faFileWord,
    'application/vnd.oasis.opendocument.text': faFileWord,
    'application/vnd.openxmlformats-officedocument.wordprocessingml': faFileWord,
    'application/vnd.ms-excel': faFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml': faFileExcel,
    'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
    'application/vnd.ms-powerpoint': faFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml': faFilePowerpoint,
    'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
    'text/plain': faFileAlt,
    'text/html': faFileCode,
    'application/json': faFileCode,
    // Archives
    'application/gzip': faFileArchive,
    'application/zip': faFileArchive,
  }
  for (let key in mimeTypes) {
    if (mimeType && mimeType.search(key) === 0) {
      return mimeTypes[key];
    }
  }
  return faFile;
}

export function FileTypeClass(intl, mimeType, filename) {
  const mimeTypes = {
    "image": "image",
    "audio": "audio",
    "video": "video",
    // Documents
    "application/pdf": "pdf",
    "application/msword": "word",
    "application/vnd.ms-word": "word",
    "application/vnd.oasis.opendocument.text": "word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml": "word",
    "application/vnd.ms-excel": "excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml": "excel",
    "application/vnd.oasis.opendocument.spreadsheet": "excel",
    "application/vnd.ms-powerpoint": "powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml": "powerpoint",
    "application/vnd.oasis.opendocument.presentation": "powerpoint",
    "text/plain": "text",
    "text/html": "code",
    "application/json": "code",
    // Archives
    "application/gzip": "archive",
    "application/zip": "archive"
  }
  for (let key in mimeTypes) {
    if (mimeType && mimeType.search(key) === 0) {
      if (!intl) {
        return mimeTypes[key];
      }
      switch (mimeTypes[key]) {
        case 'image':
          return intl.formatMessage({id:"common.filetype.class.image", defaultMessage:"Image"});
        case 'audio':
          return intl.formatMessage({id:"common.filetype.class.audio", defaultMessage:"Audio"});
        case 'video':
          return intl.formatMessage({id:"common.filetype.class.video", defaultMessage:"Video"});
        case 'pdf':
          return intl.formatMessage({id:"common.filetype.class.pdf", defaultMessage:"Portable document"});
        case 'word':
          return intl.formatMessage({id:"common.filetype.class.document", defaultMessage:"Document"});
        case 'excel':
          return intl.formatMessage({id:"common.filetype.class.spreadsheet", defaultMessage:"Spreadsheet"});
        case 'powerpoint':
          return intl.formatMessage({id:"common.filetype.class.presentation", defaultMessage:"Presentation"});
        case 'text':
          if (filename) {
            switch (filename.split('.').pop().toLowerCase()) {
              case 'tsv':
              case 'csv':
                return intl.formatMessage({id:"common.filetype.class.data", defaultMessage:"Data"});
              case 'css':
              case 'ts':
              case 'js':
              case 'php':
                return intl.formatMessage({id:"common.filetype.class.code", defaultMessage:"Code"});
              default:
            }
          }
          return intl.formatMessage({id:"common.filetype.class.text", defaultMessage:"Text"});
        case 'code':
          return intl.formatMessage({id:"common.filetype.class.code", defaultMessage:"Code"});
        case 'archive':
          return intl.formatMessage({id:"common.filetype.class.archive", defaultMessage:"Archive"});
        default:
      }
    }
  }
  if (intl) {
    return intl.formatMessage({
      id: "common.filetype.class.unknown",
      defaultMessage: "{ext} file"
    }, {ext: filename ? filename.split('.').pop().toLocaleUpperCase() : 'Unknown'});
  }
  return 'Unknown';
}
