import React, {useEffect, useMemo, useRef, useState} from "react";
import JoditEditor from "jodit-pro-react";
import {joditConfig} from "../../variables/general";

export const Editor = ({ value, onBlur, ...rest }) => {
  const editor = useRef(null);
  const [content, setContent] = useState(value);

  const config = useMemo(() => joditConfig, []);
  useEffect(() => {
    onBlur(content);
  }, [content]);

  return (
    <JoditEditor
      {...rest}
      ref={editor}
      value={content}
      config={config}
      onBlur={newContent => setContent(newContent)}
    />
  );
};
