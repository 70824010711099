import React, {useState} from "react";
import {useNode} from "@craftjs/core";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Slider,
  Typography
} from "@material-ui/core";
import Color from "../Settings/Color";
import {FormatBold, FormatClear, FormatItalic, FormatStrikethrough, FormatUnderlined} from "@material-ui/icons";
import themeColors from "../../../assets/theme/colors";
import {useIntl} from "react-intl";
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";

export const StaticField = ({field, fontSize, style, weight, decoration, color, ...props}) => {
  const {connectors: {connect, drag}} = useNode();
  const intl = useIntl();
  return <Typography
    {...props}
    ref={ref => connect(drag(ref))}
    style={{
      display: "inline",
      color: color,
      fontSize: fontSize + 'px',
      textDecoration: decoration,
      fontWeight: weight,
      fontStyle: field ? style : 'italic',
      border: "1px dashed " + themeColors.primary.light,
    }}
  >{field?.name || intl.formatMessage({
    id: "layouts.prop.field-none-selected",
    defaultMessage: "No field selected"
  })}</Typography>;
}

const StaticFieldSettings = () => {
  const {
    field,
    fontSize,
    style,
    weight,
    decoration,
    color,
    width,
    height,
    margin,
    actions: {setProp}
  } = useNode((node) => ({
    field: node.data.props.field,
    style: node.data.props.style,
    weight: node.data.props.weight,
    decoration: node.data.props.decoration,
    fontSize: node.data.props.fontSize,
    color: node.data.props.color,
    width: node.data.props.width,
    height: node.data.props.height,
    margin: node.data.props.margin,
  }));
  const intl = useIntl();
  const [fields, setFields] = useState([]);
  const client = new graphQLApi(useAuthDispatch());
  // const isEntityField = !!field?.type.match(/^entit(y|ies)_\d+$/);
  const isAssetField = !!field?.type.match(/^assets*$/);

  return (
    <div>
      <FormControl fullWidth variant="standard" margin="normal">
        <InputLabel htmlFor="field">{intl.formatMessage({
          id: "layouts.prop.field",
          defaultMessage: "Field"
        })}</InputLabel>
        <Select
          inputProps={{
            name: 'field',
            id: 'field',
          }}
          value={field}
          renderValue={f => f.entity_type?.title + ': ' + f.name}
          onOpen={_ => {
            if (fields.length) return;
            setFields([{
              id: "--loading--",
              title: intl.formatMessage({id: "common.loading", defaultMessage: "Loading..."})
            }]);
            client.query('{fields(sorting:"entity_type_id,name",direction:"asc"){data{id name type entity_type{id title}}}}').then(r => {
              if (r?.hasOwnProperty('fields')) {
                let eTitle = '';
                let fs = [
                  {
                    id: 'header-system',
                    title: intl.formatMessage({id: "layouts.prop.field_system.header", defaultMessage: "System data"})
                  },
                  {
                    id: 'date',
                    type: 'system',
                    entity_type: {title: 'System'},
                    name: intl.formatMessage({id: "layouts.prop.field_system.date", defaultMessage: "PDF export date"})
                  },
                  {
                    id: 'datetime',
                    type: 'system',
                    entity_type: {title: 'System'},
                    name: intl.formatMessage({
                      id: "layouts.prop.field_system.datetime",
                      defaultMessage: "PDF export date and time"
                    })
                  },
                  {
                    id: 'user',
                    type: 'system',
                    entity_type: {title: 'System'},
                    name: intl.formatMessage({
                      id: "layouts.prop.field_system.user",
                      defaultMessage: "Exporting user's name"
                    })
                  },
                ];
                r.fields.data.forEach(f => {
                  if (eTitle !== f.entity_type.title) {
                    eTitle = f.entity_type.title;
                    fs.push({id: 'header-' + f.entity_type.id, title: eTitle});
                  }
                  fs.push(f);
                })
                setFields(fs);
              }
            });
          }}
          onChange={e => setProp(props => props.field = e.target.value)}
        >{fields.map(f => f.id.match(/^header-.+$/)
          ? <ListSubheader key={f.id} color="primary"
                           style={{backgroundColor: themeColors.info.badgeBg}}>{f.title}</ListSubheader>
          : <MenuItem
            key={"field-" + f.id}
            disabled={f.id === "--loading--"}
            value={f}
          >{f.name}</MenuItem>
        )}</Select>
      </FormControl>
      {!isAssetField && <FormControl size="small" component="fieldset" fullWidth>
        <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
          intl.formatMessage({id: "layouts.prop.font-size", defaultMessage: "Font size {size}"}, {size: fontSize})
        }</FormLabel>
        <Slider
          value={fontSize || 8}
          step={2}
          min={8}
          max={50}
          onChange={(_, value) => {
            setProp(props => props.fontSize = value);
          }}
        />
      </FormControl>}
      {!isAssetField && <FormControl fullWidth margin="dense" component="fieldset">
        <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
          intl.formatMessage({id: "layouts.prop.font-style", defaultMessage: "Font style"})
        }</FormLabel>
        <ButtonGroup size="small">
          <Button onClick={() => setProp((props) => {
            props.style = 'normal';
            props.weight = 'normal';
            props.decoration = 'none';
          }, 500)}>
            <FormatClear/>
          </Button>
          <Button
            onClick={() => setProp((props) => (props.weight = weight === 'bold' ? 'normal' : 'bold'), 500)}
            variant={weight === "bold" && "contained"}
            color={weight === "bold" && "primary"}>
            <FormatBold/>
          </Button>
          <Button
            onClick={() => setProp((props) => (props.style = style === 'italic' ? 'normal' : 'italic'), 500)}
            variant={style === "italic" && "contained"}
            color={style === "italic" && "primary"}>
            <FormatItalic/>
          </Button>
          <Button
            onClick={() => setProp((props) => (props.decoration = decoration === 'underline' ? 'none' : 'underline'), 500)}
            variant={decoration === "underline" && "contained"}
            color={decoration === "underline" && "primary"}>
            <FormatUnderlined/>
          </Button>
          <Button
            onClick={() => setProp((props) => (props.decoration = decoration === 'line-through' ? 'none' : 'line-through'), 500)}
            variant={decoration === "line-through" && "contained"}
            color={decoration === "line-through" && "primary"}>
            <FormatStrikethrough/>
          </Button>
        </ButtonGroup>
      </FormControl>}
      {!isAssetField && <Color setProp={setProp} value={color} prop={"color"}/>}
      {isAssetField &&
        <FormControl fullWidth margin="dense" component="fieldset">
          <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
            intl.formatMessage({
              id: "layouts.prop.width",
              defaultMessage: "Width {width}"
            }, {width: width ? width + '%' : 'auto'})
          }</FormLabel>
          <Slider
            defaultValue={width}
            onChange={(_, value) =>
              setProp((props) => (props.width = value), 500)
            }
            step={5}
            min={0}
            max={1000}
          />
        </FormControl>}
      {isAssetField &&
        <FormControl fullWidth margin="dense" component="fieldset">
          <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
            intl.formatMessage({
              id: "layouts.prop.height",
              defaultMessage: "Height {height}"
            }, {height: height ? height + 'px' : 'auto'})
          }</FormLabel>
          <Slider
            defaultValue={height}
            onChange={(_, value) =>
              setProp((props) => (props.height = value), 500)
            }
            step={5}
            min={0}
            max={1000}
          />
        </FormControl>}
      {isAssetField &&
        <FormControl fullWidth margin="dense" component="fieldset">
          <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
            intl.formatMessage({
              id: "layouts.prop.margin",
              defaultMessage: "Margin {margin}"
            }, {margin: margin ? margin + 'px' : 'auto'})
          }</FormLabel>
          <Slider
            defaultValue={margin}
            onChange={(_, value) =>
              setProp((props) => (props.margin = value), 500)
            }
            step={4}
            min={0}
            max={40}
          />
        </FormControl>}
    </div>
  )
}

export const StaticFieldButton = ({connectors, ...rest}) => {
  const intl = useIntl();
  return <Button
    {...rest}
    ref={ref => connectors.create(ref, <StaticField text="Here I am!"/>, {})}
    variant="outlined"
    style={{border: "1px dashed " + themeColors.primary.light}}
  >{intl.formatMessage({id: "layouts.prop.buttons.field", defaultMessage: "Field"})}</Button>
}

StaticField.craft = {
  props: {
    field: null,
    color: themeColors.text.primary,
    fontSize: 12,
    style: "normal",
    weight: "normal",
    decoration: "none",
    width: 0,
    height: 0,
    margin: 4,
  },
  related: {
    settings: StaticFieldSettings,
  },
  rules: {}
};
