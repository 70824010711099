import React from "react";
import {Button, ButtonGroup, FormControl, FormLabel} from "@material-ui/core";
import {FormatAlignCenter, FormatAlignLeft, FormatAlignRight} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function Alignment({setProp, value, key = "align"}) {
    const intl = useIntl();
    return <FormControl fullWidth margin="dense" component="fieldset">
        <FormLabel component="legend" style={{fontSize:"0.825rem"}}>{
            intl.formatMessage({id:"layouts.prop.alignment",defaultMessage:"Alignment"})
        }</FormLabel>
        <ButtonGroup size="small">
            <Button onClick={() => setProp((props) => (props[key] = 'left'), 500)}
                    variant={value === "left" && "contained"}
                    color={value === "left" && "primary"}>
                <FormatAlignLeft/>
            </Button>
            <Button onClick={() => setProp((props) => (props[key] = 'center'), 500)}
                    variant={value === "center" && "contained"}
                    color={value === "center" && "primary"}>
                <FormatAlignCenter/>
            </Button>
            <Button onClick={() => setProp((props) => (props[key] = 'right'), 500)}
                    variant={value === "right" && "contained"}
                    color={value === "right" && "primary"}>
                <FormatAlignRight/>
            </Button>
        </ButtonGroup>
    </FormControl>;
}
