import React, {useContext, useRef} from 'react';
import {useIntl} from "react-intl";
import {Grid, Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {FileCopy} from '@material-ui/icons';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import {SettingsContext} from "../../../contexts/Settings";

export default function PdfLayoutList(props) {
  const intl = useIntl();
  const ref = useRef({});
  const {settings} = useContext(SettingsContext);
  const columns = [
    {
      title: intl.formatMessage({id: 'pdf-layouts.list.column.title', defaultMessage: 'Title'}),
      field: 'title'
    },
    {
      title: intl.formatMessage({id: 'pdf-layouts.list.column.entity_type', defaultMessage: 'Entity Type'}),
      field: 'entity_type.title'
    },
    {
      title: intl.formatMessage({id: 'pdf-layouts.list.column.language', defaultMessage: 'Language'}),
      field: 'language.name',
      render: row => row.language?.name || settings.language
    },
    {
      title: intl.formatMessage({id: 'pdf-layouts.list.column.created_at', defaultMessage: 'Created'}),
      field: 'created_at',
      type: 'datetime'
    }
  ];

  const client = new graphQLApi(useAuthDispatch());

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
            <EnhancedTable
                ref={ref}
                columns={columns}
                query={"printoutLayouts"}
                mutations={"printoutLayout"}
                fields="title entity_type{title} language{name} created_at"
                title={intl.formatMessage({id: "menu.pdf-layouts"})}
                actions={[
                  {
                    icon: FileCopy,
                    tooltip: intl.formatMessage({id: "printouts.list.action.copy", defaultMessage: "Copy"}),
                    onClick: (row) => {
                      ref.current.isLoading(true)
                      client.mutate('($id:ID!) {printoutLayoutCopy(id:$id)}', {id: row.id}).then(r => {
                        if (r) {
                          ref.current.update();
                        }
                      });

                    }
                  }
                ]}
                {...props}
            />
        </Paper>
      </Grid>
    </Grid>
  );
}
