import {authUser} from 'contexts/Auth';

export default function SalesforceXml(intl) {
    return [
        {
            field: "url_catalog",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.salesforce_xml.url_catalog.label", defaultMessage: "Catalog URL"}),
            help: intl.formatMessage({
                id: "connector.driver.salesforce_xml.url_catalog.help",
                defaultMessage: "Import a catalog file from a webserver, example: https://example.com/master_catalog.xml",
            }, {
                schema: authUser().site.schema,
            }),
        },
        {
            field: "url_pricebooks",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.salesforce_xml.url_pricebooks.label", defaultMessage: "Price book URL"}),
            help: intl.formatMessage({
                id: "connector.driver.salesforce_xml.url_pricebooks.help",
                defaultMessage: "Import a pricebooks file from a webserver, example: https://example.com/price_book.csv.xml",
            }, {
                schema: authUser().site.schema,
            }),
        },
        {
            field: "images_url",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.salesforce_xml.images_url.label", defaultMessage: "Images Base URL"}),
            help: intl.formatMessage({
                id: "connector.driver.xml_file.images_url.help",
                defaultMessage: "The base url of the website to fetch images if they do not have a full url path",
            }),
        },
        {
            field: "encoding",
            initial: "utf-8",
            label: intl.formatMessage({id: "connector.driver.salesforce_xml.encoding.label", defaultMessage: "Encoding"}),
            help: intl.formatMessage({
                id: "connector.driver.xml_file.encoding.help",
                defaultMessage: "Older files use 'iso-8859-1' newer files would use 'utf-8'",
            }),
        },
    ];
}
