export function CreativeCompanyInventoryTest(intl) {
  return {
    apiKey: [intl.formatMessage({id: "connector.driver.creative_company_products.test.api_key", defaultMessage: "Connection test failed, check api key setting."})],
  }
}

export default function CreativeCompanyInventory(intl) {
  return [
    {
      field: "apiKey",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.creative_company_products.api_key", defaultMessage: "Api key"}),
      inputProps: {autoComplete: "off"},
    },
    {
      field: "lastImportedAt",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.creative_company_products.last_import_at", defaultMessage: "Last import done at"}),
      inputProps: {autoComplete: "off"}
    },
  ];
}
