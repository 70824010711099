import {useIntl} from "react-intl";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

export default function FieldSelection({value, onChange, fields}) {
  const intl = useIntl();

  return (
    <FormControl fullWidth>
      <InputLabel id="field_with_title-select-label">{intl.formatMessage({
        id: "labels.edit.label.field",
        defaultMessage: "Field"
      })}</InputLabel>
      <Select
        labelId="field_with_title-select-label"
        value={value}
        onChange={onChange}
      >
        {fields.map(field => (
            <MenuItem value={field} key={field.id}>{`${field.entity_type.title}: ${field.name}`}</MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
