import {authUser} from "contexts/Auth";

export default function DxlFile(intl) {
    return [
        {
            field: "url",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.xml_file.host", defaultMessage: "Host"}),
            help: intl.formatMessage({
                id: "connector.driver.xml_file.url",
                defaultMessage: "To import a file from a webserver specify the full url of the file, example: https://example.com/test.xml",
            }, {
                schema: authUser().site.schema,
            }),
        },
        {
            field: "encoding",
            initial: "iso-8859-1",
            label: intl.formatMessage({id: "connector.driver.xml_file.encoding.label", defaultMessage: "Encoding"}),
            help: intl.formatMessage({
                id: "connector.driver.xml_file.encoding.help",
                defaultMessage: "Older files use 'iso-8859-1' newer files would use 'utf-8'",
            }),
        },
        {
            field: "excluded",
            initial: "",
            label: intl.formatMessage({id: "connector.driver.xml_file.excluded.label", defaultMessage: "Excluded"}),
            help: intl.formatMessage({
                id: "connector.driver.xml_file.excluded.help",
                defaultMessage: "Comma separated list of fields that should be excluded (use * as a wildcard)",
            }),
        },
    ];
}
