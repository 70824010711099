export default function ImapMail(intl) {
  return [
    {
      field: "imap_host",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.imap_mail.imap_host", defaultMessage: "IMAP server hostname or ip"}),
    },
    {
      field: "imap_port",
      initial: "993",
      label: intl.formatMessage({id: "connector.driver.imap_mail.imap_host", defaultMessage: "IMAP server port"}),
    },
    {
      field: "imap_user",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.imap_mail.imap_user", defaultMessage: "IMAP username"}),
    },
    {
      field: "imap_pass",
      initial: "",
      input: "password",
      label: intl.formatMessage({id: "connector.driver.imap_mail.imap_pass", defaultMessage: "IMAP password"}),
    },
    {
      field: "imap_secure",
      initial: "notls/ssl",
      label: intl.formatMessage({id: "connector.driver.imap_mail.imap_secure", defaultMessage: "IMAP connection method"}),
      options: [
        {id:"ssl", name:"SSL encryption"},
        {id:"tls", name:"TLS encryption"},
        {id:"false", name:"Not secured"},
      ]
    },
    {
      field: "imap_archive",
      initial: "Archives",
      label: intl.formatMessage({id: "connector.driver.imap_mail.imap_archive", defaultMessage: "IMAP Archive folder name"}),
    },
    {
      field: "smtp_host",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.smtp_mail.smtp_host", defaultMessage: "SMTP server hostname or ip"}),
    },
    {
      field: "smtp_port",
      initial: "587",
      label: intl.formatMessage({id: "connector.driver.smtp_mail.smtp_host", defaultMessage: "SMTP server port"}),
    },
    {
      field: "smtp_user",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.smtp_mail.smtp_user", defaultMessage: "SMTP username"}),
    },
    {
      field: "smtp_pass",
      initial: "",
      input: "password",
      label: intl.formatMessage({id: "connector.driver.smtp_mail.smtp_pass", defaultMessage: "SMTP password"}),
    },
    {
      field: "smtp_secure",
      initial: "tls",
      label: intl.formatMessage({id: "connector.driver.smtp_mail.smtp_secure", defaultMessage: "SMTP connection method"}),
      options: [
        {id:"tls", name:"TLS encryption"},
        {id:"ssl", name:"SSL encryption"},
        {id:"none", name:"Not secured"},
      ]
    },
  ];
}

export function ImapMailTest(intl) {
  return {
    imap_host: [intl.formatMessage({id: "connector.driver.imap_mail.test.imap_host", defaultMessage: "Connection test failed, check imap settings."})],
  }
}
