import React from "react";
import {useIntl} from "react-intl";
import StringField from "./StringField";
import moment from "moment/moment";

export default function DateTimeField(props) {
  const intl = useIntl();
  return <StringField {...props}
                      type={'datetime-local'}
                      valueFormatter={value => value && moment(value).format(intl.formatMessage({id: "common.datetime.format"}))}/>;
}
