import React, {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Button, FormControl, InputLabel} from "@material-ui/core";
import themeColors from "../../../../assets/theme/colors";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {getFieldLabel} from "./Field";
import Box from "@material-ui/core/Box";
import {graphQLApi} from "../../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../../contexts/Auth";
import {getEntityValueColumnFromField} from "../../../../variables/fields";

export default function ButtonField({field}) {
  const intl = useIntl();
  const {language} = useContext(LanguageContext);
  const {getValuesOfField, setValuesOfField} = useContext(EntityContext);
  let value = getValuesOfField(field.id)?.pop() || {};

  const [compFields, setCompFields] = useState([]);
  const client = new graphQLApi(useAuthDispatch());
  useEffect(() => {
    client.query('{fields(filter:{id:' + field.id + '}){data{id computation_fields{id name type}}}}').then(r => {
      if (r.hasOwnProperty('fields') && r.fields.data[0]) {
        setCompFields(r.fields.data[0].computation_fields || []);
      }
    });
  }, []);
  const column = getEntityValueColumnFromField(field);

  return value?.string !== field.computation_value ? <FormControl fullWidth>
    <Box style={{display: "flex"}}>
      <Button
        disabled={compFields?.filter(cf => !getValuesOfField(cf.id).length).length > 0}
        id={'field-' + field.id}
        variant={"contained"}
        color={"primary"}
        onClick={event => {
          event.target.disabled = true;
          setValuesOfField(field.id, [{string: field.computation_value}]);
        }}
      >{getFieldLabel(field, language)}</Button>
      {compFields?.filter(cf => !getValuesOfField(cf.id).length).length > 0 &&
        <Box style={{margin: 8, color: themeColors.error.main}}>{
          intl.formatMessage({
            id: "entity.edit.field.button.validation_fields",
            defaultMessage: "The following fields are missing data: {fields}"
          }, {
            fields: compFields?.filter(cf => !getValuesOfField(cf.id).length).map(cf => cf.name).join(", ")
          })
        }</Box>}
    </Box></FormControl> : <FormControl
    fullWidth
    variant="standard"
  >
    <InputLabel shrink>{getFieldLabel(field, language)}</InputLabel>
    <Box height={36.45} pt={2}>{value ? value[column] : ''}</Box>
  </FormControl>;
}
