import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// @material-ui/icons components
import Search from '@material-ui/icons/Search';

import componentStyles from 'assets/theme/layouts/admin.js';
import NavbarDropdown from 'components/Dropdowns/NavbarDropdown.js';
import AdminFooter from 'components/Footers/AdminFooter.js';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import {authUser} from 'contexts/Auth';
import React from 'react';
import {useIntl} from 'react-intl';
import {Route, Routes} from 'react-router-dom';

import getRoutesDef from 'routes/admin';
import {config} from '../config';

const useStyles = makeStyles(componentStyles);

export default function Admin(props) {
  const {history, match} = props;
  const intl = useIntl();
  const classes = useStyles();
  const routes = props.routes ? props.routes : getRoutesDef(intl);
  const getRoutes = (routes, prefix = "") => {
    return routes.map((prop) => {
      let rs = [];
      if (prop.views) {
        rs = getRoutes(prop.views, prop.path + '/');
      }
      rs.push(<Route
        path={prefix + prop.path + (prop.views ? '/*' : '')}
        element={<prop.component/>}
        exact={prop.exact}
      />);
      return rs;
    });
  };

  return (
    <div>
      <Sidebar
        {...props}
        pathPrefix={"/admin"}
        routes={routes}
        dropdown={<NavbarDropdown/>}
        input={
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-search-responsive">
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-search-responsive"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <Box
                    component={Search}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        }
      />
      <Box position="relative" className={classes.mainContent}>
        <AdminNavbar
          routes={routes}
          history={history}
          match={match}
          logo={{
            innerLink: "/",
            imgSrc: (authUser().site && authUser().site.logo_uri)
              ? authUser().site.logo_uri
              : config.brandLogo.default,
            imgAlt: intl.formatMessage({id: "navbar.brand_text"}),
          }}
        />
        <Container
          maxWidth={false}
          component={Box}
          classes={{root: classes.containerRoot}}
          id='main-content'
        >
          <Container classes={{root: classes.mainRoot}}>
            <Routes>
              {getRoutes(routes)}
            </Routes>
          </Container>
          <AdminFooter width={"calc(100vw - 270px)"}/>
        </Container>
      </Box>
    </div>
  );
};
