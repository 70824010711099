import React, {useEffect, useRef, useState} from 'react';
import {Button, Grid, IconButton, LinearProgress, Snackbar, SnackbarContent} from "@material-ui/core";
import {useAuthDispatch} from "../../contexts/Auth";
import {graphQLApi} from "../../services/GraphQLApi";
import {Close} from "@material-ui/icons";
import {useIntl} from "react-intl";
import themeColors from "../../assets/theme/colors";
import Link from "@material-ui/core/Link";


export default function JobsChecker() {
  const intl = useIntl();
  let checkedAtTimer = useRef(null)
  const [showImporting, setShowImporting] = useState(false);
  const [importKey, setImportKey] = useState(null);
  const [jobStarted, setJobStarted] = useState(false);
  const [jobCompleted, setJobCompleted] = useState(false);
  const [jobCancelled, setJobCancelled] = useState(false);
  const [progress, setProgress] = useState(null);
  const [total, setTotal] = useState(0);
  const [showExporting, setShowExporting] = useState(false);
  const [exportKey, setExportKey] = useState(null);
  const [exportJobStarted, setExportJobStarted] = useState(false);
  const [exportJobCompleted, setExportJobCompleted] = useState(false);
  const [exportJobCancelled, setExportJobCancelled] = useState(false);
  const [exportProgress, setExportProgress] = useState(null);
  const [exportTotal, setExportTotal] = useState(0);
  const [exportUrl, setExportUrl] = useState('');
  const client = new graphQLApi(useAuthDispatch());

  useEffect(() => {
    checkedAtTimer.current = setInterval(() => {
      const iKey = JSON.parse(localStorage.getItem('importKey'))
      if (iKey) {
        checkJob(iKey.key);
      }
      setImportKey(cur => {
        if (iKey) {
          if (cur !== iKey.key)
            return iKey.key;
          return cur;
        }
        return null;
      })

      const eKey = JSON.parse(localStorage.getItem('exportKey'))
      if (eKey) {
        checkExportJob(eKey.key);
      }
      setExportKey(cur => {
        if (eKey) {
          if (cur !== eKey.key)
            return eKey.key;
          return cur;
        }
        return null;
      })
    }, 2500);
    return () => {
      clearInterval(checkedAtTimer.current);
    };
  }, []);

  useEffect(() => {
    const iKey = JSON.parse(localStorage.getItem('importKey'));
    if (iKey) {
      setShowImporting(true)
      setTotal(iKey.total)
    } else {
      setShowImporting(false)
      setJobStarted(false)
      setJobCancelled(false)
      setJobCompleted(false)
      setProgress(null)
      setTotal(0)
    }
  }, [importKey]);

  useEffect(() => {
    const eKey = JSON.parse(localStorage.getItem('exportKey'));
    if (eKey) {
      setShowExporting(true)
      setExportJobCompleted(false)
      setExportJobStarted(false)
      setExportJobCancelled(false)
      setExportTotal(eKey.total)
    } else {
      // setShowExporting(false)
      setExportProgress(null)
      setExportTotal(0)
    }
  }, [exportKey]);


  const checkJob = (key) => {
    if(key) {
      client.mutate('($key:String!) {importProgress(key:$key)}', {key: key}).then(r => {
        if (r.importProgress > 0) {
          setJobStarted(true)
          setProgress(r.importProgress)
        }
        if (r.importProgress >= 100) {
          setJobCompleted(true)
          localStorage.removeItem('importKey')
        }
      });
    }
  }

    const checkExportJob = (key) => {
      client.mutate('($key:String!) {exportProgress(key:$key)}', {key: key}).then(r => {
          const string = JSON.parse(r.exportProgress)
          if (string.progress > 0) {
            setExportJobStarted(true)
            setExportProgress(string.progress)
          }
          if (string.progress >= 100 && string.url !== null) {
            setExportJobCompleted(true)
            localStorage.removeItem('exportKey')
            setExportUrl(string.url)
            if (string.url?.startsWith('https') && window.open(string.url, '_blank')) {
              setShowExporting(false);
            }
          }
        });
    }

  const handleImportCancel = () => {
    client.mutate('($importKey:String!) {importJobDelete(key:$importKey)}', {importKey: importKey}).then(r => {
      if (r) {
        localStorage.removeItem('importKey')
        setJobCancelled(true)
      }
    });
  }
  const handleExportCancel = () => {
    client.mutate('($exportKey:String!) {exportJobDelete(key:$exportKey)}', {exportKey: exportKey}).then(r => {
      if (r) {
        setExportJobCancelled(true)
        localStorage.removeItem('exportKey')
      }
    });
  }

  return (
    <Snackbar open={showImporting || showExporting} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}} color={themeColors.info.snackbar}>
      <SnackbarContent
        style={{backgroundColor:themeColors.secondary.snackbar, color:themeColors.black.light}}
        message={
          <Grid container  spacing={2} style={{flexFlow:"column"}}>
            {showImporting &&
              <Grid item container justifyContent="space-between">
              <Grid item style={{marginTop: (jobStarted && !jobCompleted && !jobCancelled) ? 0 : 4}}>
                {
                  jobCancelled ?
                    intl.formatMessage({id: "jobs_checker.import.cancel_message", defaultMessage: "Import has been cancelled"})
                    :
                    jobCompleted ?
                      intl.formatMessage({id: "jobs_checker.import.success_message", defaultMessage: "{total} entities have been imported"}, {total: total})
                      :
                      jobStarted  ?
                          <>
                            {intl.formatMessage({id: "jobs_checker.import.progress_message", defaultMessage: "Importing {progress} of {total} entities..."}, {progress: progress + '%', total: total})}
                            <LinearProgress variant="determinate" value={progress}/>
                          </>
                        :
                          intl.formatMessage({id: "jobs_checker.import.waiting_message", defaultMessage: "Waiting for import to start"})
                }
              </Grid>

            {(!jobCompleted && !jobCancelled) &&
              <Grid item>
                <Button onClick={() => {handleImportCancel()}} size="small" variant="outlined">{intl.formatMessage({id: "common.button.cancel", defaultMessage: "Cancel"})}</Button>
              </Grid>
            }
            {(jobCompleted || jobCancelled) &&
              <Grid item>
                <IconButton
                  size="small"
                  title={intl.formatMessage({id: 'jobs_checker.completed_job.dismiss', defaultMessage: "Dismiss"})}
                  onClick={() => {setShowImporting(false)}}
                ><Close/></IconButton>
              </Grid>
            }</Grid>
            }
            {showExporting && <Grid item container justifyContent="space-between">
              <Grid item style={{marginTop: (exportJobStarted && !exportJobCompleted && !exportJobCancelled) ? 0 : 4}}>
                {
                  exportJobCancelled ?
                    intl.formatMessage({id: "jobs_checker.export.cancel_message", defaultMessage: "Export has been cancelled"})
                    :
                    exportJobCompleted ?<>
                        <Link href={exportUrl} target="_blank">{intl.formatMessage({id: "jobs_checker.export.download", defaultMessage: "Download the exported file"})}</Link>
                      </>
                      :
                      exportJobStarted  ?
                        <>
                          {intl.formatMessage({id: "jobs_checker.export.progress_message", defaultMessage: "Exporting {progress} of {total} entities..."}, {progress: exportProgress + '%', total: exportTotal})}
                          <LinearProgress variant="determinate" value={exportProgress}/>
                        </>
                        :
                        intl.formatMessage({id: "jobs_checker.export.waiting_message", defaultMessage: "Waiting for export to start"})
                }
              </Grid>
              {(!exportJobCompleted && !exportJobCancelled) &&
                <Grid item>
                  <Button onClick={() => {handleExportCancel()}} size="small" variant="outlined">{intl.formatMessage({id: "common.button.cancel", defaultMessage: "Cancel"})}</Button>
                </Grid>
              }
              {(exportJobCompleted || exportJobCancelled) &&
                <Grid item>
                  <IconButton
                    size="small"
                    title={intl.formatMessage({id: 'jobs_checker.completed_job.dismiss', defaultMessage: "Dismiss"})}
                    onClick={() => {setShowExporting(false)}}
                  ><Close/></IconButton>
                </Grid>
              }
            </Grid>}
          </Grid>
        }
      />
    </Snackbar>)
}
