import React from "react";
import {useNode} from "@craftjs/core";
import {Button} from "@material-ui/core";
import Color from "../Settings/Color";
import themeColors from "../../../assets/theme/colors";
import {useIntl} from "react-intl";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../../assets/theme/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid " + theme.palette.grey[500],
    borderRadius: 4,
    width: "100%",
    margin: 2,
    padding: 4,
  }
}));

export const Variant = ({background, color, ...props}) => {
  const classes = useStyles();
  const {connectors: {connect, drag}} = useNode();
  const intl = useIntl();
  return <Box
    {...props}
    ref={ref => connect(drag(ref))}
    classes={classes}
    item
    style={{
      backgroundColor: background,
      color: color,
    }}><em>
    {intl.formatMessage({
      id: "layouts.prop.variant_description",
      defaultMessage: "An area for handling variant parent, the variant fields and values"
    })}</em>
  </Box>;
}

export const VariantSettings = () => {
  const {
    color,
    background,
    actions: {setProp},
  } = useNode((node) => ({
    color: node.data.props.color,
    background: node.data.props.background,
  }));

  return <div>
    <Color setProp={setProp} value={color} prop='color'/>
    <Color setProp={setProp} value={background}/>
  </div>;
};

export const VariantButton = ({connectors, ...rest}) => {
  const intl = useIntl();
  return <Button
    {...rest}
    ref={ref => connectors.create(ref, <Variant/>, {})}
    variant="outlined"
    style={{border: "1px dashed " + themeColors.secondary.light}}
  >{intl.formatMessage({id: "layouts.prop.buttons.variant", defaultMessage: "Variant"})}</Button>
}

Variant.craft = {
  props: {
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  },
  related: {
    settings: VariantSettings,
  },
};
