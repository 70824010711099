export function RackBeatProductsTest(intl) {
  return {
    apiKey: [intl.formatMessage({id: "connector.driver.rackbeat_products.test.api_key", defaultMessage: "Connection test failed, check api key setting."})],
    supplierGroupId: [intl.formatMessage({id: "common.required_field"})],
    paymentTermsId: [intl.formatMessage({id: "common.required_field"})],
  }
}

export default function RackBeatProducts(intl) {
  return [
    {
      field: "apiKey",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.rackbeat_products.api_key", defaultMessage: "Api key"}),
      inputProps: {autoComplete: "off"},
    },
    {
      field: "lastImportedAt",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.rackbeat_products.last_import_at", defaultMessage: "Last import done at"}),
      inputProps: {autoComplete: "off"}
    },
    {
      field: "supplierGroupId",
      initial: "1",
      label: intl.formatMessage({id: "connector.driver.rackbeat_products.supplier_group_id", defaultMessage: "ID of default supplier group for new suppliers"}),
      inputProps: {autoComplete: "off"}
    },
    {
      field: "paymentTermsId",
      initial: "1",
      label: intl.formatMessage({id: "connector.driver.rackbeat_products.payment_terms_id", defaultMessage: "ID of default payment term for new suppliers"}),
      inputProps: {autoComplete: "off"}
    },
  ];
}
