import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Slider,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import {Element, useNode} from "@craftjs/core";
import Alignment from "../Settings/Alignment";
import Color from "../Settings/Color";
import Padding from "../Settings/Padding";
import {useIntl} from "react-intl";
import themeColors from "../../../assets/theme/colors";

export const Cell = ({children, width, align, padding, background}) => {
    const {connectors: {connect}} = useNode();
    let justify = align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center";
    return <TableCell
        ref={connect}
        style={{
            width: width ? width+'%' : 'auto',
            minHeight: 16,
            margin: 0,
            padding: padding,
            textAlign: align,
            justifyContent: justify,
            backgroundColor: background,
          verticalAlign: "top",
            border: "1px dashed " + themeColors.gray["400"],
        }}>{children}</TableCell>;
}

export const CellSettings = () => {
    const {
        background,
        padding,
        align,
        width,
        actions: {setProp},
    } = useNode((node) => ({
        align: node.data.props.align,
        background: node.data.props.background,
        padding: node.data.props.padding,
        width: node.data.props.width,
    }));
    const intl = useIntl();

    return (
        <div>
            <Alignment setProp={setProp} value={align}/>
            <Color setProp={setProp} value={background}/>
            <Padding setProp={setProp} value={padding}/>
            <FormControl fullWidth margin="dense" component="fieldset">
                <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
                    intl.formatMessage({
                      id: "layouts.prop.width",
                      defaultMessage: "Width {width}"
                    }, {width: width ? width + '%' : 'auto'})
                }</FormLabel>
                <Slider
                    defaultValue={width}
                    onChange={(_, value) =>
                        setProp((props) => (props.width = value), 500)
                    }
                    step={5}
                    min={0}
                    max={100}
                />
            </FormControl>
        </div>
    );
};

Cell.craft = {
    props: {
        background: '#ffffff',
        padding: 4,
        align: 'left',
        width: 0,
    },
    related: {
        settings: CellSettings,
    },
};

export const Table = ({columns, rows, width, align, background, spacing}) => {
    const {id, connectors: {connect, drag}} = useNode(state => ({id: state.id}));

    const c = [];
    for (let i = 0; i < columns; i++) {
        c.push(i + 1);
    }
    const r = [];
    for (let i = 0; i < rows; i++) {
        r.push(i + 1);
    }
    return <MaterialTable
        align={align}
        ref={ref => connect(drag(ref))}
        style={{
          margin: 0,
          width: width ? width + "%" : "auto",
          borderSpacing: spacing,
          borderCollapse: "separate",
          backgroundColor: background,
          border: "1px dashed " + themeColors.info.badge,
        }}>
        <TableBody>
            {r.map(ri =><TableRow key={'table-'+id+'-row-' + ri}>{c.map(ci =>
                <Element key={'table-'+id+'-row-' + ri + '-cell-' + ci} is={Cell} canvas id={'table-'+id+'-row-' + ri + '-cell-' + ci}/>
            )}</TableRow>)}
        </TableBody>
    </MaterialTable>;
}

export const TableSettings = () => {
    const {
        rows,
        columns,
        background,
        spacing,
        width,
        align,
        actions: {setProp},
    } = useNode((node) => ({
        rows: node.data.props.rows,
        columns: node.data.props.columns,
        background: node.data.props.background,
        spacing: node.data.props.spacing,
        width: node.data.props.width,
        align: node.data.props.align,
    }));
    const intl = useIntl();

    return (
        <div>
            <FormControl fullWidth margin="dense" component="fieldset">
                <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
                    intl.formatMessage({
                        id: "layouts.prop.rows",
                        defaultMessage: "Rows {count}"
                    }, {count: rows})
                }</FormLabel>
                <Slider
                    defaultValue={rows}
                    onChange={(_, value) =>
                        setProp((props) => (props.rows = value), 500)
                    }
                    step={1}
                    min={1}
                    max={50}
                />
            </FormControl>
            <FormControl fullWidth margin="dense" component="fieldset">
                <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
                    intl.formatMessage({
                        id: "layouts.prop.columns",
                        defaultMessage: "Columns {count}"
                    }, {count: columns})
                }</FormLabel>
                <Slider
                    defaultValue={columns}
                    onChange={(_, value) =>
                        setProp((props) => (props.columns = value), 500)
                    }
                    step={1}
                    min={1}
                    max={20}
                />
            </FormControl>
            <FormControl fullWidth margin="dense" component="fieldset">
                <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
                    intl.formatMessage({
                        id: "layouts.prop.spacing",
                        defaultMessage: "Spacing {count}"
                    }, {count: spacing})
                }</FormLabel>
                <Slider
                    defaultValue={spacing}
                    onChange={(_, value) =>
                        setProp((props) => (props.spacing = value), 500)
                    }
                    step={4}
                    min={0}
                    max={40}
                />
            </FormControl>
            <FormControl fullWidth margin="dense" component="fieldset">
                <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
                    intl.formatMessage({
                        id: "layouts.prop.width",
                        defaultMessage: "Width {width}"
                    }, {width: width ? width+'%' : 'auto'})
                }</FormLabel>
                <Slider
                    defaultValue={width}
                    onChange={(_, value) =>
                        setProp((props) => (props.width = value), 500)
                    }
                    step={5}
                    min={0}
                    max={100}
                />
            </FormControl>
            <Alignment setProp={setProp} value={align}/>
            <Color setProp={setProp} value={background}/>
        </div>
    );
};

export const TableButton = ({connectors, ...rest}) => {
    const intl = useIntl();
    return <Button
        {...rest}
        ref={ref => connectors.create(ref, <Table/>, {})}
        variant="outlined"
        style={{border: "1px dashed " + themeColors.info.badge}}
    >{intl.formatMessage({id: "layouts.prop.buttons.table", defaultMessage: "Table"})}</Button>
}

Table.craft = {
    props: {
        background: '#ffffff',
        spacing: 4,
        columns: 4,
        rows: 2,
      width: 100,
        align: 'center',
    },
    related: {
        settings: TableSettings,
    },
};
