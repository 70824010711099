import React, {useState} from "react";
import {useNode} from "@craftjs/core";
import {Button, FormControl, InputLabel, ListSubheader, MenuItem, Select} from "@material-ui/core";
import themeColors from "../../../assets/theme/colors";
import {useIntl} from "react-intl";
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";

export const Field = ({field}) => {
  const {connectors: {connect, drag}} = useNode();
  const intl = useIntl();
  return <Box
    m={0.3}
    p={0.7}
    borderColor={themeColors.gray[300]}
    borderRadius={4}
    border={1}
    height={32}
    width="100%"
    overflow="clip"
    ref={ref => connect(drag(ref))}
  >{field?.name || <i style={{opacity: 0.5}}>{intl.formatMessage({
    id: "layouts.prop.field-none-selected",
    defaultMessage: "No field selected"
  })}</i>}</Box>;
}

const FieldSettings = () => {
  const {
    field,
    actions: {setProp}
  } = useNode((node) => ({
    field: node.data.props.field,
  }));
  const intl = useIntl();
  const [fields, setFields] = useState([]);
  const client = new graphQLApi(useAuthDispatch());
  const params = useParams();

  return (
    <div>
      <FormControl fullWidth variant="standard" margin="normal">
        <InputLabel htmlFor="field">{intl.formatMessage({
          id: "layouts.prop.field",
          defaultMessage: "Field"
        })}</InputLabel>
        <Select
          inputProps={{
            name: 'field',
            id: 'field',
          }}
          value={field}
          renderValue={f => f.entity_type?.title + ': ' + f.name}
          onOpen={_ => {
            if (fields.length) return;
            setFields([{
              id: "--loading--",
              title: intl.formatMessage({id: "common.loading", defaultMessage: "Loading..."})
            }]);
            client.query('{fields(' + (params.id ? 'filter:{entity_type_id:' + params.id + '},' : '') + 'sorting:"entity_type_id,name",direction:"asc"){data{id name type entity_type{id title}}}}').then(r => {
              if (r?.hasOwnProperty('fields')) {
                let eTitle = '';
                let fs = [];
                if (!params.id) {
                  fs = [
                    {
                      id: 'header-system',
                      title: intl.formatMessage({id: "layouts.prop.field_system.header", defaultMessage: "System data"})
                    },
                    {
                      id: 'date',
                      type: 'system',
                      entity_type: {title: 'System'},
                      name: intl.formatMessage({
                        id: "layouts.prop.field_system.date",
                        defaultMessage: "PDF export date"
                      })
                    },
                    {
                      id: 'datetime',
                      type: 'system',
                      entity_type: {title: 'System'},
                      name: intl.formatMessage({
                        id: "layouts.prop.field_system.datetime",
                        defaultMessage: "PDF export date and time"
                      })
                    },
                    {
                      id: 'user',
                      type: 'system',
                      entity_type: {title: 'System'},
                      name: intl.formatMessage({
                        id: "layouts.prop.field_system.user",
                        defaultMessage: "Exporting user's name"
                      })
                    },
                  ];
                }
                r.fields.data.forEach(f => {
                  if (eTitle !== f.entity_type.title) {
                    eTitle = f.entity_type.title;
                    fs.push({id: 'header-' + f.entity_type.id, title: eTitle});
                  }
                  fs.push(f);
                })
                setFields(fs);
              }
            });
          }}
          onChange={e => setProp(props => props.field = e.target.value)}
        >{fields.map(f => f.id.match(/^header-.+$/)
          ? <ListSubheader key={f.id} color="primary"
                           style={{backgroundColor: themeColors.info.badgeBg}}>{f.title}</ListSubheader>
          : <MenuItem
            key={"field-" + f.id}
            disabled={f.id === "--loading--"}
            value={f}
          >{f.name}</MenuItem>
        )}</Select>
      </FormControl>
    </div>
  )
}

export const FieldButton = ({connectors, ...rest}) => {
  const intl = useIntl();
  return <Button
    {...rest}
    ref={ref => connectors.create(ref, <Field/>, {})}
    variant="outlined"
    style={{border: "1px dashed " + themeColors.primary.light}}
  >{intl.formatMessage({id: "layouts.prop.buttons.field", defaultMessage: "Field"})}</Button>
}

Field.craft = {
  props: {
    field: null,
  },
  related: {
    settings: FieldSettings,
  },
  rules: {}
};
