import React, {useLayoutEffect, useRef} from 'react';
import {Jodit} from "jodit-pro-react";

async function loadAndInit(data, options, onSelect) {
  if (!data.destructed) {
    data.browser = new Jodit.modules.FileBrowserPro(options);
    data.browser.setMod('static', true).open(onSelect);
    data.div.current.appendChild(data.browser.container);
  }
}

export function Filebrowser({ options, onSelect } = {}) {
  const div = useRef(null);
  let data = { div, destructed: false };

  useLayoutEffect(() => {
    void loadAndInit(data, options, onSelect);

    return () => {
      data.destructed = true;
      data.browser?.container.remove();
      data.browser?.destruct();
    };
  }, [options]);

  return <div ref={div} />;
}
