import React from "react";
import {useNode} from "@craftjs/core";
import {Button, ButtonGroup, FormControl, FormLabel, Slider, TextField, Typography} from "@material-ui/core";
import Color from "../Settings/Color";
import {FormatBold, FormatClear, FormatItalic, FormatStrikethrough, FormatUnderlined} from "@material-ui/icons";
import themeColors from "../../../assets/theme/colors";
import {useIntl} from "react-intl";

export const Text = ({text, fontSize, style, weight, decoration, color, ...props}) => {
    const {connectors: {connect, drag}} = useNode();
  const intl = useIntl();
    return <Typography
        {...props}
        ref={ref => connect(drag(ref))}
        style={{
            display: "inline",
            color: color,
            fontSize: fontSize + 'px',
            textDecoration: decoration,
            fontWeight: weight,
            fontStyle: style,
            border: "1px dashed " + themeColors.secondary.light
        }}
    >{text.length ? text : <em style={{opacity: 0.3}}>{intl.formatMessage({
      id: "layouts.prop.text-empty",
      defaultMessage: "Write your text here"
    })}</em>}</Typography>;
}

const TextSettings = () => {
    const {
        text,
        fontSize,
        style,
        weight,
        decoration,
        color,
        actions: {setProp}
    } = useNode((node) => ({
        text: node.data.props.text,
        style: node.data.props.style,
        weight: node.data.props.weight,
        decoration: node.data.props.decoration,
        fontSize: node.data.props.fontSize,
        color: node.data.props.color,
    }));
    const intl = useIntl();

    return (
        <div>
            <TextField
                fullWidth
                variant="standard"
                size="small"
                label={intl.formatMessage({id: "layouts.prop.text", defaultMessage: "Text"})}
                value={text}
                margin="normal"
                onChange={(e) => {
                    setProp(props => props.text = e.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: "layouts.prop.text-empty",
                  defaultMessage: "Write your text here"
                })}
            />
            <FormControl size="small" component="fieldset" fullWidth>
                <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
                    intl.formatMessage({id: "layouts.prop.font-size", defaultMessage: "Font size"})
                }</FormLabel>
                <Slider
                    value={fontSize || 7}
                    step={7}
                    min={1}
                    max={50}
                    onChange={(_, value) => {
                        setProp(props => props.fontSize = value);
                    }}
                />
            </FormControl>
            <FormControl fullWidth margin="dense" component="fieldset">
                <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
                    intl.formatMessage({id: "layouts.prop.font-style", defaultMessage: "Font style"})
                }</FormLabel>
                <ButtonGroup size="small">
                    <Button onClick={() => setProp((props) => {
                        props.style = 'normal';
                        props.weight = 'normal';
                        props.decoration = 'none';
                    }, 500)}>
                        <FormatClear/>
                    </Button>
                    <Button
                        onClick={() => setProp((props) => (props.weight = weight === 'bold' ? 'normal' : 'bold'), 500)}
                        variant={weight === "bold" && "contained"}
                        color={weight === "bold" && "primary"}>
                        <FormatBold/>
                    </Button>
                    <Button
                        onClick={() => setProp((props) => (props.style = style === 'italic' ? 'normal' : 'italic'), 500)}
                        variant={style === "italic" && "contained"}
                        color={style === "italic" && "primary"}>
                        <FormatItalic/>
                    </Button>
                    <Button
                        onClick={() => setProp((props) => (props.decoration = decoration === 'underline' ? 'none' : 'underline'), 500)}
                        variant={decoration === "underline" && "contained"}
                        color={decoration === "underline" && "primary"}>
                        <FormatUnderlined/>
                    </Button>
                    <Button
                        onClick={() => setProp((props) => (props.decoration = decoration === 'line-through' ? 'none' : 'line-through'), 500)}
                        variant={decoration === "line-through" && "contained"}
                        color={decoration === "line-through" && "primary"}>
                        <FormatStrikethrough/>
                    </Button>
                </ButtonGroup>
            </FormControl>
            <Color setProp={setProp} value={color} prop={"color"}/>
        </div>
    )
}

export const TextButton = ({connectors, ...rest}) => {
    const intl = useIntl();
    return <Button
        {...rest}
        ref={ref => connectors.create(ref, <Text text=""/>, {})}
        variant="outlined"
        style={{border: "1px dashed " + themeColors.secondary.light}}
    >{intl.formatMessage({id: "layouts.prop.buttons.text", defaultMessage: "Text"})}</Button>
}

Text.craft = {
    props: {
        text: "",
        color: themeColors.text.primary,
        fontSize: 12,
        style: "normal",
        weight: "normal",
        decoration: "none",
    },
    related: {
        settings: TextSettings,
    },
    rules: {}
};
