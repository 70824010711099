import React from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import FooterLinks from "./FooterLinks";

const Footer = (props) => {
  return (
    <Box component="footer">
      <FooterLinks {...props}/>
    </Box>
  );
};

export default Footer;
