import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Slider,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {Add, Delete, Edit, People} from '@material-ui/icons';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import {roles} from 'config';
import EditForm from 'components/Form/EditForm';
import {graphQLApi, graphQLReduceFields} from 'services/GraphQLApi';
import FilterEdit from 'components/Filters/FilterEdit';
import themeColors from 'assets/theme/colors';
import {useLocation, useNavigate, useParams} from "react-router-dom";

export default function RoleEdit(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  let id = Number(params.id);
  const rolesFiltered = roles(intl).filter(r => r.id >= authUser().roles[0].priority).reverse();

  const handleCheckboxChange = (checked, prop, value) => {
    const d = {...data};
    if (checked) {
      d[prop].push(value);
    }
    else {
      d[prop] = d[prop].filter(p => p.id !== value.id);
    }
    setData(d);
  }
  const fields = [
    {
      field: 'title',
      initial: '',
      type: 'String',
      label: intl.formatMessage({id: 'roles.edit.label.title', defaultMessage: 'Title'}),
      input: 'text',
    },
    {
      field: 'description',
      initial: '',
      type: 'String',
      label: intl.formatMessage({id: 'roles.edit.label.description', defaultMessage: 'Description'}),
      input: 'text',
    },
    {
      column: 1,
      field: 'priority',
      initial: rolesFiltered[0].id,
      type: 'Int',
      render: (key, data, setData) => <FormControl fullWidth id={'form-field-' + key + '-label'}>
        <InputLabel shrink>{intl.formatMessage({id: 'roles.edit.label.priority', defaultMessage: 'Rettigheder for rollen'})}</InputLabel>
        <Box height={rolesFiltered.length * 25} margin="32px 0 12px 0">{/*roles(intl).filter(r => r.id >= authUser().roles[0].priority).length*30*/}
          <Slider
            aria-labelledby={'form-field-' + key + '-label'}
            orientation="vertical"
            value={rolesFiltered.findIndex(r => r.id === parseInt(data.priority))}
            onChange={(_e, value) => {
              setData(cur => ({...cur, priority: rolesFiltered[value].id}));
            }}
            marks={rolesFiltered.map((r, i) => ({value: i, label: r.name}))}
            min={0}
            max={rolesFiltered.length - 1}
            step={null}
            style={{margin:8}}
          /></Box>
      </FormControl>,
    },
    {
      column: 1,
      field: 'entity_types',
      initial: [],
      render: () => <FormControl fullWidth>
        <InputLabel shrink>{intl.formatMessage({id: 'roles.edit.label.entity_types_access', defaultMessage: 'Entity type access'})}</InputLabel>
        <FormGroup row style={{marginTop:18, borderWidth:1, borderColor:themeColors.gray[200], borderStyle:"solid", borderRadius:4}}>
          {entityTypes.map((entity_type, index) => <FormControlLabel
            style={{width:"45%"}}
            key={"entity_type_"+index}
            control={<Checkbox
              checked={!!data.entity_types?.find(l => l.id === entity_type.id)}
              onChange={e => handleCheckboxChange(e.target.checked, 'entity_types', entity_type)}
              name={"entity_type_"+entity_type.id}/>}
            label={entity_type.title}
          />)}{entityTypes.length === 0 && <em style={{margin:4}}>{intl.formatMessage({id: 'roles.edit.label.no_entity_types', defaultMessage: 'No entity types has been created yet.'})}</em>}
        </FormGroup>
        <FormHelperText>{
          intl.formatMessage({id: 'roles.edit.help.entity_types_access', defaultMessage: 'Only allow the users access to these entity types.'})
        }</FormHelperText>
      </FormControl>
    },
    {
      column: 1,
      field: 'role_filters',
      initial: [],
      render: () => <FormControl fullWidth>
        <InputLabel shrink>{intl.formatMessage({id: 'roles.edit.label.filters', defaultMessage: 'Base entity filters'})}</InputLabel>
        <TableContainer style={{marginTop:18, borderWidth:1, borderColor:themeColors.gray[200], borderStyle:"solid", borderRadius:4}}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({id: 'roles.edit.filters.column.data_type', defaultMessage: 'Filter entity type'})}</TableCell>
                <TableCell>{intl.formatMessage({id: 'roles.edit.filters.column.operands', defaultMessage: 'Number of operands for this filter'})}</TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    disabled={selectedFilter !== null}
                    title={intl.formatMessage({id:"common.button.add"})}
                    onClick={() => {
                      const d = {...data};
                      const index = d.role_filters.push({
                        id: null,
                        role_id: id,
                        entity_type_id: null,
                        entity_type: {title:""},
                        comparator: 'and',
                        role_filter_operands: [],
                      })-1;
                      setData(d);
                      setSelectedFilters(index);
                    }}
                  ><Add/></IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.role_filters.map((filter, index) => <TableRow key={'filter_' + index}>
                <TableCell>{filter.entity_type.title}</TableCell>
                <TableCell>{filter.role_filter_operands?.length}</TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    disabled={selectedFilter !== null}
                    title={intl.formatMessage({id:"common.button.add"})}
                    onClick={() => setSelectedFilters(index)}
                  ><Edit/></IconButton>
                  <IconButton
                    size="small"
                    disabled={selectedFilter !== null}
                    title={intl.formatMessage({id:"common.button.delete"})}
                    onClick={() => {
                      const d = {...data};
                      d.role_filters.splice(index, 1);
                      setData(d);
                    }}
                  ><Delete/></IconButton>
                </TableCell>
              </TableRow>)}
              {data.role_filters.length === 0 && <TableRow>
                <TableCell colSpan={3}><em>{intl.formatMessage({id:"roles.edit.label.no_filters", defaultMessage:"No filters have been created yet."})}</em></TableCell>
              </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <FormHelperText>{
          intl.formatMessage({id: 'roles.edit.help.filters', defaultMessage: 'These filters will be applied to all user entity queries, effectively limiting the user to only see entities allowed by these filters.'})
        }</FormHelperText>
      </FormControl>
    },
    {
      column: 2,
      field: 'listings',
      initial: [],
      render: () => <FormControl fullWidth>
        <InputLabel shrink>{intl.formatMessage({id: 'roles.edit.label.list_access', defaultMessage: 'Lists access'})}</InputLabel>
        <FormGroup row style={{marginTop:18, borderWidth:1, borderColor:themeColors.gray[200], borderStyle:"solid", borderRadius:4}}>
          {listings.map((listing, index) => <FormControlLabel
            style={{width:"45%"}}
            key={"listing_"+index}
            control={<Checkbox checked={!!data.listings?.find(l => l.id === listing.id)} onChange={e => handleCheckboxChange(e.target.checked, 'listings', listing)} name={"listing_"+listing.id}/>}
            label={<span>{listing.title}</span>}
          />)}{listings.length === 0 && <em style={{margin:4}}>{intl.formatMessage({id: 'roles.edit.label.no_lists', defaultMessage: 'No lists has been created yet.'})}</em>}
        </FormGroup>
      </FormControl>
    },
    /*{
      column: 2,
      field: 'views',
      initial: [],
      render: () => <FormControl fullWidth>
        <InputLabel shrink>{intl.formatMessage({id: 'roles.edit.label.views_access', defaultMessage: 'Views access'})}</InputLabel>
        <FormGroup row style={{marginTop:18, borderWidth:1, borderColor:themeColors.gray[200], borderStyle:"solid", borderRadius:4}}>
          {_views.map((view, index) => <FormControlLabel
            style={{width:"45%"}}
            key={"view_"+index}
            control={<Checkbox
              checked={!!data.views?.find(l => l.id === view.id)}
              onChange={e => handleCheckboxChange(e.target.checked, 'views', view)}
              name={"view_"+view.id}/>}
            label={view.title}
          />)}{_views.length === 0 && <em style={{margin:4}}>{intl.formatMessage({id: 'roles.edit.label.no_views', defaultMessage: 'No views has been created yet.'})}</em>}
        </FormGroup>
      </FormControl>
    },*/
    {
      column: 2,
      field: 'field_groups',
      initial: [],
      render: () => <FormControl fullWidth>
        <InputLabel shrink>{intl.formatMessage({id: 'roles.edit.label.field_groups_access', defaultMessage: 'Field groups access'})}</InputLabel>
        <FormGroup row style={{marginTop:18, borderWidth:1, borderColor:themeColors.gray[200], borderStyle:"solid", borderRadius:4}}>
          {fieldGroups.map((field_group, index) => <FormControlLabel
            style={{width:"45%"}}
            key={"field_group_"+index}
            control={<Checkbox
              checked={!!data.field_groups?.find(l => l.id === field_group.id)}
              onChange={e => handleCheckboxChange(e.target.checked, 'field_groups', field_group)}
              name={"field_group_"+field_group.id}/>}
            label={<span>{field_group.name}<br/><small><em>{field_group.entity_type?.title}</em></small></span>}
          />)}{fieldGroups.length === 0 && <em style={{margin:4}}>{intl.formatMessage({id: 'roles.edit.label.no_field_groups', defaultMessage: 'No field groups has been created yet.'})}</em>}
        </FormGroup>
      </FormControl>
    },
  ];

  const initialValidation = graphQLReduceFields(fields, 'validation');
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: setValidationFromErrors});

  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(graphQLReduceFields(fields));
  const [validation, setValidation] = useState(initialValidation);
  const [selectedFilter, setSelectedFilters] = useState(null);
  const [entityTypes, setEntityTypes] = useState([]);
  const [listings, setListings] = useState([]);
  const [_views, setViews] = useState([]);
  const [fieldGroups, setFieldGroups] = useState([]);

  const [entityFields, setEntityFields] = useState([]);

  const queryFields = 'id title description priority ' +
    'role_filters{id entity_type{title} entity_type_id comparator role_filter_operands{id field_id field{id name type field_options{id title}} column operator value}} ' +
    'listings{id} ' +
    'views{id} ' +
    'field_groups{id} ' +
    'entity_types{id} ' +
    'field_groups{id} ';
  useEffect(() => {
    setIsLoading(true);
    let query =
      ' fields(sorting:"name",direction:"asc"){data{' +
      '  id name type entity_type{id title} field_options{id title}' +
      ' }}' +
      ' fieldGroups(sorting:"entity_type_id",direction:"asc"){data{id name entity_type{title}}}' +
      ' entityTypes{data{id title}}' +
      ' listings(filter:{user_id:null}){data{id title}}' +
      ' views{data{id title}}';
    if (id) {
      query = query + ' roles(filter:{id:' + id + '}){data{' + queryFields + '}}';
    }
    client.query('{' + query + '}').then(resp => {
      if (resp?.hasOwnProperty('entityTypes')) {
        setEntityTypes(resp.entityTypes.data);
      }
      if (resp?.hasOwnProperty('fields')) {
        setEntityFields(resp.fields.data);
      }
      if (resp?.hasOwnProperty('listings')) {
        setListings(resp.listings.data);
      }
      if (resp?.hasOwnProperty('views')) {
        setViews(resp.views.data);
      }
      if (resp?.hasOwnProperty('fieldGroups')) {
        setFieldGroups(resp.fieldGroups.data);
      }
      if (resp?.hasOwnProperty('roles')) {
        setData(resp.roles.data[0]);
      }
      else {
        setData({...data, entity_types: resp.entityTypes.data, field_groups: resp.fieldGroups.data})
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <Grid container>
      <Snackbar
        open={notification !== null}
        message={notification}
        onClose={() => setNotification(null)}
        autoHideDuration={7500}
        anchorOrigin={{horizontal:'center', vertical:'top'}}
      />
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={<People color="primary"/>}
            title={intl.formatMessage({id: 'roles.edit.heading', defaultMessage: 'Roles edit'})}
            titleTypographyProps={{color: 'primary'}}
          />
          <CardContent>
            <EditForm
              {...props}
              id={id}
              fields={fields}
              data={data}
              setData={setData}
              isLoading={isLoading}
              validation={validation}
              cols={2}
              save={d => {
                setIsLoading(true);
                setValidation(initialValidation);
                const vars = {
                  title: "String",
                  description: "String",
                  priority: "Int",
                  views: "[ID]",
                  listings: "[ID]",
                  entity_types: "[ID]",
                  field_groups: "[ID]",
                  role_filters: "[RoleFiltersInput]",
                };
                const newData = {
                  ...d,
                  views: d.views ? d.views.map(f => f.id) : [],
                  listings: d.listings.map(f => f.id),
                  entity_types: d.entity_types.map(f => f.id),
                  field_groups: d.field_groups.map(f => f.id),
                  role_filters: d.role_filters.map(f => ({
                    id:f.id,
                    role_id:id,
                    entity_type_id:f.entity_type_id,
                    comparator: f.comparator,
                    role_filter_operands: f.role_filter_operands.map(o => ({
                      id: o.id,
                      field_id: o.field_id,
                      operator: o.operator,
                      value: o.value,
                    }))
                  })),
                }
                if (id) {
                  newData.id = id;
                  vars.id = "ID!";
                }
                client.mutation('role', vars, newData, queryFields).then(r => {
                  if (r?.response) {
                    setNotification(intl.formatMessage({id:"roles.edit.saved", defaultMessage:"Role was saved"}));
                    setData(r.response);
                    navigate(location.pathname.replace('/create', '/' + r.response.id), {replace: true});
                  }
                  setIsLoading(false);
                });
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={selectedFilter !== null} maxWidth="lg">
        <DialogContent>
          {selectedFilter !== null && <FilterEdit
            filter={data.role_filters[selectedFilter]}
            setFilter={filter => {
              const d = {...data};
              d.role_filters[selectedFilter] = filter;
              d.role_filters[selectedFilter].entity_type = entityTypes.find(et => et.id === filter.entity_type_id);
              setData(d);
            }}
            operands={data.role_filters[selectedFilter].role_filter_operands}
            setOperands={operands => {
              const d = {...data};
              d.role_filters[selectedFilter].role_filter_operands = operands;
              setData(d);
            }}
            entityTypes={entityTypes}
            fields={entityFields}
          />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSelectedFilters(null)}
            variant="contained"
          >{intl.formatMessage({id:"common.button.close"})}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
